import { Box, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TokenColorIcon from "assets/tokens";
import RightRow from 'assets/Arrow-right.svg';
import check from 'assets/Check-rig.svg'
import { useEffect } from "react";
import { hash_url } from "config";
import { useAccount } from "wagmi";


type TypeProps = {
  windowWidth: Number;
  open: boolean;
  handleConfirmClose: () => void;
  hash: string;
  RewardNum: string;
}







const ClaimSucceed = ({ windowWidth, hash, handleConfirmClose, open, RewardNum }: TypeProps) => {



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: windowWidth >= 600 ? '600px' : `${windowWidth}px`,
      borderRadius: '20px',
      padding: windowWidth >= 600 ? '20px' : '10px 10px 20px 10px',
      margin: '10px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));

  useEffect(() => {

  }, [hash])

  const { address } = useAccount()


  const goHash = () => {
    window.open(`${hash_url}${hash}`, '_blank')
  }

  return (
    <>
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >

              <Stack direction="row" justifyContent="end" alignItems="end" padding="0 10px" mb="20px" width="100%">

                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}

                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px', mb: '20px' }}>
                  <Box sx={{ width: '100%', mb: '20px', textAlign: 'center' }}>
                    <img src={check} />
                  </Box>

                  <Typography sx={{ color: '#000', fontSize: '20px', fontWeight: 700, lineHeight: '20px', textAlign: 'center', mb: '20px' }}>
                    Transaction Succeed!
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing="10px" flexWrap="wrap" gap="10px" justifyContent="center">

                    <Stack direction="row" spacing="4px" alignItems="center" >
                      <TokenColorIcon name="HAYA" size={22} />
                      <Typography sx={{ color: "#000", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                        {RewardNum} $HAYA
                      </Typography>

                    </Stack>
                    <img src={RightRow} />
                    <Stack direction="row" spacing="4px" alignItems="center"  >
                      <TokenColorIcon name="HAYA" size={22} />
                      <Typography sx={{ color: "#000", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>

                        {address?.substring(0, 6)}...{address?.substring(address.length - 6)}
                      </Typography>

                    </Stack>

                  </Stack>




                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%">
                  <Typography onClick={goHash} component="button" sx={{ backgroundColor: 'transparent', border: 0, cursor: 'pointer', color: '#1AAE70', fontSize: '15px', fontWeight: 600, lineHeight: '20px', textAlign: 'center' }}>
                    View on Explorer
                  </Typography>
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <BootstrapDialog
            onClose={handleConfirmClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >

            <Stack direction="row" justifyContent="end" alignItems="end" padding="0 10px" mb="20px" width="100%">

              <IconButton
                aria-label="close"
                onClick={handleConfirmClose}
                sx={{ color: "#9b9b9b" }}

              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <DialogContent >
              <Box sx={{ p: '0px 0px 20px 0px', mb: '20px' }}>
                <Box sx={{ width: '100%', mb: '20px', textAlign: 'center' }}>
                  <img src={check} />
                </Box>

                <Typography sx={{ color: '#000', fontSize: '20px', fontWeight: 700, lineHeight: '20px', textAlign: 'center', mb: '20px' }}>
                  Transaction Succeed!
                </Typography>
                <Stack direction="row" alignItems="center" spacing="10px" flexWrap="wrap" gap="10px" justifyContent="center">

                  <Stack direction="row" spacing="4px" alignItems="center" >
                    <TokenColorIcon name="HAYA" size={22} />
                    <Typography sx={{ color: "#000", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      {RewardNum} $HAYA
                    </Typography>

                  </Stack>
                  <img src={RightRow} />
                  <Stack direction="row" spacing="4px" alignItems="center"  >
                    <TokenColorIcon name="HAYA" size={22} />
                    <Typography sx={{ color: "#000", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>

                      {address?.substring(0, 6)}...{address?.substring(address.length - 6)}
                    </Typography>

                  </Stack>

                </Stack>




              </Box>
            </DialogContent>
            <DialogActions>
              <Stack width="100%">
                <Typography onClick={goHash} component="button" sx={{ backgroundColor: 'transparent', border: 0, cursor: 'pointer', color: '#1AAE70', fontSize: '15px', fontWeight: 600, lineHeight: '20px', textAlign: 'center' }}>
                  View on Explorer
                </Typography>
              </Stack>
            </DialogActions>
          </BootstrapDialog>
        )
      }

    </>
  )

}
export default ClaimSucceed

import axios from 'axios';
import { clearToken, getToken, isAuth } from './token';
// import { message } from 'antd';
//'https://mbtest.haya.finance/', 'https://mback.haya.finance/'
const http = axios.create({
  timeout: 100000,
  baseURL: 'https://app2.haya.finance/miner',
  withCredentials: true
});

// ==============================|| axiosServices AXIOS - FOR MOCK SERVICES ||============================== //

http.interceptors.response.use(
  // (response) => response,

  // (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')


  function (config) {
    config.headers['Content-Type'] = "application/json"
    if (isAuth()) {
      config.headers!.Authorization = `Bearer ${getToken().miner_token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
);

// 相应拦截器
http.interceptors.response.use(undefined, error => {


  // console.log('res111111111', error)
  // 响应失败时，会执行此处的回调函数

  if (!error.response) {
    // console.log(error)
    // 网路超时
    return Promise.reject(error)
  }


  if (error.response.status === 401) {
    clearToken()

    // const dispatch = useDispatch()

    // token 过期，登录超时
    // window.location.href = '/login'
    return Promise.reject(error)

  }

  return Promise.reject(error)
})

export { http }

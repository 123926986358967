import { Box, Button, ButtonProps, Container, Skeleton, Stack, Typography } from "@mui/material"
import styled from "@mui/system/styled"
import TokenColorIcon from "assets/tokens"
import { useEffect, useState } from "react"
import NftPage from "./NFT"
import FooterBlock from "layout/CommonLayout/FooterBlock"
import ReferralsDetails from "./ReferralsDetails"
import UpdatePage from "./upgrade"
import { MinerFreeStakingContract, MinerStakingContract, sepolia_rpc } from "config"
import { useAccount } from "wagmi"
import MinerStakingAbi from 'abi/minerStaking.json'
import { ethers } from "ethers"
import moment from "moment"
// import ConnectWallet from "layout/CommonLayout/components/connectWallet"

import BlockieAvatar from "./avater/blockieAvatat"
import minerBannerIcon from 'assets/banner/minerBanner.png'
import ReadPage from "./read"
import afterBannerIcon from 'assets/banner/afterBanner.png'
import groudBannerIcon from 'assets/banner/groudBanner.png'
import minermobileBanner from 'assets/banner/minermobileBanner.png'
import mobileBanner from 'assets/banner/mobileBanner.png'
import minerStakingFeerAbi from 'abi/minerFeerStaking.json'
// import { notification } from "antd";
import { useOutletContext } from "react-router"
import AnimatedNumber from 'react-animated-number'
// import { useOutletContext } from "react-router"

// import { useNavigate } from "react-router"



const provider = new ethers.JsonRpcProvider(sepolia_rpc)


type PropsType = {
  windowWidth: number
  windowHeight: number
}


type HashDataType = {
  id: number
  hashRate: string
}




// function formatNumber(num: number) {

//   if (num % 1 !== 0) {
//     const decimalPart = num.toString().split('.')[1]

//     for (let i = 0; i < decimalPart?.length; i++) {
//       if (Number(decimalPart[i]) !== 0) {
//         num *= 10 ** (i + 2)
//         num = Math.floor(num)
//         num /= 10 ** (i + 2)
//         var parts = num.toString().split(".");
//         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         return parts.join(".");
//       }
//     }
//   } else {
//     return num.toLocaleString()

//   }
// }

// function transferToNumber(inputNumber: any) {
//   if (isNaN(inputNumber)) {
//     return inputNumber
//   }
//   inputNumber = '' + inputNumber
//   inputNumber = parseFloat(inputNumber)
//   let eformat = inputNumber.toExponential() // 转换为标准的科学计数法形式（字符串）
//   let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/) // 分离出小数值和指数值
//   let new_tmpArray = tmpArray[1] !== undefined ? tmpArray[1].length >= 4 ? tmpArray[1].substring(0, 4) : tmpArray[1] : tmpArray[1]
//   let number = inputNumber.toFixed(Math.max(0, (new_tmpArray || '').length - tmpArray[2]))
//   return number
// }

function timeDifference(time1: any, time2: any) {
  const target = moment(time1)
  console.log('1111111hours', target.diff(time2, 'days'))
  // let result = '';
  if (target.diff(time2, 'days') > 0) {
    return `${target.diff(time2, 'days')}-Days`
    // result += `${target.diff(time2, 'days')}days`;
  } else if (target.diff(time2, 'hours') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'hours')}-Hours`
    // result += `${target.diff(time2, 'hours')}hours`;
  } else if (target.diff(time2, 'minutes') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'minutes')}-Minutes`
    // result += ;
  } else {
    return `${target.diff(time2, 'seconds')}-Seconds`
  }


  // return result;
}





const ClaimButton = styled(Button)<ButtonProps>(({ theme }) => ({

  padding: '8px 30px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  borderRadius: '20px',
  display: 'none',

  "&::after": { boxShadow: 'none' }
}));



const MinerPage = ({ windowHeight, windowWidth }: PropsType) => {

  const outLentData: any = useOutletContext()

  const [upgradeOpen, setUpgradeOpen] = useState(false)

  const [hashRandData, setHashRandData] = useState<HashDataType[]>([
    {
      id: 6,
      hashRate: '0',
    },
    {
      id: 0,
      hashRate: '0',
    },
    {
      id: 1,
      hashRate: '0',
    },
    {
      id: 2,
      hashRate: '0',
    },
    {
      id: 3,
      hashRate: '0',
    }
  ])



  useEffect(() => {
    if (outLentData?.newData.length !== 0) {


    }

  }, [outLentData?.newData])

  useEffect(() => {

  }, [outLentData?.rewardData])

  useEffect(() => {

  }, [outLentData?.hashUserRandData])

  useEffect(() => {

  }, [outLentData?.mintDays])




  // const [err, setErr] = useState('')



  const { address } = useAccount()


  // const getNFTBalance = async () => {
  //   const addressList = [address, address, address, address]
  //   const idsList = [0, 1, 2, 3]
  //   const contract = new ethers.Contract(MinerContract, minerAbi, provider)
  //   const feerContract = new ethers.Contract(MinerFreeContract, minerFeeAbi, provider)

  //   await contract.balanceOfBatch(addressList, idsList).then((res: any) => {

  //     for (let i = 0; i < res.length; i++) {
  //       setNewData((pre) => pre.map((item) => item.id == i ? { ...item, num: String(res[i]), total_num: String(Number(item.expired_num) + Number(item.miningNum) + Number(res[i])) } : item))
  //     }
  //   }).catch((err) => {

  //   })

  //   const FeerIdsList = [1, 3]

  //   for (let i = 0; i < FeerIdsList.length; i++) {
  //     await feerContract.balanceOf(address, FeerIdsList[i]).then((res: any) => {

  //       setNewData((pre) => pre.map((item) => item.id == 6 && item.token_id == FeerIdsList[i] ? { ...item, num: String(Number(res)), total_num: String(Number(item.expired_num) + Number(item.miningNum) + Number(Number(res))) } : item))
  //     }).catch((err) => {

  //     })
  //   }
  // }














  const getHash = async () => {
    const idsList = [0, 1, 2, 3]
    const contract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, provider)
    const feerContract = new ethers.Contract(MinerFreeStakingContract, minerStakingFeerAbi, provider)

    await feerContract.HASH_RATE_FREE().then(async (res1: any) => {
      let newArr = [
        {
          id: 6,
          hashRate: '0',
        },
        {
          id: 0,
          hashRate: '0',
        },
        {
          id: 1,
          hashRate: '0',
        },
        {
          id: 2,
          hashRate: '0',
        },
        {
          id: 3,
          hashRate: '0',
        }
      ]

      newArr = newArr.map(item => item.id == 6 ? { ...item, hashRate: String(Number(res1) / (10 ** 3)) } : item)
      // setHashRandData((pre) => pre.map((item) => item.id == 6 ? { ...item, hashRate: String(Number(res1) / (10 ** 3)) } : item))

      for (let i = 0; i < idsList.length; i++) {
        await contract.hashRates(idsList[i]).then(async (res1: any) => {
          newArr = newArr.map(item => item.id == idsList[i] ? { ...item, hashRate: String(Number(res1) / (10 ** 3)) } : item)
          // setHashRandData((pre) => pre.map((item) => item.id == idsList[i] ? { ...item, hashRate: String(Number(res1) / (10 ** 3)) } : item))
        })
      }

      setHashRandData(newArr)
    })
  }


  // const navigate = useNavigate()


  // const { disconnect } = useDisconnect()


  useEffect(() => {
    getHash()

    // if (address !== undefined && isAuth()) {
    //   getMiningBalance()
    //   // getNFTBalance()
    //   getStakes()
    // } else {



    // }

    // if (address == undefined || !isAuth()) {


    //   navigate('/')
    // }

  }, [])






  useEffect(() => {

  }, [hashRandData])


  const OnUpgradeClose = () => {
    onChange()
    setUpgradeOpen(false)
  }

  const OnReferralsClose = () => {
    setReferralOpen(false)
  }


  const [referralOpen, setReferralOpen] = useState(false)



  // const onCliamConfirmClose = () => {
  //   setOpenConfirm(false)
  // }


  // const onCliamSucceedClose = () => {
  //   setOpenSucced(false)
  // }




  // const onCloseWalletConnect = () => {
  //   setOpenWallet(false)
  // }

  const onChange = () => {
    console.log('11111111')
    outLentData?.onChangePages()

  }




  return (
    <>
      {/* <CliamConfirm windowWidth={windowWidth} handleConfirmClose={onCliamConfirmClose} open={openConfirm} RewardNum={transferToNumber(rewardData.reduce((sum, current) => sum + Number(current.now_RewardNum), 0), )} />
      <ClaimSucceed windowWidth={windowWidth} handleConfirmClose={onCliamSucceedClose} open={openSucced} RewardNum={transferToNumber(rewardData.reduce((sum, current) => sum + Number(current.now_RewardNum), 0))} hash={hash} /> */}

      {/* <ConnectWallet windowWidth={windowWidth} open={openWallet} handleClose={onCloseWalletConnect} /> */}
      <ReferralsDetails open={referralOpen} handleClose={OnReferralsClose} windowHeight={windowHeight} windowWidth={windowWidth} />
      <UpdatePage open={upgradeOpen} handleClose={OnUpgradeClose} windowHeight={windowHeight} windowWidth={windowWidth} />
      {
        windowWidth >= 600 ? (
          <>
            <Container sx={{ width: '100%', '&.MuiContainer-root': { maxWidth: '100%', paddingLeft: '0px', paddingRight: '0px', } }}>

              <Box padding="0px" sx={Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? { backgroundImage: `url(${groudBannerIcon}), url(${afterBannerIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center 80%, top', height: `${(windowWidth * 1340) / 1920}px`, backgroundSize: 'contain', width: '100%' } : { backgroundImage: `url(${minerBannerIcon})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top', height: `${(windowWidth * 1340) / 1920}px`, backgroundSize: 'contain', width: '100%' }}>
                <Stack alignItems="start" justifyContent="center" padding="160px 0 0 0px" maxWidth='1340px' margin='0 auto'>
                  <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '72px', lineHeight: '80px', fontFamily: `'Public Sans',sans-serif`, pb: '20px' }}  >
                    {`${Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? 'Start Mining' : 'Mining...'}`}
                  </Typography>
                  <Stack alignItems="center" spacing="10px" justifyContent="center">
                    <Box >
                      <Stack direction="row" spacing="10px" alignItems="center" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '20px', padding: '0 30px', height: '60px', width: '600px' }}>
                        <BlockieAvatar style={{ width: '36px', height: '36px' }} address={String(address)} />
                        <Stack direction="row" spacing="10px" alignItems="center">
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '16px', lineHeight: '20px', fontFamily: `'Public Sans',sans-serif` }}  >
                            {address?.substring(0, 6)}...{address?.substring(address.length - 4)}
                          </Typography>
                          {
                            Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? (
                              <>
                                <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '4px 6px', borderRadius: '20px' }}>

                                  <Typography variant="body1" sx={{ color: '#C0C0C0', ontWeight: 600, fontSize: '10px', lineHeight: '16px', fontFamily: `'Public Sans',sans-serif` }}  >
                                    Inactive
                                  </Typography>
                                </Box>

                              </>
                            ) : (
                              <>
                                <Stack direction="row" alignItems="center" spacing="6px" sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', padding: '4px 6px', borderRadius: '20px' }}>
                                  <Box width="10px" height="10px" sx={{ borderRadius: '50%', backgroundColor: '#1aae70' }}>

                                  </Box>
                                  <Typography variant="body1" sx={{ color: '#1aae70', fontWeight: 600, fontSize: '10px', lineHeight: '16px', fontFamily: `'Public Sans',sans-serif`, }}  >
                                    Mining
                                  </Typography>


                                </Stack>
                              </>
                            )
                          }


                        </Stack>

                      </Stack>

                    </Box>

                    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '20px', padding: '25px 30px', width: '600px' }}>
                      <Stack alignItems="start" spacing="20px">
                        <Stack direction="row" alignItems="start" >
                          <Typography variant="body1" sx={{ color: '#C0C0C0', fontWeight: 500, fontSize: '16px', lineHeight: '20px', fontFamily: `'Public Sans',sans-serif` }}  >
                            Total Mining Reward
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                          <Stack direction="row" alignItems="center" spacing="12px">
                            <TokenColorIcon name="HAYA" size={48} />
                            {
                              address == undefined ? (
                                <>
                                  <AnimatedNumber style={{ color: '#fff', fontWeight: 600, fontSize: '44px', lineHeight: '46px', fontFamily: `'Public Sans',sans-serif` }} value={0} duration={1000} easing={'easeOutQuad'} />
                                </>

                              ) : (
                                <>
                                  {
                                    outLentData?.rewardData.length == 0 ? (
                                      <Skeleton variant="text" sx={{ fontSize: '44px', lineHeight: '46px', width: '100px', height: '46px', backgroundColor: 'rgba(225, 225, 225, 0.60)' }} />

                                    ) : (
                                      // <Skeleton variant="text" sx={{ fontSize: '44px', lineHeight: '46px', width: '100px', height: '46px' }} />
                                      <AnimatedNumber style={{ color: '#fff', fontWeight: 600, fontSize: '44px', lineHeight: '46px', fontFamily: `'Public Sans',sans-serif` }} value={Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0) * 100000) / 100000} easing={'easeOutQuad'} duration={3000} stepPrecision={5} />

                                    )
                                  }
                                </>
                              )
                            }


                            {/* <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '44px', lineHeight: '46px', fontFamily: `'Public Sans',sans-serif`, '&::after': { content: `counter(${Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0) * 100000) / 100000})` }, 'animation': 'counter 5s infinite alternate ease-in-out', 'counterReset': 'num var(--num)', '--num': `${Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0) * 100000) / 100000 == 0 ? 100 : Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0) * 100000) / 100000}` }}  >
                              {Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0) * 100000) / 100000}
                            </Typography> */}
                          </Stack>

                          <ClaimButton sx={String(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0)) == '0' ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#FFF', '&:hover': { backgroundColor: '#19A56A', color: '#FFF' } }}>Claim All</ClaimButton>


                        </Stack>

                        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                          <Typography variant="body1" sx={{ color: '#C0C0C0', fontWeight: 500, fontSize: '16px', lineHeight: '20px', fontFamily: `'Public Sans',sans-serif` }}  >
                            Total {transferToNumber(rewardData.reduce((sum, current) => sum + Number(current.now_RewardNum), 0))} $HAYA Claimable
                          </Typography>
                          <ClaimButton sx={transferToNumber(rewardData.reduce((sum, current) => sum + Number(current.now_RewardNum), 0)) == '0' ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#FFF', '&:hover': { backgroundColor: '#19A56A', color: '#FFF' } }} onClick={onClaimAll}>Claim All</ClaimButton>
                        </Stack> */}

                      </Stack>
                    </Box>

                    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '20px', padding: '40px 30px', width: '600px' }}>
                      <Stack direction="row" alignItems="center" justifyContent="space-around">
                        <Stack spacing="10px" alignItems="center">
                          <Stack direction="row" alignItems="center" spacing="10px">
                            <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                              Staking
                            </Typography>

                          </Stack>
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px' }}  >
                            {outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)} <span style={{ fontSize: '20px' }}>NFTs</span>
                          </Typography>
                        </Stack>
                        <Box sx={{ width: '1px', backgroundColor: '#464646', height: '77px' }}></Box>
                        <Stack spacing="10px" alignItems="center">
                          <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                            Total Hash Rate
                          </Typography>
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px' }}  >
                            {outLentData?.hashUserRandData.reduce((sum: any, current: any) => sum + Number(current.hashRate), 0)} <span style={{ fontSize: '20px' }}>E</span>
                          </Typography>
                        </Stack>
                        <Box sx={{ width: '1px', backgroundColor: '#464646', height: '77px' }}></Box>
                        <Stack spacing="10px" alignItems="center">
                          <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                            Mining Days
                          </Typography>
                          {
                            outLentData?.mintDays == '' ? (
                              <>
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px', textTransform: 'capitalize' }}  >
                                  0 <span style={{ fontSize: '20px' }}>Days</span>
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px', textTransform: 'capitalize' }}  >
                                  {timeDifference(moment().unix() * 1000, Number(outLentData?.mintDays) * 1000)?.split('-')[0]} <span style={{ fontSize: '20px' }}>{timeDifference(moment().unix() * 1000, Number(outLentData?.mintDays) * 1000)?.split('-')[1]}</span>
                                </Typography>
                              </>
                            )
                          }

                        </Stack>
                      </Stack>
                    </Box>


                  </Stack>


                </Stack>
              </Box>
              <Box sx={{ padding: '100px 100px', background: `${Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? 'linear-gradient(to bottom,rgba(13, 53, 41, 0),rgba(13, 53, 41, 0.9) 10%,rgba(27, 27, 27, 0.55) 14%,#1B1B1B 16%)' : 'linear-gradient(to bottom,rgba(13, 53, 41, 0),#0D3529 10%,rgba(27, 27, 27, 0.55) 14%,#1B1B1B 16%)'}`, width: '100%', marginTop: `-${(((windowWidth * 1340) / 1920) * 445) / 1340}px` }}>
                <Box >
                  <NftPage hashData={hashRandData} onChange={onChange} windowHeight={windowHeight} windowWidth={windowWidth} />
                </Box>

              </Box>
              <Box sx={{ backgroundColor: '#1b1b1b', display: 'none' }}>
                <Box sx={{ backgroundColor: '#F6F6F6', padding: '60px 0', borderRadius: '50px 50px 0 0', }}>
                  <Box sx={{ maxWidth: '1340px', margin: '0 auto' }}>
                    <ReadPage windowHeight={windowHeight} windowWidth={windowWidth} />

                  </Box>

                </Box>

              </Box>
              <FooterBlock windowWidth={windowWidth} />

            </Container>
          </>
        ) : (
          <>
            <Container sx={{ width: `${windowWidth}px`, '&.MuiContainer-root': { maxWidth: `${windowWidth}px`, paddingLeft: '0px', paddingRight: '0px' } }}>

              <Box padding="0px" sx={Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? { backgroundImage: `url(${mobileBanner})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top', height: `880px`, backgroundSize: 'contain', width: '100%' } : { backgroundImage: `url(${minermobileBanner})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top', height: `880px`, backgroundSize: 'contain', width: '100%' }}>
                <Stack alignItems="start" justifyContent="center" padding="100px 10px 0 10px"  >
                  <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '38px', lineHeight: '50px', fontFamily: `'Public Sans',sans-serif`, pb: '10px' }}  >
                    {`${Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? 'Start Mining' : 'Mining...'}`}
                  </Typography>
                  <Stack alignItems="center" spacing="4px" justifyContent="center" width={`${windowWidth - 20}px`}>
                    <Box width="100%">
                      <Stack direction="row" spacing="10px" alignItems="center" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '10px', padding: '10px 20px', }}>
                        <BlockieAvatar style={{ width: '36px', height: '36px' }} address={String(address)} />
                        <Stack direction="row" spacing="10px" alignItems="center">
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '16px', lineHeight: '20px', fontFamily: `'Public Sans',sans-serif` }}  >
                            {address?.substring(0, 6)}...{address?.substring(address.length - 4)}
                          </Typography>
                          {
                            Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? (
                              <>
                                <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '4px 6px', borderRadius: '20px' }}>

                                  <Typography variant="body1" sx={{ color: '#C0C0C0', ontWeight: 600, fontSize: '10px', lineHeight: '16px', fontFamily: `'Public Sans',sans-serif` }}  >
                                    Inactive
                                  </Typography>
                                </Box>

                              </>
                            ) : (
                              <>
                                <Stack direction="row" alignItems="center" spacing="6px" sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', padding: '4px 6px', borderRadius: '20px' }}>
                                  <Box width="10px" height="10px" sx={{ borderRadius: '50%', backgroundColor: '#1aae70' }}>

                                  </Box>
                                  <Typography variant="body1" sx={{ color: '#1aae70', fontWeight: 600, fontSize: '10px', lineHeight: '16px', fontFamily: `'Public Sans',sans-serif`, }}  >
                                    Mining
                                  </Typography>


                                </Stack>
                              </>
                            )
                          }


                        </Stack>

                      </Stack>

                    </Box>

                    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '10px', padding: '10px 20px', width: "100%" }}>
                      <Stack alignItems="start" spacing="10px">
                        <Stack direction="row" alignItems="start" >
                          <Typography variant="body1" sx={{ color: '#C0C0C0', fontWeight: 500, fontSize: '16px', lineHeight: '20px', fontFamily: `'Public Sans',sans-serif` }}  >
                            Total Mining Reward
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                          <Stack direction="row" alignItems="center" spacing="12px">
                            <TokenColorIcon name="HAYA" size={32} />
                            {
                              address == undefined ? (
                                <>
                                  <AnimatedNumber style={{ color: '#fff', fontWeight: 600, fontSize: '32px', lineHeight: '46px', fontFamily: `'Public Sans',sans-serif` }} value={0} duration={1000} easing={'easeOutQuad'} />
                                </>

                              ) : (
                                <>
                                  {
                                    outLentData?.rewardData.length == 0 ? (
                                      <Skeleton variant="text" sx={{ fontSize: '44px', lineHeight: '34px', width: '100px', height: '46px', backgroundColor: 'rgba(225, 225, 225, 0.60)' }} />

                                    ) : (
                                      // <Skeleton variant="text" sx={{ fontSize: '44px', lineHeight: '46px', width: '100px', height: '46px' }} />
                                      <AnimatedNumber style={{ color: '#fff', fontWeight: 600, fontSize: '32px', lineHeight: '46px', fontFamily: `'Public Sans',sans-serif` }} value={Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current?.now_RewardNum), 0) * 100000) / 100000} easing={'easeOutQuad'} duration={3000} stepPrecision={5} />

                                    )
                                  }
                                </>
                              )
                            }
                            {/* <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '32px', lineHeight: '46px', fontFamily: `'Public Sans',sans-serif`, '@keyframes counter': { "from": { '--num': '0' }, 'to': { '--num': `${Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0) * 100000) / 100000}` } }, '&::after': { content: 'counter(num)' }, 'animation': 'counter 5s infinite alternate ease-in-out', 'counterReset': 'num var(--num)' }}  >
                              {Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0) * 100000) / 100000}
                            </Typography> */}
                          </Stack>

                          <ClaimButton sx={String(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0)) == '0' ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#FFF', '&:hover': { backgroundColor: '#19A56A', color: '#FFF' } }} >Claim All</ClaimButton>


                        </Stack>

                        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="body1" sx={{ color: '#C0C0C0', fontWeight: 500, fontSize: '16px', lineHeight: '20px', fontFamily: `'Public Sans',sans-serif` }}  >
              Total {transferToNumber(rewardData.reduce((sum, current) => sum + Number(current.now_RewardNum), 0))} $HAYA Claimable
            </Typography>
            <ClaimButton sx={transferToNumber(rewardData.reduce((sum, current) => sum + Number(current.now_RewardNum), 0)) == '0' ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#FFF', '&:hover': { backgroundColor: '#19A56A', color: '#FFF' } }} onClick={onClaimAll}>Claim All</ClaimButton>
          </Stack> */}

                      </Stack>
                    </Box>

                    <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '10px', padding: '20px 30px', width: '100%' }}>
                      <Stack direction="row" alignItems="center" justifyContent="space-around">
                        <Stack spacing="10px" alignItems="center">
                          <Stack direction="row" alignItems="center" spacing="10px">
                            <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '12px', lineHeight: '20px' }}  >
                              Staking
                            </Typography>

                          </Stack>
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '24px', lineHeight: '26px' }}  >
                            {outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)} <span style={{ fontSize: '18px' }}>NFTs</span>
                          </Typography>
                        </Stack>
                        <Box sx={{ width: '1px', backgroundColor: '#464646', height: '77px' }}></Box>
                        <Stack spacing="10px" alignItems="center">
                          <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '12px', lineHeight: '20px' }}  >
                            Total Hash Rate
                          </Typography>
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '24px', lineHeight: '26px' }}  >
                            {outLentData?.hashUserRandData.reduce((sum: any, current: any) => sum + Number(current.hashRate), 0)} <span style={{ fontSize: '18px' }}>E</span>
                          </Typography>
                        </Stack>
                        <Box sx={{ width: '1px', backgroundColor: '#464646', height: '77px' }}></Box>
                        <Stack spacing="10px" alignItems="center">
                          <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '12px', lineHeight: '20px' }}  >
                            Mining Days
                          </Typography>
                          {
                            outLentData?.mintDays == '' ? (
                              <>
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '24px', lineHeight: '26px', textTransform: 'capitalize' }}  >
                                  0 <span style={{ fontSize: '18px' }}>Days</span>
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '24px', lineHeight: '26px', textTransform: 'capitalize' }}  >
                                  {timeDifference(moment().unix() * 1000, Number(outLentData?.mintDays) * 1000)?.split('-')[0]} <span style={{ fontSize: '20px' }}>{timeDifference(moment().unix() * 1000, Number(outLentData?.mintDays) * 1000)?.split('-')[1]}</span>
                                </Typography>
                              </>
                            )
                          }

                        </Stack>
                      </Stack>
                    </Box>


                  </Stack>


                </Stack>
              </Box>
              <Box sx={{ padding: '20px 10px', background: `${Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? 'linear-gradient(to bottom,rgba(13, 53, 41, 0),rgba(13, 53, 41, 0.9) 4%,rgba(27, 27, 27, 0.55) 6%,#1B1B1B 8%)' : 'linear-gradient(to bottom,rgba(13, 53, 41, 0),#0D3529 4%,rgba(27, 27, 27, 0.55) 6%,#1B1B1B 8%)'}`, width: '100%', marginTop: `-360px` }}>
                <Box >
                  <NftPage hashData={hashRandData} onChange={onChange} windowHeight={windowHeight} windowWidth={windowWidth} />
                </Box>

              </Box>
              <Box sx={{ backgroundColor: '#1b1b1b', display: 'none' }}>
                <Box sx={{ backgroundColor: '#f6f6f6', padding: '30px 10px', borderRadius: '25px 25px 0 0' }}>
                  <ReadPage windowHeight={windowHeight} windowWidth={windowWidth} />

                </Box>

              </Box>
              <FooterBlock windowWidth={windowWidth} />

            </Container>
          </>
        )
      }
    </>
  )
}

export default MinerPage
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken } from "utils/token";

type UserInfoType = {
  tiger_one_rewards: string;
  tiger_two_rewards: string;
  pool_dividend_rewards: string;
  pending_withdrawed_rewards: string;
  withdrawed_rewards_amount: string
}

type DataType = {
  data: UserInfoType
  success?: boolean
  error?: null | string
}

type Data = {
  MiningTigerRewardSummariesData: DataType,
  status: number
}




// 获取用户信息
export const getMiningTigerRewardSummariesData = createAsyncThunk('account/MiningTigerRewardSummaries', async (_, thunkkAPI?: any) => {
  try {
    const res = await http.get('/mining_tiger_reward_summaries', {
      "headers": {
        "Authorization": `Bearer ${getToken().miner_token}`,
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 SaleTigerRewardSummaries', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  MiningTigerRewardSummariesData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getMiningTigerRewardSummaries',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getMiningTigerRewardSummariesData.fulfilled, (state, action) => {
        state.MiningTigerRewardSummariesData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getMiningTigerRewardSummariesData.rejected, (state, action: any) => {
        state.status = 401
        state.MiningTigerRewardSummariesData.data.tiger_one_rewards = '0'
        state.MiningTigerRewardSummariesData.data.tiger_two_rewards = '0'
      })
  },
  reducers: {}
})


export default slice.reducer



type Props = {
  size: number
}

const H20Token = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#00BD75" />
      <path d="M15.6933 13.2454C15.6933 15.6343 13.8138 17.7324 11.4269 17.7324C13.6818 16.0923 11.1484 13.7694 11.1484 13.7694C13.3414 11.4815 13.4941 7.37609 13.459 7.20898C14.7505 9.50099 14.996 10.2024 15.324 11.269C15.4725 11.7497 15.6933 12.5336 15.6933 13.2454Z" fill="#77ED5F" />
      <path d="M12.729 13.1598C9.9955 14.9216 10.4556 16.5555 11.4252 17.7314C8.73912 17.5829 7 15.588 7 13.5662C7 12.1572 7.3796 11.0308 7.84584 9.98687C9.20536 6.94392 11.3777 4 11.3777 4C12.1988 5.0934 12.7806 6.07333 13.3231 6.98106C13.3231 6.98106 15.9246 11.1009 12.729 13.1598Z" fill="white" />
    </svg>
  )
}

export default H20Token

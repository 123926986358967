import { Box, Stack, Typography } from "@mui/material"
import { MinerFactoryContract, MinerStakingContract, sepolia_rpc } from "config"
import { useEffect, useRef, useState } from "react"
import AllNft from "./allNft"
import MyNFT from "./myNft"
import minerFactoryAbi from 'abi/minerFactory.json'
import { ethers } from "ethers"
import tokenAbi from 'abi/token.json'
import { useAccount } from "wagmi"
import MinerStakingAbi from 'abi/minerStaking.json'
import BronzeIcon from 'assets/images/nftIcons/gif/Bronze.gif'
import GoldIcon from 'assets/images/nftIcons/gif/Gold.gif'
import MiniIcon from 'assets/images/nftIcons/gif/Mini.gif'
import SilverIcon from 'assets/images/nftIcons/gif/Silver.gif'
import BronzePngIcon from 'assets/images/nftIcons/png/Bronze.png'
import GoldPngIcon from 'assets/images/nftIcons/png/Gold.png'
import MiniPngIcon from 'assets/images/nftIcons/png/Mini.png'
import SilverPngIcon from 'assets/images/nftIcons/png/Silver.png'
import { useOutletContext } from "react-router"
import FeerNft from "./feerNFT"
// import minerAbi from 'abi/miner.json'

const provider = new ethers.JsonRpcProvider(sepolia_rpc)




type PropsType = {
  windowWidth: number
  windowHeight: number
  onChange: () => void
  hashData: HashDataType[]
}

type HashDataType = {
  id: number
  hashRate: string
}
type DataType = {
  name: string;
  id: number
  price: string;
  hashRate: string;
  url: string;
  pngUrl: string
}


interface ListDataType {
  key: number;
  index: string;
  name: string;
  token_id: string;
  activate_date: string;
  end_date: string;
  rewarded: string;
  status: string;
  nowReward: string;
  new_token_id: number


}






const NftPage = ({ windowHeight, windowWidth, onChange, hashData }: PropsType) => {

  const outLentData: any = useOutletContext()

  useEffect(() => {

  }, [outLentData?.rewardData])

  useEffect(() => {

  }, [outLentData?.hashUserRandData])

  const [value, setValue] = useState('All NFT')

  const OnCheckTitel = (text: string) => {
    setValue(text)
  }

  const [listData, setListData] = useState<ListDataType[]>([])

  const [nftData, setNftData] = useState<DataType[]>([
    { name: 'Mini', id: 0, price: '', hashRate: '', url: MiniIcon, pngUrl: MiniPngIcon },
    { name: 'Bronze', id: 1, price: '', hashRate: '', url: BronzeIcon, pngUrl: BronzePngIcon },
    { name: 'Silver', id: 2, price: '', hashRate: '', url: SilverIcon, pngUrl: SilverPngIcon },
    { name: 'Gold', id: 3, price: '', hashRate: '', url: GoldIcon, pngUrl: GoldPngIcon }
  ])



  useEffect(() => {
    let newArr = []
    for (let i = 0; i < outLentData?.newData.length; i++) {
      if (Number(outLentData?.newData[i]?.num) !== 0) {
        newArr.push(
          { new_token_id: outLentData?.newData[i]?.token_id, key: newArr.length + 1, name: outLentData?.newData[i]?.name, token_id: String(outLentData?.newData[i]?.id), activate_date: '', end_date: '', rewarded: String(outLentData?.newData[i]?.num), status: 'Unactivated', nowReward: '0', totalReward: '0', index: '0' }
        )
      }
      if (Number(outLentData?.newData[i]?.miningNum) !== 0) {
        for (let j = 0; j < outLentData?.newData[i]?.mining_detail.length; j++) {
          newArr.push(
            { new_token_id: outLentData?.newData[i]?.token_id, key: newArr.length + 1, index: String(outLentData?.newData[i]?.mining_detail[j]?.index), name: outLentData?.newData[i]?.name, token_id: String(outLentData?.newData[i]?.id), activate_date: String(outLentData?.newData[i]?.mining_detail[j]?.start_time), end_date: String(outLentData?.newData[i].mining_detail[j]?.end_time), rewarded: String(outLentData?.newData[i]?.mining_detail[j]?.rewarded), status: 'Mining', nowReward: String(outLentData?.newData[i]?.mining_detail[j]?.now_rewardNum) }
          )
        }
      }
      if (Number(outLentData?.newData[i]?.expired_num) !== 0) {

        for (let j = 0; j < outLentData?.newData[i]?.expired_detail.length; j++) {


          newArr.push(
            { new_token_id: outLentData?.newData[i]?.token_id, key: newArr.length + 1, index: String(outLentData?.newData[i]?.expired_detail[j]?.index), name: outLentData?.newData[i]?.name, token_id: String(outLentData?.newData[i]?.id), activate_date: String(outLentData?.newData[i]?.expired_detail[j]?.start_time), end_date: String(outLentData?.newData[i].expired_detail[j]?.end_time), rewarded: String(outLentData?.newData[i]?.expired_detail[j]?.rewarded), status: 'Expired', nowReward: String(outLentData?.newData[i]?.expired_detail[j]?.now_rewardNum) }
          )

        }
      }
    }
    setListData(newArr)

  }, [outLentData?.newData])

  // const getListData = async () => {
  //   const ids = [
  //     { id: 0, name: 'Mini' },
  //     { id: 1, name: 'Bronze' },
  //     { id: 2, name: 'Silver' },
  //     { id: 3, name: 'Gold' },
  //   ]
  //   const contract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, provider)

  //   const addressList = [address, address, address, address]
  //   const idsList = [0, 1, 2, 3]
  //   const Minercontract = new ethers.Contract(MinerContract, minerAbi, provider)



  //   await contract.getMiningStatusLength(address).then(async (res1: any) => {
  //     let newArr: ListDataType[] = []
  //     await Minercontract.balanceOfBatch(addressList, idsList).then((res: any) => {

  //       for (let i = 0; i < res.length; i++) {
  //         if (Number(res[i]) !== 0) {
  //           newArr.push(
  //             { key: newArr.length + 1, name: ids.filter((item) => item.id == Number(i))[0].name, token_id: String(i), activate_date: '', end_date: '', rewarded: String(res[i]), status: 'Unactivated', nowReward: '0', totalReward: '0' }
  //           )

  //         }
  //       }
  //     })
  //     for (let i = 0; i < Number(res1); i++) {
  //       await contract.getMiningStatus(address, i, i).then(async (res2: any) => {
  //         if (Number(res2[0][2]) <= Number(moment().unix())) {


  //           if (Number(res2[0][2]) !== Number(res2[0][4])) {
  //             await contract.getUnclaimedRewards(address, i, i, res2[0][2]).then(async (res3: any) => {
  //               newArr.push(
  //                 { key: newArr.length + 1, name: ids.filter((item) => item.id == Number(res2[0][0]))[0].name, token_id: String(res2[0][0]), activate_date: String(res2[0][1]), end_date: String(res2[0][2]), rewarded: String(res2[0][5]), status: 'Expired', nowReward: String(Number(res3) / (10 ** 18)), totalReward: String(Number(res3) / (10 ** 18)) }
  //               )

  //             })



  //           }
  //         } else {
  //           await contract.getUnclaimedRewards(address, i, i, res2[0][2]).then(async (res3: any) => {
  //             await contract.getUnclaimedRewards(address, i, i, moment().unix()).then(async (res4: any) => {
  //               newArr.push(
  //                 { key: newArr.length + 1, name: ids.filter((item) => item.id == Number(res2[0][0]))[0].name, token_id: String(res2[0][0]), activate_date: String(res2[0][1]), end_date: String(res2[0][2]), rewarded: String(res2[0][5]), status: 'Mining', nowReward: String((Number(res4) / (10 ** 18))), totalReward: String((Number(res3) / (10 ** 18))) }
  //               )
  //             })

  //           })

  //         }
  //       })

  //     }
  //     setListData(newArr)

  //   })

  // }

  useEffect(() => {
    // console.log('listdata', listData)

  }, [listData])


  const getPrice = async (id: number) => {
    const contract = new ethers.Contract(MinerFactoryContract, minerFactoryAbi, provider)
    const Stakcontract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, provider)

    await contract.minerPrices(id).then(async (res: any) => {

      setNftData((pre) => pre.map((item) => item.id == id ? { ...item, price: String(Number(res) / (10 ** 6)) } : item))
    })

    await Stakcontract.hashRates(id).then(async (res1: any) => {
      setNftData((pre) => pre.map((item) => item.id == id ? { ...item, hashRate: String(Number(res1) / (10 ** 3)) } : item))
    })

  }

  const { address } = useAccount()

  const [allowance, setAllowance] = useState('')

  const getAllowance = async () => {
    const contract = new ethers.Contract(MinerFactoryContract, minerFactoryAbi, provider)



    await contract.tokenForMint().then(async (res: any) => {

      const TokenContract = new ethers.Contract(res, tokenAbi, provider)

      await TokenContract.allowance(address, MinerFactoryContract).then(async (res: any) => {

        setAllowance(String(res))
      })
    })

  }

  useEffect(() => {

  }, [allowance])

  useEffect(() => {
    getAllowance()
    for (let i = 0; i < nftData.length; i++) {
      getPrice(nftData[i].id)
    }

  }, [])

  const onUpdate = () => {
    // getListData()
    onChange()

  }



  // useEffect(() => {
  //   if (address !== undefined) {
  //     getListData()

  //   }

  // }, [address])




  useEffect(() => {


  }, [nftData])
  const numberRef = useRef(0)

  useEffect(() => {
    if (numberRef.current == 0) {
      if (Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0) {
        setValue('All NFT')
      } else {
        setValue('My NFT')
      }
      numberRef.current = 1
    }

  }, [outLentData?.newData])

  const nftChange = () => {
    setValue('All NFT')
  }




  return (
    <>
      {
        windowWidth >= 600 ? (
          <Box sx={{ maxWidth: '1340px', margin: '0 auto', padding: '0px' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom="10px" >
              <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '18px', lineHeight: '14px' }}  >
                Miners NFTs
              </Typography>
              <Box sx={{ backgroundColor: '#000', borderRadius: '16px', padding: '2px' }}>
                <Stack direction="row" alignItems="center">
                  <Box sx={value == 'All NFT' ? ({ p: '4px 30px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '4px 30px', backgroundColor: 'transparent', fontWeight: 600, color: '#9B9B9B', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('All NFT')}>
                    All NFT
                  </Box>
                  <Box sx={value == 'Event NFT' ? ({ p: '4px 30px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '4px 30px', backgroundColor: 'transparent', fontWeight: 600, color: '#9B9B9B', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Event NFT')}>
                    Event NFT 🔥
                  </Box>


                  <Box sx={value == 'My NFT' ? ({ p: '4px 30px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '4px 30px', backgroundColor: 'transparent', fontWeight: 600, color: '#9B9B9B', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('My NFT')}>
                    <Stack direction="row" alignItems="center" spacing="6px">
                      <Typography variant="body1" sx={{ color: `${value == 'My NFT' ? '#000' : '#9B9B9B'}`, fontWeight: 600, fontSize: '14px', lineHeight: '14px' }}  >
                        My NFT
                      </Typography>
                      <Box sx={{ width: 'auto', height: 'auto', borderRadius: '20px', padding: '2px 5px', backgroundColor: '#1aae70' }}>
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 500, fontSize: '12px', lineHeight: '16px' }}  >
                          {outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.total_num), 0)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {
              value == "All NFT" ? (
                <>
                  <AllNft windowHeight={windowHeight} windowWidth={windowWidth} onChange={onChange} nftData={nftData} />
                </>
              ) : (
                <>
                  {
                    value == 'Event NFT' ? (
                      <>
                        <FeerNft windowHeight={windowHeight} windowWidth={windowWidth} onChange={onChange} changeNFT={nftChange} />

                      </>
                    ) : (
                      <>
                        <MyNFT hashData={hashData} nftChange={nftChange} onUpdate={onUpdate} listData={listData} onChange={onChange} hashRateData={nftData} windowHeight={windowHeight} windowWidth={windowWidth} />

                      </>
                    )
                  }                </>
              )
            }


          </Box>
        ) : (
          <Box sx={{ padding: '0px' }}>
            <Stack alignItems="start" spacing="20px" marginBottom="20px" >
              <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '22px', lineHeight: '30px' }}  >
                Miners NFTs
              </Typography>
              <Box sx={{ backgroundColor: '#000', borderRadius: '16px', padding: '2px' }}>
                <Stack direction="row" alignItems="center">
                  <Box sx={value == 'All NFT' ? ({ p: '4px 30px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '4px 30px', backgroundColor: 'transparent', fontWeight: 600, color: '#9B9B9B', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('All NFT')}>
                    All NFT
                  </Box>
                  <Box sx={value == 'Event NFT' ? ({ p: '4px 30px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '4px 30px', backgroundColor: 'transparent', fontWeight: 600, color: '#9B9B9B', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Event NFT')}>
                    Event NFT 🔥
                  </Box>
                  <Box sx={value == 'My NFT' ? ({ p: '4px 30px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '4px 30px', backgroundColor: 'transparent', fontWeight: 600, color: '#9B9B9B', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('My NFT')}>
                    <Stack direction="row" alignItems="center" spacing="6px">
                      <Typography variant="body1" sx={{ color: `${value == 'My NFT' ? '#000' : '#9B9B9B'}`, fontWeight: 600, fontSize: '14px', lineHeight: '14px' }}  >
                        My NFT
                      </Typography>
                      <Box sx={{ width: 'auto', height: 'auto', borderRadius: '20px', padding: '2px 5px', backgroundColor: '#1aae70' }}>
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 500, fontSize: '12px', lineHeight: '16px' }}  >
                          {outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.total_num), 0)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {
              value == "All NFT" ? (
                <>
                  <AllNft windowHeight={windowHeight} windowWidth={windowWidth} onChange={onChange} nftData={nftData} />
                </>
              ) : (
                <>
                  {
                    value == 'Event NFT' ? (
                      <>
                        <FeerNft windowHeight={windowHeight} windowWidth={windowWidth} onChange={onChange} changeNFT={nftChange} />

                      </>
                    ) : (
                      <>
                        <MyNFT hashData={hashData} nftChange={nftChange} onUpdate={onUpdate} listData={listData} onChange={onChange} hashRateData={nftData} windowHeight={windowHeight} windowWidth={windowWidth} />

                      </>
                    )
                  }                </>
              )
            }


          </Box>
        )
      }
    </>
  )
}

export default NftPage
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken } from "utils/token";

type UserInfoType = {
  user_id: string;
  user_wallet_address: string;
  invite_code: string;
  invite_wallet_address: string;
}

type DataType = {
  data: UserInfoType
  success?: boolean
  error: null | string
}

type Data = {
  userInfoData: DataType,
  status: number
}

// 获取用户信息
export const getUserInfoData = createAsyncThunk('account/profile', async (_, thunkkAPI?: any) => {
  try {
    const res = await http.get('/profile', {
      "headers": {
        "Authorization": `Bearer ${getToken().miner_token}`,
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 getUserInfo', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)

  }
})

const initialState = {
  userInfoData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getUserInfo',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getUserInfoData.fulfilled, (state, action) => {
        state.userInfoData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getUserInfoData.rejected, (state, action: any) => {
        state.status = 401
      })

  },
  reducers: {}
})


export default slice.reducer

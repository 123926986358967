import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"

type ChallengeType = {
  account: string;
  challenge: string
}

type DataType = {
  data: ChallengeType
  success?: boolean
  error: null | string
}

type Data = {
  challengeData: DataType
  code: number
}

// 获取邀请码
export const getChallengeData = createAsyncThunk('account/get_callenge', async (address: string) => {
  const res = await http.get(`/get_challenge/${address}`, {
    "headers": {
      "Content-Type": "application/json"
    }
  })
  // console.log('获取 getCallenge', res)
  return res
})


const initialState = {
  challengeData: {},
  code: 0
} as Data


const slice = createSlice({
  name: 'getChallenge',
  initialState,
  extraReducers: builder => {
    builder.addCase(getChallengeData.fulfilled, (state, action) => {
      state.challengeData = action.payload.data
      state.code = action.payload.status
    });
  },
  reducers: {}
})


export default slice.reducer

import { Box, Button, ButtonProps, Dialog, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CheckIcon from 'assets/icons/Check.svg'
import { useAccount } from "wagmi";
import { getEthersSigner } from "contract/getEthersSigner";
import { config } from "contexts/wagmiConfig";
import { ethers } from "ethers";
import { MinerContract, MinerFreeContract, MinerFreeStakingContract, MinerStakingContract, sepolia_rpc } from "config";
import minerAbi from 'abi/miner.json'
import { useEffect, useState } from "react";
import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import WarningIcon from '@mui/icons-material/Warning';
import StakeApprovalTokens from "./ApproveStake";
import Confirm from "./stakeTranstion/confirm";
import Succeed from "./stakeTranstion/succeed";
import StakeMiner from "./StakeMiner";
import MinerStakingAbi from 'abi/minerStaking.json'
import MinerFeerStakingAbi from 'abi/minerFeerStaking.json'
import minerFeerAbi from 'abi/minFeer.json'




const provider = new ethers.JsonRpcProvider(sepolia_rpc)


type TypeProps = {
  windowWidth: number;
  windowHeight: number
  open: boolean;
  handleConfirmClose: () => void;
  onChange: () => void
  NFTName: string
  NFTNum: string
  NFTId: number
  url: string
  token_id: number

}

const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  width: '100%',
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
}));







const ActivateMiner = ({ windowWidth, handleConfirmClose, open, onChange, NFTName, NFTNum, NFTId, windowHeight, url, token_id }: TypeProps) => {


  const { address } = useAccount()



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));

  const [openSucced, setOpenSucced] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const [openApproval, setOpenApproval] = useState(false)
  const [openLevelUp, setOpenLevelUp] = useState<boolean>(false)


  const handleTranstionConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSucceedClose = () => {
    setOpenSucced(false)
    setOpenLevelUp(true)
  }

  const handleLevelUpClose = () => {
    setOpenLevelUp(false)
    onChange()
  }


  useEffect(() => {

  }, [openConfirm])

  useEffect(() => {

  }, [openSucced])


  const handleApprovalClose = () => {
    setOpenApproval(false)
  }

  const [hash, setHash] = useState('')

  useEffect(() => {

  }, [hash])


  const notificonfig = {
    top: windowHeight * 0.4,

  }


  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement, err: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      closeIcon: windowWidth >= 600 ? true : false,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '400px' : '160px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,

    });
  };






  const goViewDetail = async () => {

    if (Number(NFTId) == 6) {
      const FeerApprovedContract = new ethers.Contract(MinerFreeContract, minerFeerAbi, provider)

      await FeerApprovedContract.isApprovedForAll(address, MinerFreeStakingContract).then(async (res) => {
        if (!Boolean(res)) {
          handleConfirmClose()
          setOpenApproval(true)
        } else {
          const Signer = getEthersSigner(config)

          const FeerStakingContract = new ethers.Contract(MinerFreeStakingContract, MinerFeerStakingAbi, await Signer)

          setOpenConfirm(true)
          handleConfirmClose()

          await FeerStakingContract.mining(MinerFreeContract, token_id).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenConfirm(false)
              setOpenSucced(true)

            }

          }).catch((err) => {
            // console.log("错误结果", err)
            openNotification('top', `feermining ---- 💎 You have staked a Free Miner. You can gift this one to a friend! Don't forget, you can buy another Haya Miner! 🚀 `)
            handleConfirmClose()
            setOpenConfirm(false)
          })

        }
      }).catch((err) => {

      })
    } else {
      const ApprovedContract = new ethers.Contract(MinerContract, minerAbi, provider)

      await ApprovedContract.isApprovedForAll(address, MinerStakingContract).then(async (res) => {
        if (!Boolean(res)) {
          handleConfirmClose()
          setOpenApproval(true)
        } else {
          const Signer = getEthersSigner(config)

          const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)

          setOpenConfirm(true)
          handleConfirmClose()

          await StakingContract.mining(NFTId, 1).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenConfirm(false)
              setOpenSucced(true)

            }

          }).catch((err) => {
            // console.log("错误结果", err)
            openNotification('top', `mining ---- ${err}`)
            handleConfirmClose()
            setOpenConfirm(false)
          })

        }
      }).catch((err) => {

      })
    }


  }

  const onClose = () => {
    handleConfirmClose()
    onChange()
  }



  return (
    <>
      {contextHolder}
      <StakeApprovalTokens token_id={token_id} url={url} windowHeight={windowHeight} windowWidth={windowWidth} handleApprovalClose={handleApprovalClose} open={openApproval} NFTName={NFTName} NFTNum={NFTName} id={NFTId} onUpdate={onChange} />
      <Confirm windowWidth={windowWidth} open={openConfirm} handleConfirmClose={handleTranstionConfirmClose} NFTName={NFTName} NFTNum={NFTNum} address={MinerStakingContract} />
      <Succeed url={url} windowWidth={windowWidth} open={openSucced} handleConfirmClose={handleSucceedClose} NFTName={NFTName} NFTNum={NFTNum} address={MinerStakingContract} hash={hash} />
      <StakeMiner url={url} windowWidth={windowWidth} open={openLevelUp} handleConfirmClose={handleLevelUpClose} NFTName={NFTName} NFTNum={NFTNum} onChange={onChange} />
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={onClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Activate Miner
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>

                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '0 20px', mb: '20px' }}>
                    <Stack alignItems="center" direction="row" spacing="20px">
                      <Box sx={{ width: '120px', height: '120px', lineHeight: '120px' }}>
                        <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                      </Box>
                      <Stack alignItems="start" spacing="2px" justifyContent="center">
                        <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '30px' }}>
                          {Number(NFTId) == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${NFTName} Miner`}
                        </Typography>

                      </Stack>


                    </Stack>

                  </Box>


                  <Stack alignItems="start" spacing="10px" >
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '32px', padding: '0 20px' }}>
                      Bind address
                    </Typography>


                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '12px 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9b9b9b', fontSize: '16px', fontWeight: 600, lineHeight: '36px' }}>
                          {address}
                        </Typography>
                        <img src={CheckIcon} />

                      </Stack>

                    </Box>


                  </Stack>





                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%" >
                  <ViewDetailButton onClick={goViewDetail}>Stake</ViewDetailButton>
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={onClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0', width: `${windowWidth}px` } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                    Activate Miner
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 0px 10px 0px' }}>

                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '0 10px', mb: '10px' }}>
                    <Stack alignItems="center" direction="row" spacing="20px">
                      <Box sx={{ width: '120px', height: '120px', lineHeight: '120px', textAlign: 'center' }}>
                        <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                      </Box>
                      <Stack alignItems="start" spacing="2px" justifyContent="center">
                        <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '30px' }}>
                          {Number(NFTId) == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${NFTName} Miner`}
                        </Typography>

                      </Stack>


                    </Stack>

                  </Box>


                  <Stack alignItems="start" spacing="10px" >
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '32px', padding: '0 20px' }}>
                      Bind address
                    </Typography>


                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '12px 10px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9b9b9b', fontSize: '12px', fontWeight: 600, lineHeight: '36px' }}>
                          {address}
                        </Typography>
                        <img src={CheckIcon} />

                      </Stack>

                    </Box>


                  </Stack>





                </Box>
                <Stack width="100%" >
                  <ViewDetailButton onClick={goViewDetail}>Stake</ViewDetailButton>
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }

    </>
  )

}
export default ActivateMiner
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"

type UserInfoType = {
  total_current_hashrates: number;
  total_rewards: string;
  total_activing_miners: number
}

type DataType = {
  data: UserInfoType
  success?: boolean
  error?: null | string
}

type Data = {
  MiningPoolRewardsData: DataType,
  status: number
}




// 获取用户信息
export const getPoolRewardsData = createAsyncThunk('account/PoolRewards', async (_, thunkkAPI?: any) => {
  try {
    const res = await http.get('/pool_rewards', {
      "headers": {
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 PoolRewards', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  MiningPoolRewardsData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getPoolRewards',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getPoolRewardsData.fulfilled, (state, action) => {
        state.MiningPoolRewardsData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getPoolRewardsData.rejected, (state, action: any) => {
        state.status = 401
        state.MiningPoolRewardsData.data.total_activing_miners = 0
        state.MiningPoolRewardsData.data.total_current_hashrates = 0
        state.MiningPoolRewardsData.data.total_rewards = String(0 * (10 ** 18))

      })
  },
  reducers: {}
})


export default slice.reducer


import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Drawer, Stack } from '@mui/material';
import TokenColorIcon from 'assets/tokens';
import { ButtonProps } from '@mui/material/Button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Web3 from 'web3';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers'
import { arb_url, HayaStakingContract } from 'config';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import WarningIcon from '@mui/icons-material/Warning';
import { getEthersSigner } from 'contract/getEthersSigner';
import { config } from 'contexts/wagmiConfig';
import Succeed from '../TransactionStatus/succeed';
import Confirm from '../TransactionStatus/confirm';
import levelAbi from 'abi/level.json';
import UnStakeLevelUp from '../levelUp/unStakeLevelUp';
import { useAccount } from 'wagmi';

// const sepolia_rpc = "https://sepolia.infura.io/v3/0edd253962184b628e0cfabc2f91b0ae"


const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
}));



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    width: '600px',
    borderRadius: '20px',
    padding: '20px 20px',

  },

  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiDialogActions-root': {
    padding: 0,
  },
  '& .customized-dialog-title': {
    borderBottom: 0
  }
}));


const ShowButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#1AAE70',
  boxShadow: 'none',
  padding: 0,
  "&::after": { boxShadow: 'none' },
  '&:hover': {
    backgroundColor: "#fff",
    color: '#1aae70',
  },
  '&:active': {
    boxShadow: 'none',

  },
}));






type TypeProps = {
  open: boolean;
  handleSwapClose: () => void;
  HayaNum: string;
  windowWidth: number
  windowHeight: number;
  onChange: () => void
}





export default function ConfirmUnStake({ open, handleSwapClose, HayaNum, windowWidth, windowHeight, onChange }: TypeProps) {


  const SwapButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#1aae70',
    padding: '20px 40px',
    fontSize: '24px',
    fontWeight: 600,
    width: '100%',
    lineHeight: '32px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#19A56A',
      color: '#fff',
    },
  }));


  const [hidder, setHidder] = useState(true)

  const onShowMore = () => {
    setHidder(!hidder)
  }

  const notificonfig = {
    top: windowHeight * 0.4,

  }

  const { address } = useAccount()


  const [api, contextHolder] = notification.useNotification(notificonfig);
  const [openSucced, setOpenSucced] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openLevelUp, setOpenLevelUp] = useState<boolean>(false)

  const openNotification = (placement: NotificationPlacement) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }} color="#000">
                  The transaction submission was either cancelled or failed.
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }} color="#000">
                  Coming soon
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      closeIcon: windowWidth >= 600 ? true : false,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '400px' : '160px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,

    });
  };


  // const provider = new ethers.BrowserProvider(window.ethereum)

  const [hash, setHash] = useState('')

  useEffect(() => {

  }, [hash])



  const handleSwap = async () => {
    // const signer = await provider.getSigner()


    const provider = getEthersSigner(config)
    const StakingContract = new ethers.Contract(HayaStakingContract, levelAbi, await provider)
    setOpenConfirm(true)
    handleSwapClose()

    await StakingContract.unstake().then(async (res) => {
      console.log(res)

      const res1 = await res.wait()
      if (res1.blockNumber == null) {
      } else {
        setHash(String(res1.hash))
        setOpenConfirm(false)
        setOpenSucced(true)

      }


    }).catch((err) => {
      setOpenConfirm(false)
      openNotification('top')
      handleSwapClose()
    })







  }


  const gotoContract = () => {
    window.location.assign(`${arb_url}${HayaStakingContract}`)
  }

  const handleConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSucceedClose = () => {
    setOpenSucced(false)
    setOpenLevelUp(true)
  }

  const handleLevelUpClose = () => {
    setOpenLevelUp(false)
  }

  useEffect(() => {

  }, [openConfirm])

  useEffect(() => {

  }, [openSucced])

  useEffect(() => {

  }, [openLevelUp])

  useEffect(() => {

  }, [HayaNum])







  return (
    <>
      {contextHolder}
      <UnStakeLevelUp windowWidth={windowWidth} open={openLevelUp} handleConfirmClose={handleLevelUpClose} HAYANum={HayaNum} onChange={onChange} />
      <Confirm windowWidth={windowWidth} open={openConfirm} handleConfirmClose={handleConfirmClose} HayaNum={HayaNum} address={String(address)} />
      <Succeed hash={hash} windowWidth={windowWidth} open={openSucced} handleConfirmClose={handleSucceedClose} HAYANum={HayaNum} address={String(address)} />
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleSwapClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Review UnStake
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleSwapClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <DialogContent >

                <Box sx={{ p: '12px 20px', mb: '10px' }}>
                  <Typography variant='body1' sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 600 }} color="#9b9b9b">
                    You UnStake
                  </Typography>


                  <Stack alignItems="center" direction="row" sx={{ padding: '10px 0' }} justifyContent="space-between">

                    <Typography variant='body1' sx={{ color: '#000', fontWeight: 600, fontSize: '32px', lineHeight: '36px' }}>
                      {Math.round(Number(HayaNum))} HAYA
                    </Typography>

                    <TokenColorIcon name="HAYA" size={36} />



                  </Stack>

                </Box>


                <Stack direction="row" alignItems="center" justifyContent="space-between" padding="0 20px">
                  <Box sx={{ flex: 1, backgroundColor: '#c0c0c0', height: '1px' }}></Box>
                  <ShowButton variant="text" onClick={onShowMore} endIcon={!hidder ? <KeyboardArrowUpIcon sx={{ color: '1aae70' }} /> : <KeyboardArrowDownIcon sx={{ color: '1aae70' }} />}>Fold</ShowButton>

                  <Box sx={{ flex: 1, backgroundColor: '#c0c0c0', height: '1px' }}></Box>
                </Stack>
                <Box sx={{ marginTop: '10px', mb: '20px', p: '0 20px', display: !hidder ? 'none' : 'block' }}>
                  <Stack spacing="10px">
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography sx={{ color: '#6F6F6F', fontSize: '14px', lineHeight: '22px' }}>
                        Contract
                      </Typography>
                      <Typography sx={{
                        color: '#1aae70',
                        padding: 0,
                        fontSize: '14px',
                        lineHeight: '22px',
                        '&:hover': {
                          color: "#19A56A",
                          backgroundColor: 'transparent'
                        }
                      }} component={Button} onClick={gotoContract} variant='body1' >
                        {HayaStakingContract.slice(0, 9)}
                      </Typography>

                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography sx={{ color: '#6F6F6F', fontSize: '14px', lineHeight: '22px' }}>
                        Transaction simulation
                      </Typography>
                      <Typography sx={{ color: '#464646', fontSize: '14px', lineHeight: '22px' }} variant='body1' >
                        Auto
                      </Typography>

                    </Stack>
                  </Stack>
                </Box>
              </DialogContent>
              <DialogActions>
                <SwapButton onClick={handleSwap}>
                  Confirm UnStake
                </SwapButton>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleSwapClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0' } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center" pb="10px">
                  <Typography sx={{ color: "#464646", fontSize: '22px', lineHeight: '24px', fontWeight: 700 }}>
                    Review UnStake
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleSwapClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>

                <Box sx={{ p: '12px 10px', mb: '10px' }}>
                  <Typography variant='body1' sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 600 }} color="#9b9b9b">
                    You UnStake
                  </Typography>


                  <Stack alignItems="center" direction="row" sx={{ padding: '10px 0' }} justifyContent="space-between">

                    <Typography variant='body1' sx={{ color: '#000', fontWeight: 600, fontSize: '32px', lineHeight: '36px' }}>
                      {Math.round(Number(HayaNum))} HAYA
                    </Typography>

                    <TokenColorIcon name="HAYA" size={36} />



                  </Stack>

                </Box>


                <Stack direction="row" alignItems="center" justifyContent="space-between" padding="0 10px">
                  <Box sx={{ flex: 1, backgroundColor: '#c0c0c0', height: '1px' }}></Box>
                  <ShowButton variant="text" onClick={onShowMore} endIcon={!hidder ? <KeyboardArrowUpIcon sx={{ color: '1aae70' }} /> : <KeyboardArrowDownIcon sx={{ color: '1aae70' }} />}>Fold</ShowButton>

                  <Box sx={{ flex: 1, backgroundColor: '#c0c0c0', height: '1px' }}></Box>
                </Stack>
                <Box sx={{ marginTop: '10px', mb: '10px', p: '0 10px', display: !hidder ? 'none' : 'block' }}>
                  <Stack spacing="10px">
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography sx={{ color: '#6F6F6F', fontSize: '14px', lineHeight: '22px' }}>
                        Contract
                      </Typography>
                      <Typography sx={{
                        color: '#1aae70',
                        padding: 0,
                        fontSize: '14px',
                        lineHeight: '22px',
                        '&:hover': {
                          color: "#19A56A",
                          backgroundColor: 'transparent'
                        }
                      }} component={Button} onClick={gotoContract} variant='body1' >
                        {HayaStakingContract.slice(0, 9)}
                      </Typography>

                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography sx={{ color: '#6F6F6F', fontSize: '14px', lineHeight: '22px' }}>
                        Transaction simulation
                      </Typography>
                      <Typography sx={{ color: '#464646', fontSize: '14px', lineHeight: '22px' }} variant='body1' >
                        Auto
                      </Typography>

                    </Stack>
                  </Stack>
                </Box>
                <SwapButton onClick={handleSwap}>
                  Confirm UnStake
                </SwapButton>

              </Box>
            </Box>
          </Drawer>


        )
      }
    </>
  );
}
type Props = {
  size: number
}

const AVAXColor = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect width="22" height="22" rx="11" fill="url(#pattern0_2957_4469)" />
      <defs>
        <pattern id="pattern0_2957_4469" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2957_4469" transform="scale(0.015625)" />
        </pattern>
        <image id="image0_2957_4469" width="64" height="64" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACdElEQVR4Xu2bPU7DQBBGuRlrcwTugejgLCBElxZBAQeABiREDzX5awEJIpSAI2Elz9jZ3Zm1dw1Peo298+3MVImibG21xNTkB+MsX9hYnGV9cnAoqcyPFjauLe+LgonJT9locE1+xj46odJYy7Kf1mAjXcv+gsLLY5F9qsMLY5V9q8BLYpf9i2B4KnIOLxiampzHCYalKueyhkHa/sDn2nIuKxiiLeF7bTlfIywOIeH7EHLOWliobR08py3n/BUWhbAJntWW864xzrIHFmi7CZ7XdmTMJecu4WFtbWGdtpy7hAe1tYV12nLuJTykrSus15bz/+0F8KW2vjBH2/8FtLEAX6a7u5UsbYMvYPb0xLmsYVYIl8MPTb7PF1r6Mnt8rGSFcFT8AsWHWkpgVkijWwBzQhtkARKYFdqoFsCcNlRfgARmrfp+e7eYf3xUnktVXcDrYMCZrGHWqi9Hx9ZnXVVdgARmNeXyvUS1BUhglk0uz/na+QKYQ+vgOV9VFiCBWS65PO9j8VH4kA9d9YU5dBOfz8NKjZvZnvjLkARm+eSyzkXxt8G38wv2Yw2zVnWF9baKFyCBWZJc1tsqWoAEZmnkMsfGcgE+S/CFOdQX5mxybfg+LKCAWU1y/iU81OT79Q3vb4T1dfrCnCY5dwkP9lXOXTIx2RUP983Rdn7PuddgQd/kvL/Cor7IOWthYV/knI2wOHU5nxUMSVXOZQ2DUpVzOcGw1OQ8XjA0FTmHCIbHLvtXgZfEKvtWhxfGIvsMCi/vWvbXGmykbdlPJxR/YmRjwd3eOWEfUVBpVFneFy3fzc7ZvMA585NjZIz13+eLs6wPxRfcM7S6mfjOrgAAAABJRU5ErkJggg==" />
      </defs>
    </svg>


  )
}

export default AVAXColor
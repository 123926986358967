import { Box, Button, ButtonProps, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import picIcon from 'assets/icons/Levelup.png'


type TypeProps = {
  windowWidth: Number;
  open: boolean;
  handleConfirmClose: () => void;
  HAYANum: string;
  onChange: () => void

}

const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  width: '100%',
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));







const UnStakeLevelUp = ({ windowWidth, handleConfirmClose, open, HAYANum, onChange }: TypeProps) => {



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: windowWidth >= 600 ? '600px' : `${windowWidth}px`,
      borderRadius: '20px',
      padding: windowWidth >= 600 ? '20px' : '10px 10px 20px 10px',
      margin: '10px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));



  const goViewDetail = () => {
    handleConfirmClose()
    onChange()

  }

  return (
    <>
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >

              <Stack direction="row" justifyContent="end" alignItems="end" padding="0 10px" mb="20px" width="100%">

                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}

                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>
                  <Box sx={{ width: '100%', mb: '20px', textAlign: 'center', height: '240px' }}>
                    <img src={picIcon} style={{ lineHeight: '240px', verticalAlign: 'middle' }} />
                  </Box>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="20px">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '30px', textAlign: 'center' }}>
                      Unstaked!
                    </Typography>
                    <Stack alignItems="center">
                      <Typography sx={{ color: "#6F6F6F", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                        {Math.round(Number(HAYANum))} HAYA unstaked succeed!
                      </Typography>
                      <Typography sx={{ color: "#6F6F6F", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                        Your staking node level has changed to basic
                      </Typography>

                    </Stack>


                  </Stack>




                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%">
                  <ViewDetailButton onClick={goViewDetail}>OK</ViewDetailButton>
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <BootstrapDialog
            onClose={handleConfirmClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >

            <Stack direction="row" justifyContent="end" alignItems="end" padding="0 10px" mb="20px" width="100%">

              <IconButton
                aria-label="close"
                onClick={handleConfirmClose}
                sx={{ color: "#9b9b9b" }}

              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <DialogContent >
              <Box sx={{ p: '0px 0px 20px 0px' }}>
                <Box sx={{ width: '100%', mb: '20px', textAlign: 'center', height: '240px' }}>
                  <img src={picIcon} style={{ lineHeight: '240px', verticalAlign: 'middle' }} />
                </Box>


                <Stack alignItems="center" spacing="10px" justifyContent="center" mb="20px">
                  <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '30px', textAlign: 'center' }}>
                    Unstaked!
                  </Typography>
                  <Stack alignItems="center">
                    <Typography sx={{ color: "#6F6F6F", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      {Math.round(Number(HAYANum))} HAYA unstaked succeed!
                    </Typography>
                    <Typography sx={{ color: "#6F6F6F", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      Your staking node level has changed to basic
                    </Typography>

                  </Stack>


                </Stack>




              </Box>
            </DialogContent>
            <DialogActions>
              <Stack width="100%">
                <ViewDetailButton onClick={goViewDetail}>OK</ViewDetailButton>
              </Stack>
            </DialogActions>
          </BootstrapDialog>
        )
      }

    </>
  )

}
export default UnStakeLevelUp
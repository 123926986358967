import { Box, Button, ButtonProps, Dialog, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import upIcon from 'assets/icons/Up.svg'
import { arb_url, MinerContract, network_Name, sepolia_rpc, uriAddress } from "config";
import infoIcon from 'assets/icons/Info.svg'
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import minerAbi from 'abi/miner.json'
import ConfirmMint from "./confirmMint";
import { useAccount } from "wagmi";
import { isAuth } from "utils/token";
// import ConnectWallet from "layout/CommonLayout/components/connectWallet/walletConnect";



const provider = new ethers.JsonRpcProvider(sepolia_rpc)

type TypeProps = {
  windowWidth: number;
  windowHeight: number
  open: boolean;
  handleConfirmClose: () => void;
  BuyMoney: string;
  BuyName: string;
  onChange: () => void
  id: number
  allowance: string
  mintAddress: string
  hashRate: string
  url: string
  token_id: number
  balance: string

}

const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  width: '100%',
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const SelectButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  padding: '20px 40px',
  borderRadius: '20px',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
  width: '100%',
  color: '#fff',
  border: 0,
  backgroundColor: '#9b9b9b',
  "&.Mui-disabled": {
    zIndex: 100,
    color: '#fff',

  },
  '&:hover': {
    backgroundColor: '#9b9b9b',
    color: '#fff',
  },
}));







const MintMiner = ({ windowWidth, handleConfirmClose, open, onChange, BuyMoney, BuyName, id, allowance, windowHeight, mintAddress, hashRate, url, token_id, balance }: TypeProps) => {



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));


  const [openConfirmMint, setOpenConfirmMint] = useState(false)


  const confirmMint = () => {
    handleConfirmClose()
    setOpenConfirmMint(true)
  }

  const [totalNum, setTotalNum] = useState('')

  const getTotalSupply = async (id: number) => {

    const contract = new ethers.Contract(MinerContract, minerAbi, provider)

    await contract.totalSupply(id).then((res: any) => {
      setTotalNum(String(res))
    }).catch((err) => {

    })
  }

  useEffect(() => {
    // console.log('11', totalNum)

  }, [totalNum])

  const handleConfirmMintClose = () => {
    setOpenConfirmMint(false)
  }

  useEffect(() => {
    getTotalSupply(id)
  }, [id])

  // const dispatch = useDispatch()

  // const onUpated = () => {
  //   dispatch(getUserInfoData())
  // }

  // const checkSuccess = (value: boolean) => {
  //   if (value) {
  //     dispatch(getUserInfoData())
  //   }
  // }

  const { address } = useAccount()

  // const connectWallet = () => {
  //   return (
  //     <ConnectWallet code="" windowWidth={windowWidth} windowHeight={windowHeight} onErrText={(err: string) => { }} onChange={onUpated} checkSuccess={checkSuccess} />
  //   )
  // }

  const goContractAddress = () => {
    window.open(`${arb_url}${MinerContract}`, '_blank')

  }

  const goMetadata = () => {
    window.open(`https://ipfs.io/ipfs/${uriAddress}/${id}`, '_blank')
  }



  return (
    <>
      <ConfirmMint balance={balance} token_id={token_id} url={url} mintAddress={mintAddress} windowHeight={windowHeight} windowWidth={windowWidth} open={openConfirmMint} handleConfirmClose={handleConfirmMintClose} onChange={onChange} NFTName={BuyName} NftPrice={BuyMoney} NftId={id} allowance={allowance} />
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Mint Miner
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>
                  <Box sx={{ width: '100%', mb: '20px', textAlign: 'center', height: '240px', lineHeight: '240px' }}>
                    <img src={url} style={{ borderRadius: '10px', width: '240px', lineHeight: '240px', verticalAlign: 'middle' }} />
                  </Box>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="20px">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 700, lineHeight: '30px', textAlign: 'center' }}>
                      HAYA {BuyName} Miner
                    </Typography>
                    {/* <Typography sx={{ color: "#1AAE70", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      {totalNum} Minted
                    </Typography> */}
                  </Stack>
                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px 10px 20px' }}>
                    <Stack alignItems="center" spacing="10px">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Collection
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner name
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA {BuyName} Miner
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Period of validity
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          180 Days
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Hash rate
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {hashRate} E
                        </Typography>
                      </Stack>

                      <Box sx={{ backgroundColor: '#c0c0c0', height: '0.5px', width: '100%' }}></Box>
                      <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px', textAlign: 'start', width: "100%" }}>
                        NFT Info
                      </Typography>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography component="button" onClick={goContractAddress} sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px', border: 0, backgroundColor: 'transparent' }}>
                          {MinerContract?.substring(0, 6)}...{MinerContract?.substring(MinerContract.length - 4)}
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Blockchain
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {network_Name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token standard
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ERC-1155
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token ID
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {id}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Metadata
                        </Typography>
                        <Typography component="button" onClick={goMetadata} sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px', border: 0, backgroundColor: 'transparent' }}>
                          {`ipfs://${uriAddress?.substring(0, 4)}...${uriAddress?.substring(uriAddress.length - 4)}/${id}`}
                        </Typography>
                      </Stack>

                    </Stack>

                  </Box>




                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%" alignItems="start" spacing="20px">
                  <Stack direction="row" alignItems="start" spacing="8px">
                    <img src={infoIcon} />
                    <Typography sx={{ color: '#6f6f6f', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                      Tip: Staking consumes the NFT, so it won't be returned after expiration.
                    </Typography>

                  </Stack>
                  {
                    address !== undefined ? (
                      <>
                        {
                          isAuth() ? (
                            <ViewDetailButton onClick={confirmMint}>{BuyMoney}$ Mint</ViewDetailButton>
                          ) : (
                            <SelectButton disabled>unregistered</SelectButton>

                          )
                        }
                      </>
                    ) : (
                      <SelectButton disabled>Not connected</SelectButton>
                    )
                  }
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleConfirmClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0', width: `${windowWidth}px` } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                    Mint Miner
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 0px 10px 0px' }}>
                  <Box sx={{ width: '100%', mb: '20px', textAlign: 'center', height: '240px', lineHeight: '240px' }}>
                    <img src={url} style={{ borderRadius: '10px', width: '240px', lineHeight: '240px', verticalAlign: 'middle' }} />
                  </Box>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="10px">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 700, lineHeight: '30px', textAlign: 'center' }}>
                      HAYA {BuyName} Miner
                    </Typography>
                    {/* <Typography sx={{ color: "#1AAE70", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      {totalNum} Minted
                    </Typography> */}
                  </Stack>
                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '15px 15px 10px 15px' }}>
                    <Stack alignItems="center" spacing="10px">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Collection
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner name
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA {BuyName} Miner
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Period of validity
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          180 Days
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Hash rate
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {hashRate} E
                        </Typography>
                      </Stack>

                      <Box sx={{ backgroundColor: '#c0c0c0', height: '0.5px', width: '100%' }}></Box>
                      <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px', textAlign: 'start', width: "100%" }}>
                        NFT Info
                      </Typography>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography component="button" onClick={goContractAddress} sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px', border: 0, backgroundColor: 'transparent' }}>
                          {MinerContract?.substring(0, 6)}...{MinerContract?.substring(MinerContract.length - 4)}
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Blockchain
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {network_Name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token standard
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ERC-1155
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token ID
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {id}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Metadata
                        </Typography>
                        <Typography component="button" onClick={goMetadata} sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px', border: 0, backgroundColor: 'transparent' }}>
                          {`ipfs://${uriAddress?.substring(0, 4)}...${uriAddress?.substring(uriAddress.length - 4)}/${id}`}
                        </Typography>
                      </Stack>

                    </Stack>

                  </Box>




                </Box>
                <Stack width="100%" alignItems="start" spacing="10px">
                  <Stack direction="row" alignItems="start" spacing="8px">
                    <img src={infoIcon} />
                    <Typography sx={{ color: '#6f6f6f', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                      Tip: Staking consumes the NFT, so it won't be returned after expiration.
                    </Typography>

                  </Stack>
                  {
                    address !== undefined ? (
                      <>
                        {
                          isAuth() ? (
                            <ViewDetailButton onClick={confirmMint}>{BuyMoney}$ Mint</ViewDetailButton>
                          ) : (
                            <SelectButton disabled>unregistered</SelectButton>

                          )
                        }
                      </>
                    ) : (
                      <SelectButton disabled>Not connected</SelectButton>
                    )
                  }
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }

    </>
  )

}
export default MintMiner
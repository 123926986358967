type Props = {
  size: number
}

const DefiPulseIndex = ({ size }: Props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} enable-background="new 0 0 50 50" xmlSpace="preserve">  <image id="image0" width={`${size}`} height={`${size}`} x="0" y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAM
WElEQVRo3sWaW29c13WAv7XPOXPO3DhDSRyRskTR8lWpbDmyXbtu7NhJA/jBF/mhcIGkTwFao6/9
AYHy0h/QICgK9KVFX4oWLuCicIHabe3YsSPLDmyLli3rxrt4GXJmyJkz57JXH86QEiWRHImOs4AN
EuScvde397rstc4Iu5CfvzItKGLFKaA6CvqQIPcb1VEVagoDggQAikYKqwILip0UlfMpfJbCpbxI
02ZT2jvVRXYB4QMnVOVJ4DgwCpSBEpAHfMAFHK4pmQAR0AFWe2MaOCvwgUa5D4C1bwXk5yenamCO
gzyh6AngD4CDQOEO96QLzAFngU9ATgMf9QC/WZCfvXBZRExgHO8I6PcVXgB5GrR4J5uxjcTAh8Ab
Am9Z5Lwiq/RhcjsqcerlaQNUFD0uYl4D/gTY+w0qfytpAe8p8nci+muEJSDd7gG3j0lrwEsi5q+A
+4DgdwwBmZ89J+gR4B+dVP4ZuLzdA852/zz1ysx9ID9B5C+BB3oQ36QpbSVCtskV4B6FiirzCnMK
3Grc8kROnZwR4B6UV4Ef9yC+DYAbxQXGEP4UwUHEAc70dN8kN53IqZPTBhgC+THwE+Do7wliXQQY
IAvvnpvKF46lZc1mmE0gp05O03voJZDXgO/8HgFulDJQUyFOhS+8VNrW3ALk1Mlp1Fgf5GFBfkaW
H7b1IREQ0/u50+h9fpdSQRhVGE9hNmclciw4tucjPQhEnbuBn5L5hLfVbKrgF4TvPBWw/24PPzDo
jVYrYFMlCpVuW2kspCxciVmcTgjXdGMDblMMcEiE1zA6E6WcWf+HC6DG4lkzkMITCs+zQ5ZWBdcT
Dj6Q475HfQplB3sTCaiFJFLirrLWsDTmUxYmE6a+ipgYj4g6eifeFwBPo/KsMTpNdivIQDxrSIVj
qjwPjPTIdxTHE9yc4HjgbKFRLsj+XhlyOHCvx5G2cvC8R1CCi592WFvWXjDqW4Qsz7yAylfAGxsg
qQAqjwPPcTt7dN0h2FTpdixxnKC90xEEMeC6Bs93cFyDXxDGjuWoDqfgrvLlhynRqkFuz84EeEqF
X+Ws8xbQdk+dnBVVHgT9LrD/No95Q1YWYj7670UunZsjDLuoWhzPUK7k2D9a5v7j+zgwViZf8jCO
UN2X549eqpAmdT59exXPLd/ukjngu5FJngD+z+3RPc4uQ223kzJ7uc3Fs6uEnXgjWrm5kIufdfjs
vQb3PzLII0/XOPzgAMYR9h8qcfSJmOWrdSbONvGcMiKGW+S7reQoyBPAO64ecIzMJCfI7lF3LNZa
ut2YKFTiLpheWIpDaDdiFqYjGosxcaj4BZfaXXkc1+Hw0QEaCymLU8uErTYO+Z7P9AUzChwLbFJ0
zZJUFI4A1d2AqCpWExAQEVLbJY5DUptsKDV5CcLuCl4h4kev3k+x7FEezHH4wSojhxOmLjSIO9wO
jAvcFeOMuYTp3QgDu4EAUCw2jQFFROiEDZZXJuiEjUwdBRGH5WZAKks89oMRiuU9AORLLsNjJdqr
MXMTK3SjGN+r9BsABlLMw65FD8udV3fXnYgltRHrmdHahCheI+w2N32u3VGuXO5wfnyCwaECxXKA
FwiD+z2Kl0vgzRC2V7BWKQRV+giiRTV6rxFjh8nq612CKNYm6IY5KKqKqt00rE3phiFzk0t02l0A
HBeCouA4hqGhAyQ0mZw7Q6fb6IXybWHyAqMG2EMWynaLgup6RSrZuMX6IqBWWV1pk0RZ0WcMeH72
+WKhgh/kWO1MMzX3MZ3uCqoW2RrGF9GaASnRX6X4jcp2blwqVvEDj5mFT5ld+Jx2uITVlC1OxgUG
jCo5vs16Q8EYoVwt4Oayq4lNye5dCooSBEVKxSpx0ubKzAfMLn5OO6xfd+KbxKiK39ed6hvmwPUc
7hrbR6GYuWaaKOGaxfb0dF0Pz8taA6mNmJo7w9TVT2h362x1lkaEiNtIpbuCUMXLuQyNVDl83zCF
UqZsFCr1uRTb65MYx8Vxr1URUdzh6tI4k7OnaXfqNwYAK6JdI+gaWQfwdw6RxCm1kSrPvniCvbVr
qWutYVm5mmJT7QUD2/OJTESEsNtkfulLpq5+TDtcQjVdDwAJ0HSBOlljbJeaZikkG73QaxVrs989
z2H44B6+9/zD/PDlR8n3zKq5lDI/kdBZs72kKSRpTBx1N00vYgijFjMLn2KMy/C+YxTzexFxuqrM
u9aaWRENd8shRnA9g5czCEIucMkXc4hbwMs57N1f4QcvP8qPXnmM4UODiAhqYe5CzMTZaMO4BYij
kG735hawiBAnIRNzH6EKI0PHKOb3dhAz4RrSK4pp7xYkFzgMj5YhtaSx0E1TUhwG9rgceXCEB46P
MnxwD0Exl0EozF6MuTIeszJ/XRNRhNW1BiuNxY265kZJ04ipq2ewNmJk6KG1UmHkvEvgXqJrm33q
u9ma1G7s5J6hAk+/eISoo6hK9pZBEnK+oVTJUywHOG4WbtNEuXolYfz9kNkLMdZeq9+TuMtqq06r
Vd927TgJma9/yVq4tHrk0LNfuXafaci0vQAsA4O3gYHVGMUHDLnAZfjQ9sWRTTPHnr2YmdPM1zHh
mmKca6YzvzDJwtI0SRJhzNYl8LqZrbUXW/XWpUuuTCcW+Bh4CvjDfhCErP5YbbZoNYT1nOo4LiJZ
9a5qsaqoNaQxRKFlbcVy9UrC5BcRy3Mp1tKDEEBpt5tcnhhnaWmmV2Btt42K5+XDgj84U69fXHZV
UOC0KF/0C4Jk5jH5dYN2tITnp3hewODgfvJBCTGGNI1JYouNXTpNpT6XUJ9NWVt/N6XXt4OUMFxj
YvIcc7MXabdbGGN2UEFwxPuqGOw9YzVVN5uSc8AnZK2gGjtcWUQg7gpff+jzv//xWxbqXzEwUOWh
Y89Q2z+K63gbjqoaozYzqzTRm1KviNBut5icOsf4F7+m1VreEQLAdXKopu9WC9XX292mblwWBfmN
om8Dr+4Ekmko2ChP2T1KPWnRWm6xuhJRziuue01fubYAItI7BUFEiKKQhYVJJibPMTt3gVZrGXst
0W27euAPTuX9ymfFQm1enIDrQT4HfVPhGWCYHdqlAI7jUB04gHKCdjRHnHTphA0cJ8etbj3WWpIk
oht1aLebrDQWWFqcZqk+u2FOO0EIguPk1HVzr5dKtXdLpRrGCzZd31ugHwr8pyJ/RtY07kuq5UMU
bZUwnGNi4jzWRplpyWYV0jQhikM6nVWazUWazSXiuIuI6cucsmkkDvzyl2L0dTE6LkYRo5vrEJsm
l8S4/4DwCPAI2/R/N88teE6ZJEmZmDxHvXGJKN7+5ey6D20XYm+1juPkFsqF2t+Xi7Vzg5XDDFXu
pd1t3tQajUDOCfK3wJd9r0B25EFugLvv+h57KnfjOH6vvNVbjtsXxXWC5cHSoXcF543ByuG5e0ZO
0O42WY3Cm3u8IqYFvCnwT8D47SxlxKEQDDI28iQj+47hubvuaWQIqvheOSrl976Xcwt/45jc9PUQ
AM6zR/86UwLJrsbGVbAdYLZn5AfI3uL2VUWKCH6uRC5XRNXSCZexdjdVguLnSnHer/xXIaj+UjV5
//uP/jS9HgK2rtUVuAj8C9lr4T8n60T25TOKMlAaQcRg05iFla939JktdsV6Tr6R9wffD3LlX6ra
N5977DVuhNjqRLguC9SB88ASMEb2Wm69X7yj+G6BUrFGGLWI4jVS21/ZIwgiJnUcf75aPvh24JdP
Cfa9rSD6AQGhg3JRVD7udSQP0G9DTwTX8Rko7idOOoRRsy8Y1wko+NVzleLBXzgm9wvjcOG5E39h
t4KA/tpA1sSmgdHTYqSu6DvZVzh4huyFyw7XGUPer3Jo+HGMcZlbPNszs+sfy2pwx8lp3q9Mu67/
74j7r6Wgdt4x7tXHj72YbgfRL8j6SiFwFmFe4Lwq7wMngGNkX6opbncy5WKNA7XjiBjmFs/SjdZA
LY6Tw3ODjnFy561N3/FzA+P5oPoO4p4tFfZz7N6n2AmiPxAF61tM10CiiCsLKvoWIr8CHkH1SbLk
OUrmQ0Uy09v0NSdVteXCcMI+om63FS23JiVJuouu4y8FfnWmVBj6aE9l7N+K+b0L+aAK4rKnMEa7
O78jRP8ncjMMiHYV+Q3CaU3JG/SQCA+p4X5UDytSAxkQJN8D6Sq0ivmhxbG7/rjuzf/WTdPkfxzj
fzC7eGa+kN/DWmdJgayDIi6+LBFLf9Hu/wGXFuZKaRofrQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAy
NC0wMy0xNFQwMzozMzo0MSswMDowME3eQB0AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDMtMTRU
MDM6MzM6NDErMDA6MDA8g/ihAAAAAElFTkSuQmCC" />
    </svg>


  )
}

export default DefiPulseIndex
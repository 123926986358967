type Props = {
  size: number
}

const H20ETH = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 72 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="38" height="38" rx="19" fill="#1AAE70" />
      <rect x="1" y="1" width="38" height="38" rx="19" stroke="white" stroke-width="2" />
      <path d="M28.1245 12.362V16.9501C28.1245 21.4242 24.4837 25.0632 20.0114 25.0632C19.2166 25.0632 18.4346 24.946 17.6855 24.7189V22.8161C17.6855 21.547 18.7185 20.514 19.9876 20.514H20.0114C20.4986 20.514 20.9619 20.417 21.385 20.2375C21.6102 20.1441 21.8227 20.0287 22.0205 19.8914C22.9581 19.2485 23.5753 18.1699 23.5753 16.9501V12.362C23.5753 11.1093 24.5954 10.0874 25.8499 10.0874C27.1044 10.0874 28.1245 11.1093 28.1245 12.362Z" fill="white" />
      <path d="M22.3121 15.0474V16.9503C22.3121 18.2194 21.2811 19.2523 20.0119 19.2523H19.9881C19.5943 19.2523 19.2171 19.3164 18.8636 19.4355C18.6292 19.5124 18.4039 19.6149 18.1952 19.7376C17.1366 20.3567 16.4242 21.5049 16.4242 22.8162V27.6351C16.4242 28.8878 15.4041 29.9097 14.1496 29.9097C12.8951 29.9097 11.875 28.8878 11.875 27.6351V22.8162C11.875 20.7412 12.657 18.8494 13.9408 17.4136C14.4261 16.8697 14.9847 16.3917 15.6001 15.9961C16.8656 15.1775 18.3728 14.7031 19.9881 14.7031C20.7829 14.7031 21.5649 14.8222 22.3121 15.0474Z" fill="white" />
      <rect x="33" y="1" width="38" height="38" rx="19" fill="#6780E3" />
      <rect x="33" y="1" width="38" height="38" rx="19" stroke="white" stroke-width="2" />
      <path d="M52.3717 8.03579V8.04771C52.3836 8.10736 52.3836 8.17893 52.3836 8.2505V16.8511C52.3717 16.8988 52.3359 16.9107 52.3001 16.9346C52.0496 17.0538 51.811 17.1612 51.5605 17.2685C51.2146 17.4236 50.8568 17.5906 50.5108 17.7457L49.2583 18.3183C48.9124 18.4733 48.5665 18.6284 48.2324 18.7835C47.8269 18.9743 47.4094 19.1533 47.0038 19.3441C46.6579 19.4992 46.3119 19.6662 45.9541 19.8213C45.6678 19.9525 45.3815 20.0718 45.1071 20.203C45.0833 20.2149 45.0594 20.2268 45.0356 20.2268C45.0236 20.2268 45.0236 20.2268 45.0117 20.2149L45.3338 19.6781C45.9541 18.6523 46.5624 17.6383 47.1827 16.6125C47.8388 15.515 48.5068 14.4176 49.1629 13.3201C49.7712 12.3062 50.3915 11.2923 50.9999 10.2784C51.4412 9.53879 51.8945 8.79922 52.3359 8.05964C52.3478 8.03579 52.3598 8.02386 52.3598 8H52.3717C52.3598 8.01193 52.3717 8.02386 52.3717 8.03579Z" fill="white" />
      <path d="M59.6957 20.2147L59.7076 20.2267L57.9541 21.2645L52.4431 24.521C52.4192 24.5329 52.3954 24.5448 52.3834 24.5568C52.3477 24.5568 52.3477 24.521 52.3477 24.509V24.4017V17.0298C52.3477 16.994 52.3477 16.9463 52.3596 16.9105C52.3715 16.8628 52.4073 16.8747 52.4431 16.8866C52.5982 16.9582 52.7652 17.0298 52.9203 17.1014C53.3855 17.3161 53.8507 17.5308 54.3159 17.7336C54.7215 17.9125 55.1151 18.1034 55.5207 18.2823C55.9263 18.4612 56.3318 18.6521 56.7374 18.831C57.0833 18.9861 57.4412 19.1531 57.7871 19.3081C58.1331 19.4632 58.4909 19.6302 58.8368 19.7853C59.1112 19.9046 59.3855 20.0358 59.6599 20.1551C59.6599 20.1909 59.6719 20.2028 59.6957 20.2147Z" fill="#8499E8" />
      <path d="M52.3717 31.9642C52.3717 31.9762 52.3598 31.9881 52.3598 32H52.3478C52.3478 31.9762 52.324 31.9642 52.312 31.9404C51.5725 30.9026 50.8329 29.8529 50.0933 28.8151C49.3418 27.7534 48.5784 26.6799 47.8269 25.6182C47.0992 24.5924 46.3597 23.5546 45.632 22.5287C45.4412 22.2543 45.2503 21.9919 45.0594 21.7175C45.0475 21.6937 45.0356 21.6818 45.0117 21.646C45.0475 21.646 45.0713 21.6698 45.0833 21.6818C46.1211 22.2901 47.147 22.8985 48.1847 23.5069C49.3776 24.2106 50.5585 24.9144 51.7514 25.6182L52.3598 25.9761C52.3836 25.9999 52.3836 26.0238 52.3836 26.0477V31.7972C52.3836 31.8569 52.3836 31.9165 52.3717 31.9642Z" fill="white" />
      <path d="M45 20.2391V20.2272C45.3817 20.0602 45.7515 19.8812 46.1332 19.7142C46.6223 19.4876 47.1113 19.2729 47.6004 19.0462C47.9702 18.8792 48.3519 18.7003 48.7217 18.5333C49.2704 18.2828 49.8072 18.0442 50.3559 17.7938C50.7257 17.6267 51.0955 17.4597 51.4772 17.2808C51.7397 17.1615 52.014 17.0423 52.2764 16.923C52.3003 16.911 52.3361 16.8991 52.348 16.8752C52.36 16.8752 52.36 16.8872 52.348 16.8991V24.4738C52.348 24.5095 52.3361 24.5453 52.36 24.5692C52.3361 24.605 52.3122 24.5692 52.3003 24.5573C52.1929 24.4976 52.0856 24.438 51.9782 24.3664C49.676 23.0065 47.3618 21.6348 45.0596 20.2749C45.0477 20.263 45.0238 20.2511 45 20.2391Z" fill="#C1CBF3" />
      <path d="M59.6716 21.646H59.6835C59.6835 21.6698 59.6597 21.6937 59.6478 21.7175C57.4529 24.8071 55.258 27.9085 53.0632 30.998C52.8365 31.3201 52.5979 31.6422 52.3713 31.9642C52.3594 31.9523 52.3594 31.9404 52.3594 31.9284V31.8569V26.0715V25.9642C52.8604 25.6659 53.3495 25.3796 53.8505 25.0814C55.7829 23.9363 57.7153 22.8031 59.6358 21.6579C59.6478 21.6698 59.6597 21.6579 59.6716 21.646Z" fill="#C2CCF3" />
      <path d="M52.3598 16.8989V16.875V16.8035V8.14328C52.3598 8.10749 52.3479 8.08364 52.3717 8.04785C54.7932 12.0678 57.2148 16.0758 59.6243 20.0958C59.6482 20.1316 59.684 20.1793 59.6959 20.227C59.5289 20.1673 59.3738 20.0838 59.2187 20.0123C59.0279 19.9288 58.8251 19.8333 58.6342 19.7498C58.515 19.6902 58.3837 19.6425 58.2645 19.5828C58.0617 19.4874 57.8589 19.4039 57.6561 19.3085C57.5368 19.2608 57.4175 19.2011 57.2983 19.1415L56.511 18.7836C56.3797 18.724 56.2485 18.6643 56.1054 18.6047L55.5328 18.3542C55.4135 18.3065 55.2942 18.2468 55.175 18.1872L54.3877 17.8293C54.2565 17.7697 54.1252 17.7101 53.9821 17.6504L53.4095 17.3999C53.2783 17.3402 53.159 17.2806 53.0278 17.2209C52.8012 17.1136 52.5745 17.0063 52.3359 16.9108C52.3717 16.8989 52.3598 16.8989 52.3598 16.8989Z" fill="#C2CCF3" />
    </svg>
  )
}

export default H20ETH
// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';

import login from './login';

import logout from './logout';
import account from './account';
import addAccounts from './addAccounts';
import checkInvitation from './checkInvitation';
import getChallenge from './Miner/getChallenge';
import postChallenge from './Miner/postChallenge';
import updateRwnewToken from './Miner/updateRwnewToken';
import getUserInfo from './Miner/getUserInfo';
import checkUser from './Miner/checkUser';
import getInviteCount from './Miner/inviteCount';
import getSaleTigerReward from './Miner/saleTigerReward';
import getMiningTigerReward from './Miner/miningTigerReward'
import getPoolRewards from './Miner/poolRewards';
import getLeaderBoards from './Miner/leaderBoards';
import getPartnerLevel from './Miner/partnerLevel'
import getMiningTigerRewardSummaries from './Miner/miningTigerRewardSummaries'
import getSaleTigerRewardSummaries from './Miner/saleTigerRewardSummaries'
import getUserLeaderBoards from './Miner/userLeaderboardLocation'

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  login,
  logout,
  account,
  addAccounts,
  checkInvitation,
  getChallenge,
  postChallenge,
  updateRwnewToken,
  getUserInfo,
  checkUser,
  getInviteCount,
  getSaleTigerReward,
  getMiningTigerReward,
  getPoolRewards,
  getLeaderBoards,
  getPartnerLevel,
  getMiningTigerRewardSummaries,
  getSaleTigerRewardSummaries,
  getUserLeaderBoards

});

export default reducers;



// material-ui
import { Box, Button, ButtonProps, Stack, styled, Typography } from '@mui/material';
import { notification, Table } from 'antd';
import type { TableProps } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TokenColorIcon from 'assets/tokens';
import MinerDetail from "../minMiner/minerDetail"
import MinerStakingAbi from 'abi/minerStaking.json'
import MinerFeerStakingAbi from 'abi/minerFeerStaking.json'
import { getEthersSigner } from 'contract/getEthersSigner';
import { config } from 'contexts/wagmiConfig';
import { ethers } from 'ethers';
import { MinerFreeStakingContract, MinerStakingContract, sepolia_rpc } from 'config';
import CliamConfirm from '../claimTranstion/confirm';
import ClaimSucceed from '../claimTranstion/succeed';
import FeerIcon from 'assets/images/nftIcons/free/free.jpg'
import { useAccount } from 'wagmi';
import { useOutletContext } from 'react-router-dom';
import { NotificationPlacement } from 'antd/es/notification/interface';
import WarningIcon from '@mui/icons-material/Warning';



const provider = new ethers.JsonRpcProvider(sepolia_rpc)




// third party

// assets

// ==============================|| LANDING - HEADER PAGE ||============================== //


interface DataType {
  index: string;
  key: number;
  name: string;
  token_id: string;
  activate_date: string;
  end_date: string;
  rewarded: string;
  status: string
  nowReward: string;
  new_token_id: number

}

type HashDataType = {
  name: string;
  id: number
  price: string;
  hashRate: string
  url: string;
  pngUrl: string
}

const ClaimButton = styled(Button)<ButtonProps>(({ theme }) => ({

  padding: '8px 30px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  borderRadius: '20px',

  "&::after": { boxShadow: 'none' }
}));

const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

function timeDifference(time1: any, time2: any) {
  const target = moment(time1)
  // let result = '';
  if (target.diff(time2, 'days') > 0) {
    return `${target.diff(time2, 'days')} Days`
    // result += `${target.diff(time2, 'days')}days`;
  } else if (target.diff(time2, 'hours') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'hours')} Hours`
    // result += `${target.diff(time2, 'hours')}hours`;
  } else if (target.diff(time2, 'minutes') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'minutes')} Minutes`
    // result += ;
  } else {
    return `${target.diff(time2, 'seconds')} Seconds`
  }


  // return result;
}

// function formatNumber(num: number) {

//   if (num % 1 !== 0) {
//     const decimalPart = num.toString().split('.')[1]

//     for (let i = 0; i < decimalPart?.length; i++) {
//       if (Number(decimalPart[i]) !== 0) {
//         // num *= 10 ** (i + 2)
//         // num = Math.floor(num)
//         // num /= 10 ** (i + 2)
//         var parts = num.toFixed(2).split(".");
//         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         return parts.join(".");
//       }
//     }
//   } else {
//     return num.toLocaleString()

//   }
// }


// 定义要覆盖默认的 table 背景元素的组件
const components = {
  table: (props: any) => {
    const { style } = props;
    const customStyle = {
      ...style,
      backgroundColor: "#fff", // 设置表格的背景颜色
      borderRadius: '10px'

    };
    return <table {...props} style={customStyle} />;
  },
  header: {
    // 自定义头部行组件
    wrapper: (props: any) => (
      <thead style={{ color: '#9b9b9b', fontSize: '13px', borderBottom: '1px solid #f0f0f0' }}>
        {props.children}
      </thead>
    ),
  },
};

type HashTotalDataType = {
  id: number
  hashRate: string
}


type TypeProps = {
  windowWidth: number
  listData: DataType[]
  hashData: HashDataType[]
  windowHeight: number
  onUpdate: () => void
  nftChange: () => void
  hashTotalData: HashTotalDataType[]
}

const ListTable = ({ windowWidth, listData, hashData, windowHeight, onUpdate, nftChange, hashTotalData }: TypeProps) => {
  const outLentData: any = useOutletContext()

  const [hash, setHash] = useState('')
  const [nowReward, setNowReward] = useState('')
  const { address } = useAccount()

  const notificonfig = {
    top: windowWidth >= 600 ? windowHeight * 0.1 : windowHeight * 0,

  }




  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement, err: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px', width: '-webkit-fill-available' }} color="#000">
                  {err}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '12px', fontWeight: 600, lineHeight: '18px', width: '160px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '600px' : '240px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,
      // duration: 0,

    });
  };

  const onClaim = async (id: number, time: number, nowReward: string, index: string) => {
    setNowReward(nowReward)
    const feeMinerContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, provider)

    await feeMinerContract.claimFeeForEachMiner().then(async (res: any) => {
      if (Number(time) >= Number(moment().unix())) {
        if (Number(id) == 6) {

          const Signer = getEthersSigner(config)

          const FeerStakingContract = new ethers.Contract(MinerFreeStakingContract, MinerFeerStakingAbi, await Signer)
          outLentData?.checkOpen(false)

          setOpenConfirm(true)

          await FeerStakingContract.claim(moment().subtract(120, 'seconds').unix(), {
            from: address,
            value: BigInt(Math.floor(Number(res)))
          }).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenConfirm(false)
              setOpenSucced(true)

            }

          }).catch((err) => {
            openNotification('top', `freeClaim --value${res} --- ${err}`)
            // console.log("错误结果", err)
            setOpenConfirm(false)
            outLentData?.checkOpen(true)
          })


        } else {
          const Signer = getEthersSigner(config)

          const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)
          outLentData?.checkOpen(false)

          setOpenConfirm(true)

          await StakingContract.claim([Number(index)], [moment().subtract(120, 'seconds').unix()], {
            from: address,
            value: BigInt(Math.floor(Number(res)))
          }).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenConfirm(false)
              setOpenSucced(true)

            }

          }).catch((err) => {
            openNotification('top', `Claim --value${res} --- ${err}`)
            setOpenConfirm(false)
            outLentData?.checkOpen(true)
          })
        }
      } else {
        if (Number(id) == 6) {
          const Signer = getEthersSigner(config)

          const FeerStakingContract = new ethers.Contract(MinerFreeStakingContract, MinerFeerStakingAbi, await Signer)
          outLentData?.checkOpen(false)

          setOpenConfirm(true)

          await FeerStakingContract.claim(time, {
            from: address,
            value: BigInt(Math.floor(Number(res)))
          }).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenConfirm(false)
              setOpenSucced(true)

            }

          }).catch((err) => {
            openNotification('top', `freeClaim --value${res} --- ${err}`)
            // console.log("错误结果", err)
            setOpenConfirm(false)
            outLentData?.checkOpen(true)
          })

        } else {
          const Signer = getEthersSigner(config)

          const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)
          outLentData?.checkOpen(false)

          setOpenConfirm(true)

          await StakingContract.claim([Number(index)], [time], {
            from: address,
            value: BigInt(Math.floor(Number(res)))
          }).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenConfirm(false)
              setOpenSucced(true)

            }

          }).catch((err) => {
            openNotification('top', `Claim --value${res} --- ${err}`)
            setOpenConfirm(false)
            outLentData?.checkOpen(true)
          })
        }
      }

    }).catch((err) => {
      openNotification('top', `ClaimFeeForEachMiner --- ${err}`)
    })



  }

  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSucced, setOpenSucced] = useState(false)


  const handleTranConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSuccedClose = () => {
    setOpenSucced(false)
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '#',
      dataIndex: 'key',
      width: '50px',
      key: 'key',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {record.key}
          </Typography>
        </>
      ),
    },
    {
      title: 'Miner',
      dataIndex: 'name',
      align: 'start',
      key: 'name',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {`${Number(record.token_id) == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${record.name} Miner`}`}
          </Typography>
        </>
      ),
    },
    {
      title: 'NFT Token ID',
      dataIndex: 'token_id',
      width: '80px',
      key: 'token_id',
      align: 'end',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {`${Number(record.token_id) == 6 ? 1 : record.token_id}`}
          </Typography>
        </>
      )

    },
    {
      title: 'Activate Date',
      key: 'activate_date',
      dataIndex: 'activate_date',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {
              `${record.activate_date == '' ? '---' : moment(Number(record.activate_date) * 1000).format('YYYY-MM-DD HH:mm:ss')}`
            }
          </Typography>
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'start',
      render: (_, record) => (
        <>
          {
            record.status == 'Mining' ? (
              <>
                <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', padding: '4px 10px', borderRadius: '20px', width: 'fit-content' }}>
                  <Stack direction="row" alignItems="center" spacing="6px">
                    <Box sx={{ width: '10px', height: '10px', backgroundColor: '#1AAE70', borderRadius: '10px' }}>

                    </Box>
                    <Typography variant="body1" sx={{ color: '#1aae70', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                      {record.status}
                    </Typography>


                  </Stack>

                </Box>
              </>
            ) : (
              <>
                <Box sx={{ backgroundColor: '#F6F6F6', padding: '4px 10px', borderRadius: '20px', width: 'fit-content' }}>

                  <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                    {record.status}
                  </Typography>
                </Box>

              </>
            )
          }
        </>
      ),
    },
    {
      title: 'Hash Rate',
      key: 'activate_date',
      width: '80px',
      dataIndex: 'activate_date',
      align: 'end',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {hashTotalData.filter((item) => item.id == Number(record.token_id))[0]?.hashRate} E
          </Typography>
        </>
      ),
    },
    {
      title: 'Mining Days',
      key: 'activate_date',
      dataIndex: 'activate_date',
      align: 'end',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {record.activate_date == '' ? '0 Days' : Number(record.end_date) >= Number(moment().unix()) ? timeDifference(moment().unix() * 1000, Number(record.activate_date) * 1000) : timeDifference(Number(record.end_date) * 1000, Number(record.activate_date) * 1000)}
          </Typography>
        </>
      ),
    },
    {
      title: 'Mined reward',
      key: 'rewarded',
      width: '150px',
      dataIndex: 'rewarded',
      align: 'end',
      render: (_, record) => (
        <>
          <Stack direction="row" alignItems="center" spacing="6px">
            <TokenColorIcon name="HAYA" size={22} />
            <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              {record.activate_date == '' ? '0.00' : Math.floor(Number(record.nowReward) * 100000) / 100000}
            </Typography>
          </Stack>

        </>
      ),
    },
    {
      title: '',
      key: 'rewarded',
      dataIndex: 'rewarded',
      align: 'end',
      fixed: 'right',
      render: (_, record) => (
        <>
          <ClaimButton sx={Number(record.nowReward) == 0 ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#FFF', '&:hover': { backgroundColor: '#19A56A', color: '#FFF' } }} onClick={() => onClaim(Number(record.token_id), Number(record.end_date), record.nowReward, record.index)}>Claim</ClaimButton>
        </>
      ),
    },
  ];

  useEffect(() => {

  }, [listData])

  useEffect(() => {
    console.log('hashData', hashData)

  }, [hashData])


  const [checkName, setCheckName] = useState('')
  const [checkId, setCheckId] = useState('')
  const [checkStatus, setCheckStatus] = useState('')
  const [checkStartTime, setCheckStartTime] = useState('')
  const [checkEndTime, setCheckEndTime] = useState('')
  const [checkNowReward, setCheckNowReward] = useState('')
  const [checkTotalReward, setCheckTotalReward] = useState('')
  const [openView, setOpenView] = useState(false)
  const [checkNum, setCheckNum] = useState('')
  const [checkIndex, setCheckIndex] = useState('')
  const [checkTokenId, setCheckTokenId] = useState(0)

  const handleViewClose = () => {
    setOpenView(false)
  }

  const OnRowClick = (record: any, event: any) => {
    if (event.target.innerText !== 'Claim') {
      setCheckName(record.name)
      setCheckTokenId(record.new_token_id)
      setCheckId(String(record.token_id))
      setCheckEndTime(record.end_date)
      setCheckStartTime(record.activate_date)
      setCheckStatus(record.status)
      setCheckNowReward(record.nowReward)
      setCheckTotalReward(record.totalReward)
      setCheckIndex(record.index)
      if (record.activate_date == '') {
        setCheckNum(record.rewarded)
      }
      setOpenView(true)

    }
  }


  return (
    <>
      {contextHolder}
      <CliamConfirm open={openConfirm} handleConfirmClose={handleTranConfirmClose} RewardNum={nowReward} windowWidth={windowWidth} />
      <ClaimSucceed open={openSucced} handleConfirmClose={handleSuccedClose} RewardNum={nowReward} windowWidth={windowWidth} hash={hash} />

      <MinerDetail token_id={checkTokenId} index={checkIndex} nftChange={nftChange} windowHeight={windowHeight} windowWidth={windowWidth} open={openView} handleConfirmClose={handleViewClose} num={checkNum} onChange={onUpdate} name={checkName} id={checkId} start_time={checkStartTime} end_time={checkEndTime} status={checkStatus} now_reward={checkNowReward} total_reward={checkTotalReward} hashRate={hashTotalData.filter((item) => item.id == Number(checkId))[0]?.hashRate} url={Number(checkId) == 6 ? FeerIcon : hashData.filter((item) => item.id == Number(checkId))[0]?.pngUrl} />
      <Box sx={{ '& .ant-table-wrapper tfoot>tr>td': { padding: '10px' }, '& .ant-table-wrapper .ant-table-tbody >tr >td': { borderTop: '0.5px solid rgba(192, 192, 192, 0.5) ', padding: '10px 10px' }, '& .ant-table-wrapper .ant-table': { borderRadius: '20px' }, '& .ant-table-wrapper .ant-table-cell': { padding: '10px' }, '& .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child': { paddingLeft: 1 }, '& .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover': { backgroundColor: '#F6F6F6', cursor: 'pointer' } }}>
        <Table columns={columns} dataSource={listData} components={components} scroll={{ x: 815 }} pagination={{ hideOnSinglePage: true }} onRow={(record) => { return { onClick: (event) => OnRowClick(record, event) } }} />
      </Box>
    </>
  );
};

export default ListTable;

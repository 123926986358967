import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"

type ChallengeType = {
  account: string;
  challenge: string
}

type DataType = {
  data: ChallengeType
  success?: boolean
  error: null | string
}

type Data = {
  challengeData: DataType
  code: number
}

// 获取邀请码
export const CheckUserData = createAsyncThunk('account/check_user', async (address: string, thunkkAPI?: any) => {
  try {
    const res = await http.get(`/check_user/${address}`, {
      "headers": {
        "Content-Type": "application/json"
      }
    })
    // console.log('获取检查用户', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)

  }
})


const initialState = {
  challengeData: {},
  code: 0
} as Data


const slice = createSlice({
  name: 'checkUser',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(CheckUserData.fulfilled, (state, action) => {
        state.challengeData = action.payload.data
        state.code = action.payload.status
      })
      .addCase(CheckUserData.rejected, (state, action) => {
        // state.challengeData = action.payload.data
        // state.code = action.payload.status
      })
  },
  reducers: {}
})


export default slice.reducer

import { Box, Button, ClickAwayListener, Container, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, Typography } from "@mui/material"

import crownIcon from 'assets/icons/crown.svg'
import { useEffect, useRef, useState } from "react"
import copyIcon from 'assets/images/icon/Copy.svg';
import copyLinkIcon from 'assets/images/icon/copyLink.svg'
import { FaRegSquareCheck } from "react-icons/fa6"
// import pictureIcon from 'assets/icons/picture.svg'
import twitterIcon from 'assets/icons/twitter.svg'
import FooterBlock from "layout/CommonLayout/FooterBlock"
import UpdatePage from "../Miner/upgrade"
import { HayaStakingContract, sepolia_rpc } from "config"
import { useAccount } from "wagmi"
// import MinerStakingAbi from 'abi/minerStaking.json'
import { ethers } from "ethers"
import levelAbi from 'abi/level.json';
import { isAuth } from "utils/token"
import { useSelector } from "store"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import PeoplesIcon from 'assets/icons/PeoplesIcon.svg'
import ListTable from "./listtable";
import CommissListTable from "./commissionTable";
import TokenColorIcon from "assets/tokens";
import moment from "moment";


const provider = new ethers.JsonRpcProvider(sepolia_rpc)


type PropsType = {
  windowWidth: number
  windowHeight: number
}

type timeType = {
  time: string
}






// function formatNumber(num: number) {

//   if (num % 1 !== 0) {
//     const decimalPart = num.toString().split('.')[1]

//     for (let i = 0; i < decimalPart?.length; i++) {
//       if (Number(decimalPart[i]) !== 0) {
//         num *= 10 ** (i + 2)
//         num = Math.floor(num)
//         num /= 10 ** (i + 2)
//         var parts = num.toString().split(".");
//         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         return parts.join(".");
//       }
//     }
//   } else {
//     return num.toLocaleString()

//   }
// }

function timeDifference(time1: any, time2: any) {
  const target = moment(time1)
  // console.log('1111111hours', time1.diff(moment().unix(), 'days'))
  // let result = '';
  if (target.diff(time2, 'days') > 0) {
    return `${target.diff(time2, 'days')}days ${target.diff(time2, 'hours') % 24}hours`
    // result += `${target.diff(time2, 'days')}days`;
  } else if (target.diff(time2, 'hours') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'hours')}hours`
    // result += `${target.diff(time2, 'hours')}hours`;
  } else if (target.diff(time2, 'minutes') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'minutes')}minutes`
    // result += ;
  } else {
    return `${target.diff(time2, 'seconds')}seconds`
  }


  // return result;
}

function transferToNumber(num: any) {
  if (num % 1 !== 0) {
    if (num == 0) {
      return num
    } else {
      const decimalPart = num.toString().split('.')[1]

      for (let i = 0; i < decimalPart?.length; i++) {
        if (Number(decimalPart[i]) !== 0) {
          // num *= 10 ** (i + 2)
          // num = Math.floor(num)
          // num /= 10 ** (i + 2)
          var parts = String(Math.floor(num * 100000) / 100000).split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".")?.replace(/,/g, "");
        }
      }
    }
  } else {
    return new Intl.NumberFormat().format(num)
  }
}






const ReferalsPage = ({ windowHeight, windowWidth }: PropsType) => {

  const [upgradeOpen, setUpgradeOpen] = useState(false)


  const { address } = useAccount()

  const [levelName, setLevelName] = useState('Agent')

  const [inviterCode, setInviterCode] = useState('')

  const { getUserInfo, getInviteCount, getMiningTigerRewardSummaries, getSaleTigerRewardSummaries, getPartnerLevel } = useSelector((state) => state)

  const { userInfoData } = getUserInfo

  const { InviteCountData } = getInviteCount
  const { MiningTigerRewardSummariesData } = getMiningTigerRewardSummaries
  const { SaleTigerRewardSummariesData } = getSaleTigerRewardSummaries

  useEffect(() => {
    // console.log('success', userInfoData)
    if (userInfoData.success) {
      setInviterCode(userInfoData.data.invite_code)
    }

  }, [userInfoData])

  useEffect(() => {
    console.log('InviteCountData', InviteCountData)

  }, [InviteCountData])



  useEffect(() => {
    // console.log('邀请码', inviterCode)

  }, [inviterCode])


  const getStakes = async () => {
    const StakesContract = new ethers.Contract(HayaStakingContract, levelAbi, provider)
    await StakesContract.stakes(address).then(async (res: any) => {

      if (Number(res[2]) == 0) {
        setLevelName('Agent')
      } else if (Number(res[2]) == 1) {
        setLevelName('Partner')
      } else if (Number(res[2]) == 2) {
        setLevelName('Global Partner')
      }
    })
  }






  useEffect(() => {

    if (address !== undefined) {
      getStakes()
    }

  }, [address, isAuth()])



  useEffect(() => {

  }, [levelName])


  const onUpgrade = () => {
    setUpgradeOpen(true)

  }

  const OnUpgradeClose = () => {
    onChange()
    setUpgradeOpen(false)
  }



  const [copy, setCopy] = useState(false)

  const onCopy = () => {
    navigator.clipboard.writeText(inviterCode)

    setCopy(true)

    setTimeout(() => {
      setCopy(false)

    }, 2000)
  }

  // const onClaimAll = async () => {
  //   const Signer = getEthersSigner(config)



  //   const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)

  //   setOpenConfirm(true)

  //   // console.log('3333333', newArr, timeArr)

  //   await StakingContract.claim(newArr, timeArr).then(async (res1) => {
  //     const res2 = await res1.wait()
  //     if (res2.blockNumber == null) {
  //     } else {
  //       setHash(String(res2.hash))
  //       setOpenConfirm(false)
  //       setOpenSucced(true)

  //     }

  //   }).catch((err) => {
  //     console.log("错误结果", err)
  //     // openNotification('top')
  //     setOpenConfirm(false)
  //   })

  // }




  const onChange = () => {
    getStakes()
  }


  const [copyInviter, setCopyInviter] = useState(false)

  const onInviterCopy = () => {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    navigator.clipboard.writeText(`${rootUrl}/${inviterCode}`)

    setCopyInviter(true)

    setTimeout(() => {
      setCopyInviter(false)

    }, 2000)

  }

  const goOnTwitter = () => {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

    // window.open(`https://x.com/haya_finance`, '_blank')
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent('$Haya: Decentralized Crypto Bank for Digital Residents. Offering $H20 (first on-chain ETF) and a robust #DeFi and')}&url=${encodeURIComponent(`${rootUrl}/${inviterCode}`)}`, '_blank')

  }



  const [value, setValue] = useState('Commission record')

  const OnCheckTitel = (text: string) => {
    setValue(text)

  }

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  const [timeValue, setTimeValue] = useState('All')

  const OnCheckTime = (text: string) => {
    setTimeValue(text)

  }

  const times: timeType[] = [
    { time: 'All time' },
    // { time: '30 Days' },
    // { time: '7 Days' },
    // { time: '24 Hours' }
  ]

  const [checkTime, setCheckTime] = useState<timeType>({
    time: 'All time'
  })

  const onChangeNework = (value: any, event?: any) => {
    setCheckTime(value)
    handleClose(event)
  }

  const { PartnerLevelData } = getPartnerLevel




  return (
    <>

      <UpdatePage open={upgradeOpen} handleClose={OnUpgradeClose} windowHeight={windowHeight} windowWidth={windowWidth} />
      {
        windowWidth >= 600 ? (
          <>
            <Container sx={{ width: '100%', '&.MuiContainer-root': { maxWidth: '100%', paddingLeft: '0px', paddingRight: '0px' } }}>

              <Box padding="60px 0" sx={{ backgroundColor: '#fff' }}>
                <Box sx={{ maxWidth: '1340px', margin: '0 auto', padding: '0px' }}>
                  <Stack alignItems="center" spacing="10px">
                    <Stack alignItems="center" direction="row" spacing="10px" width="100%">
                      <Box sx={{ backgroundImage: 'linear-gradient(to bottom, #017E68, #1BAD70)', height: '250px', borderRadius: '24px', padding: '30px 30px', width: '50%' }}>
                        <Stack alignItems="start" spacing="24px">
                          <Stack alignItems="start">
                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '32px', lineHeight: '32px', marginBottom: '10px' }}  >
                              Refer and Earn
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#F6F6F6', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', marginBottom: '6px' }}  >
                              Earn commission bonus from all your invited friends' daily $HAYA mine;
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#F6F6F6', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px' }}  >
                              Stake $HAYA to upgrade referrals level for a high commission rate.
                            </Typography>
                          </Stack>
                          <Stack direction="row" alignItems="center" spacing="10px" width="100%">


                            <Box sx={{ backgroundColor: 'transparent', border: 0, padding: 0 }} component="button" onClick={onInviterCopy}>
                              <Stack direction="row" alignItems="center" spacing="10px" justifyContent="center" width="100%" sx={{ backgroundColor: '#000', borderRadius: '20px', padding: '20px 20px' }}>
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '18px', lineHeight: '18px' }}  >
                                  {address !== undefined ? `https://haya-miner...${inviterCode}` : '----'}

                                </Typography>
                                {
                                  !copyInviter ? (
                                    <IconButton sx={{ width: '22px', height: '22px' }} onClick={onInviterCopy}>
                                      <img style={{ width: '22px', height: '22px', color: '#fff' }} src={copyLinkIcon} />
                                    </IconButton>
                                  ) : (
                                    <FaRegSquareCheck size={22} style={{ color: '#fff' }} />

                                  )
                                }



                              </Stack>
                            </Box>

                            <Box sx={{ backgroundColor: 'transparent', border: 0, padding: 0 }} component="button" onClick={onCopy}>
                              <Stack direction="row" justifyContent="center" width="100%" alignItems="center" spacing="10px" sx={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px 20px' }}>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '18px', lineHeight: '18px' }}  >
                                  {address !== undefined ? inviterCode : '----'}
                                </Typography>
                                {
                                  !copy ? (
                                    <IconButton sx={{ width: '22px', height: '22px' }} onClick={onCopy}>
                                      <img style={{ width: '22px', height: '22px', color: '#333' }} src={copyIcon} />
                                    </IconButton>
                                  ) : (
                                    <FaRegSquareCheck size={22} style={{ color: '#333' }} />

                                  )
                                }



                              </Stack>
                            </Box>
                            <IconButton onClick={goOnTwitter} sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                              <img src={twitterIcon} />

                            </IconButton>
                            {/* <IconButton sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                              <img src={pictureIcon} />

                            </IconButton> */}


                          </Stack>

                        </Stack>
                      </Box>

                      <Stack alignItems="center" spacing="10px" width="50%">
                        <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '25px 30px', width: '100%', height: '120px' }}>
                          <Stack alignItems="start" spacing="10px">
                            <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                              Haya Partner Level
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing="16px">
                              <img src={crownIcon} />

                              {
                                address !== undefined ? (
                                  <>
                                    <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '24px', lineHeight: '28px' }}  >
                                      {/* {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''} */}
                                      {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}


                                    </Typography>
                                    {
                                      PartnerLevelData?.data?.option_type == 3 ? (
                                        <>
                                          <Box sx={{ borderRadius: '20px', fontWeight: 600, padding: '6px 10px', color: '#f5b91f', backgroundColor: 'rgba(245, 185, 31, 0.2)', fontSize: '12px', lineHeight: '24px' }}>
                                            Temporary: {timeDifference(Number(PartnerLevelData?.data?.expired_timestamp) * 1000, Number(moment().unix()) * 1000)} left

                                          </Box>
                                        </>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  </>
                                ) : (
                                  <>
                                    <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '24px', lineHeight: '28px' }}  >
                                      {/* {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''} */}
                                      Agent

                                    </Typography>

                                  </>
                                )
                              }

                              <Typography component="button" onClick={onUpgrade} variant="body1" sx={{ padding: 0, cursor: 'pointer', color: '#1AAE70', backgroundColor: 'transparent', border: 'none', fontWeight: 500, fontSize: '16px', fontFamily: `'Public Sans',sans-serif` }}  >
                                {`Upgrade >`}
                              </Typography>


                            </Stack>


                          </Stack>
                        </Box>

                        <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                          <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '50%', height: '120px' }}>
                            <Stack spacing="10px" alignItems="start">
                              <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                                Tier One Friends
                              </Typography>
                              <Stack direction="row" alignItems="center" spacing="10px">
                                <img src={PeoplesIcon} />
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px' }}  >
                                  {InviteCountData?.data?.tiger_one_count}
                                </Typography>

                              </Stack>

                            </Stack>
                          </Box>
                          <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '50%', height: '120px' }}>
                            <Stack spacing="10px" alignItems="start">
                              <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                                Tier Two Friends
                              </Typography>
                              <Stack direction="row" alignItems="center" spacing="10px">
                                <img src={PeoplesIcon} />
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px' }}  >
                                  {InviteCountData?.data?.tiger_two_count}
                                </Typography>

                              </Stack>
                            </Stack>
                          </Box>


                        </Stack>






                      </Stack>


                    </Stack>

                    <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                      <Stack sx={{ backgroundColor: '#1b1b1b', padding: '20px', borderRadius: '20px' }} alignItems="start" spacing="10px" width="50%">
                        <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                          Reward of Referrals Commission
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing="12px">
                          <TokenColorIcon name="HAYA" size={48} />
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '48px', lineHeight: '46px' }}  >
                            {address == undefined || transferToNumber(Number(Number(MiningTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(MiningTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18)) == undefined ? '0' : `${transferToNumber(Number(Number(MiningTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(MiningTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18))}`} <span style={{ fontSize: '26px', color: '#6f6f6f' }}>$HAYA</span>
                          </Typography>

                        </Stack>

                      </Stack>
                      <Stack sx={{ backgroundColor: '#1b1b1b', padding: '20px', borderRadius: '20px' }} alignItems="start" spacing="10px" width="50%">
                        <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                          Reward of Node Sales
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing="12px">
                          <TokenColorIcon name="H20" size={48} />
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '48px', lineHeight: '46px' }}  >
                            {address == undefined || transferToNumber((Number(SaleTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(SaleTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18)) == undefined ? '0' : `${transferToNumber((Number(SaleTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(SaleTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18))}`} <span style={{ fontSize: '26px', color: '#6f6f6f' }}>$H20</span>
                          </Typography>

                        </Stack>

                      </Stack>


                    </Stack>

                  </Stack>

                  <Box sx={{ marginTop: '20px' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" padding="0 10px">
                      <Stack direction="row" spacing="" alignItems="center">
                        <Box sx={value == 'Commission record' ? ({ p: '8px 12px', backgroundColor: '#f6f6f6', color: '#1aae70', fontSize: '14px', fontWeight: 600, borderRadius: "20px", cursor: 'pointer', border: 0 }) : ({ p: '8px 12px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Commission record')}>
                          Commission record
                        </Box>
                        <Box sx={value == 'Node sales record' ? ({ p: '8px 12px', backgroundColor: '#f6f6f6', color: '#1aae70', fontSize: '14px', fontWeight: 600, borderRadius: "20px", cursor: 'pointer', border: 0 }) : ({ p: '8px 12px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Node sales record')}>
                          Node sales record
                        </Box>

                      </Stack>
                      <Stack direction="row" alignItems="center" spacing="10px">
                        <Stack direction="row" alignItems="center" sx={{ backgroundColor: '#f6f6f6', borderRadius: '16px', padding: '2px 2px' }}>
                          <Box sx={timeValue == 'All' ? ({ p: '8px 10px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 10px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('All')}>
                            All
                          </Box>
                          <Box sx={timeValue == 'Tier One' ? ({ p: '8px 10px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 10px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('Tier One')}>
                            Tier One
                          </Box>
                          <Box sx={timeValue == 'Tier Two' ? ({ p: '8px 10px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 10px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('Tier Two')}>
                            Tier Two
                          </Box>

                        </Stack>

                        <Box>
                          <Button
                            sx={{ backgroundColor: '#f6f6f6', fontSize: '14px', padding: '4px 10px 4px 12px', color: '#6f6f6f', fontWeight: 700, borderRadius: '100px', '&:hover': { backgroundColor: '#f6f6f6', color: '#6f6f6f', boxShadow: 'none' }, "&::after": { boxShadow: 'none' }, '&:active': { backgroundColor: '#f6f6f6', color: '#6f6f6f', border: 0, boxShadow: 'none', zIndex: 100, outline: '0px solid #fff' }, '&:focus': { backgroundColor: '#f6f6f6', boxShadow: 'none', color: '#6f6f6f', border: 0, outline: '0px solid #fff' } }}
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            endIcon={<KeyboardArrowDownIcon style={{ fontWeight: 600, fontSize: '20px' }} />}
                          >
                            {checkTime.time}

                          </Button>
                          <Popper
                            sx={{ zIndex: 100 }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom-start" ? 'left top' : 'left bottom'
                                }}
                              >
                                <Paper sx={{ borderRadius: '8px', padding: '0px', marginTop: '10px', width: '100px', zIndex: 100 }}>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open}
                                      id="composition-menu"
                                      aria-labelledby="composition-button"
                                      onKeyDown={handleListKeyDown}
                                      sx={{ padding: 0 }}
                                    >
                                      {
                                        times.map((item) => {
                                          return (
                                            <MenuItem sx={{ padding: '12px 10px 12px 12px', "&:hover": { backgroundColor: '#f6f6f6', color: '#6f6f6f' } }} onClick={(event) => onChangeNework({ time: item.time }, event)} disableRipple key={item.time}>
                                              <Stack direction="row" key={item.time} spacing="24px" sx={{ alignItems: "center", justifyContent: "start" }}>

                                                <Box sx={{ fontSize: '12px', color: '#6F6F6F', fontWeight: 600, }}>
                                                  {item.time}
                                                </Box>
                                              </Stack>
                                            </MenuItem>

                                          )
                                        })
                                      }
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>

                              </Grow>
                            )}

                          </Popper>
                        </Box>



                      </Stack>

                    </Stack>
                    <Box sx={{ marginTop: '10px' }}>
                      {
                        value == 'Commission record' ? (
                          <>
                            <CommissListTable windowWidth={windowWidth} tigerType={timeValue} />
                          </>
                        ) : (
                          <>
                            <ListTable windowWidth={windowWidth} tigerType={timeValue} />
                          </>
                        )
                      }

                    </Box>

                  </Box>
                </Box>




              </Box>
              <Box >
                <FooterBlock windowWidth={windowWidth} />
              </Box>

            </Container>
          </>
        ) : (
          <>
            <Container sx={{ width: `${windowWidth}px`, '&.MuiContainer-root': { maxWidth: `${windowWidth}px`, paddingLeft: '0px', paddingRight: '0px' } }}>

              <Box padding="10px 10px 60px 10px" sx={{ backgroundColor: '#F6F6F6' }}>
                <Box sx={{ padding: '0px' }}>
                  <Stack alignItems="center" spacing="10px">
                    <Stack spacing="10px" width="100%">
                      <Box sx={{ backgroundImage: 'linear-gradient(to bottom, #017E68, #1BAD70)', borderRadius: '10px', padding: '20px 20px', width: '100%' }}>
                        <Stack alignItems="start" spacing="24px">
                          <Stack alignItems="start">
                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '32px', lineHeight: '32px', marginBottom: '10px' }}  >
                              Refer and Earn
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#F6F6F6', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', marginBottom: '6px' }}  >
                              Earn commission bonus from all your invited friends' daily $HAYA mine;
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#F6F6F6', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px' }}  >
                              Stake $HAYA to upgrade referrals level for a high commission rate.
                            </Typography>
                          </Stack>
                          <Box sx={{ backgroundColor: 'transparent', border: 0, padding: 0 }} component="button" onClick={onInviterCopy}>
                            <Stack direction="row" alignItems="center" spacing="10px" justifyContent="start" width="100%" sx={{ backgroundColor: '#000', borderRadius: '20px', padding: '20px 20px', mb: '10px' }}>
                              <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                                {address !== undefined ? `https://haya-miner...${inviterCode}` : '----'}
                              </Typography>
                              {
                                !copyInviter ? (
                                  <IconButton sx={{ width: '22px', height: '22px' }} onClick={onInviterCopy}>
                                    <img style={{ width: '22px', height: '22px', color: '#fff' }} src={copyLinkIcon} />
                                  </IconButton>
                                ) : (
                                  <FaRegSquareCheck size={22} style={{ color: '#fff' }} />

                                )
                              }



                            </Stack>
                          </Box>

                          <Stack direction="row" alignItems="center" spacing="10px" width="100%">


                            <Box sx={{ backgroundColor: 'transparent', border: 0, padding: 0 }} component="button" onClick={onCopy}>
                              <Stack direction="row" justifyContent="center" width="100%" alignItems="center" spacing="10px" sx={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px 20px' }}>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '18px', lineHeight: '18px' }}  >
                                  {address !== undefined ? inviterCode : '----'}

                                </Typography>
                                {
                                  !copy ? (
                                    <IconButton sx={{ width: '22px', height: '22px' }} onClick={onCopy}>
                                      <img style={{ width: '22px', height: '22px', color: '#333' }} src={copyIcon} />
                                    </IconButton>
                                  ) : (
                                    <FaRegSquareCheck size={22} style={{ color: '#333' }} />

                                  )
                                }



                              </Stack>
                            </Box>
                            <IconButton onClick={goOnTwitter} sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                              <img src={twitterIcon} />

                            </IconButton>
                            {/* <IconButton sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                              <img src={pictureIcon} />

                            </IconButton> */}


                          </Stack>

                        </Stack>
                      </Box>

                      <Stack alignItems="center" spacing="10px" width="100%">
                        <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '25px 30px', width: '100%' }}>
                          <Stack alignItems="start" spacing="10px">
                            <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                              Haya Partner Level
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing="16px">
                              <img src={crownIcon} />

                              {
                                address !== undefined ? (
                                  <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '28px' }}  >
                                    {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}
                                    {/* {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''} */}
                                  </Typography>
                                ) : (
                                  <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '28px' }}  >
                                    Agent
                                  </Typography>
                                )
                              }


                              <Typography component="button" onClick={onUpgrade} variant="body1" sx={{ padding: 0, cursor: 'pointer', color: '#1AAE70', backgroundColor: 'transparent', border: 'none', fontWeight: 500, fontSize: '16px', fontFamily: `'Public Sans',sans-serif` }}  >
                                {`Upgrade >`}
                              </Typography>


                            </Stack>
                            {
                              address !== undefined ? (
                                <>
                                  {
                                    PartnerLevelData?.data?.option_type == 3 ? (
                                      <>
                                        <Box sx={{ borderRadius: '20px', fontWeight: 600, padding: '6px 10px', color: '#f5b91f', backgroundColor: 'rgba(245, 185, 31, 0.2)', fontSize: '12px', lineHeight: '24px' }}>
                                          Temporary: {timeDifference(Number(PartnerLevelData?.data?.expired_timestamp) * 1000, Number(moment().unix()) * 1000)} left

                                        </Box>
                                      </>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </>
                              ) : (
                                <>
                                </>
                              )
                            }


                          </Stack>
                        </Box>

                        <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                          <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '50%', height: '120px' }}>
                            <Stack spacing="10px" alignItems="start">
                              <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                                Tier One Friends
                              </Typography>
                              <Stack direction="row" alignItems="center" spacing="10px">
                                <img src={PeoplesIcon} />
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px' }}  >
                                  {InviteCountData?.data?.tiger_one_count}
                                </Typography>

                              </Stack>

                            </Stack>
                          </Box>
                          <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '50%', height: '120px' }}>
                            <Stack spacing="10px" alignItems="start">
                              <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                                Tier Two Friends
                              </Typography>
                              <Stack direction="row" alignItems="center" spacing="10px">
                                <img src={PeoplesIcon} />
                                <Typography variant="body1" sx={{ color: '#fff', fontWeight: 700, fontSize: '30px', lineHeight: '34px' }}  >
                                  {InviteCountData?.data?.tiger_two_count}
                                </Typography>

                              </Stack>
                            </Stack>
                          </Box>


                        </Stack>






                      </Stack>
                      <Stack sx={{ backgroundColor: '#1b1b1b', padding: '20px', borderRadius: '20px' }} alignItems="start" spacing="10px" width="100%">
                        <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                          Reward of Referrals Commission
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing="12px">
                          <TokenColorIcon name="HAYA" size={48} />
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '40px', lineHeight: '46px' }}  >
                            {address == undefined || transferToNumber(Number(Number(MiningTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(MiningTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18)) == undefined ? '0' : `${transferToNumber(Number(Number(MiningTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(MiningTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18))}`} <span style={{ fontSize: '18px', color: '#6f6f6f' }}>$HAYA</span>
                          </Typography>

                        </Stack>

                      </Stack>
                      <Stack sx={{ backgroundColor: '#1b1b1b', padding: '20px', borderRadius: '20px' }} alignItems="start" spacing="10px" width="100%">
                        <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                          Reward of Node Sales
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing="12px">
                          <TokenColorIcon name="H20" size={48} />
                          <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '40px', lineHeight: '46px' }}  >
                            {address == undefined || transferToNumber(Number(Number(SaleTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(SaleTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18)) == undefined ? '0' : `${transferToNumber(Number(Number(SaleTigerRewardSummariesData?.data?.tiger_one_rewards) + Number(SaleTigerRewardSummariesData?.data?.tiger_two_rewards)) / (10 ** 18))}`} <span style={{ fontSize: '18px', color: '#6f6f6f' }}>$H20</span>
                          </Typography>

                        </Stack>

                      </Stack>


                    </Stack>

                  </Stack>

                  <Box sx={{ marginTop: '20px' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" padding="0 10px" marginBottom="10px">
                      <Stack direction="row" spacing="" alignItems="center">
                        <Box sx={value == 'Commission record' ? ({ p: '8px 12px', backgroundColor: '#f6f6f6', color: '#1aae70', fontSize: '14px', fontWeight: 600, borderRadius: "20px", cursor: 'pointer', border: 0 }) : ({ p: '8px 12px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Commission record')}>
                          Commission record
                        </Box>
                        <Box sx={value == 'Node sales record' ? ({ p: '8px 12px', backgroundColor: '#f6f6f6', color: '#1aae70', fontSize: '14px', fontWeight: 600, borderRadius: "20px", cursor: 'pointer', border: 0 }) : ({ p: '8px 12px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Node sales record')}>
                          Node sales record
                        </Box>

                      </Stack>

                    </Stack>

                    <Stack direction="row" alignItems="center"
                      justifyContent="space-between">
                      <Stack direction="row" alignItems="center" sx={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', borderRadius: '16px', padding: '2px' }}>
                        <Box sx={timeValue == 'All' ? ({ p: '8px 15px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 15px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('All')}>
                          All
                        </Box>
                        <Box sx={timeValue == 'Tier One' ? ({ p: '8px 15px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 15px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('Tier One')}>
                          Tier One
                        </Box>
                        <Box sx={timeValue == 'Tier Two' ? ({ p: '8px 15px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 15px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('Tier Two')}>
                          Tier Two
                        </Box>

                      </Stack>

                      <Box>
                        <Button
                          sx={{ backgroundColor: '#f6f6f6', fontSize: '14px', padding: '4px 10px 4px 12px', color: '#6f6f6f', fontWeight: 700, borderRadius: '100px', '&:hover': { backgroundColor: '#f6f6f6', color: '#6f6f6f', boxShadow: 'none' }, "&::after": { boxShadow: 'none' }, '&:active': { backgroundColor: '#f6f6f6', color: '#6f6f6f', border: 0, boxShadow: 'none', zIndex: 100, outline: '0px solid #fff' }, '&:focus': { backgroundColor: '#f6f6f6', boxShadow: 'none', color: '#6f6f6f', border: 0, outline: '0px solid #fff' } }}
                          ref={anchorRef}
                          id="composition-button"
                          aria-controls={open ? 'composition-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle}
                          endIcon={<KeyboardArrowDownIcon style={{ fontWeight: 600, fontSize: '20px' }} />}
                        >
                          {checkTime.time}

                        </Button>
                        <Popper
                          sx={{ zIndex: 100 }}
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start" ? 'left top' : 'left bottom'
                              }}
                            >
                              <Paper sx={{ borderRadius: '8px', padding: '0px', marginTop: '10px', width: '100px', zIndex: 100 }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                    sx={{ padding: 0 }}
                                  >
                                    {
                                      times.map((item) => {
                                        return (
                                          <MenuItem sx={{ padding: '12px 10px 12px 12px', "&:hover": { backgroundColor: '#f6f6f6', color: '#6f6f6f' } }} onClick={(event) => onChangeNework({ time: item.time }, event)} disableRipple key={item.time}>
                                            <Stack direction="row" key={item.time} spacing="24px" sx={{ alignItems: "center", justifyContent: "start" }}>

                                              <Box sx={{ fontSize: '12px', color: '#6F6F6F', fontWeight: 600, }}>
                                                {item.time}
                                              </Box>
                                            </Stack>
                                          </MenuItem>

                                        )
                                      })
                                    }
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>

                            </Grow>
                          )}

                        </Popper>
                      </Box>



                    </Stack>
                    <Box sx={{ marginTop: '10px' }}>
                      {
                        value == 'Commission record' ? (
                          <>
                            <CommissListTable windowWidth={windowWidth} tigerType={timeValue} />
                          </>
                        ) : (
                          <>
                            <ListTable windowWidth={windowWidth} tigerType={timeValue} />
                          </>
                        )
                      }

                    </Box>

                  </Box>
                </Box>
              </Box>
              <Box sx={{}}>
                <FooterBlock windowWidth={windowWidth} />
              </Box>

            </Container>
          </>
        )
      }
    </>
  )
}

export default ReferalsPage
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken } from "utils/token";

type UserInfoType = {
  option_type: number;
  partner_level: number;
  update_timestamp: number;
  expired_timestamp: number | null
}

type DataType = {
  data: UserInfoType
  success?: boolean
  error?: null | string
}

type Data = {
  PartnerLevelData: DataType,
  status: number
}




// 获取用户信息
export const getPartnerLevelData = createAsyncThunk('account/PartnerLevel', async (_, thunkkAPI?: any) => {
  try {
    const res = await http.get('/partner_level', {
      "headers": {
        "Authorization": `Bearer ${getToken().miner_token}`,
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 PartnerLevel', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  PartnerLevelData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getPartnerLevel',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getPartnerLevelData.fulfilled, (state, action) => {
        state.PartnerLevelData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getPartnerLevelData.rejected, (state, action: any) => {
        state.status = 401
        state.PartnerLevelData.data.partner_level = 0

      })
  },
  reducers: {}
})


export default slice.reducer

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken } from "utils/token";

type UserInfoType = {
  tiger_one_rewards: string;
  tiger_two_rewards: string;
  pending_withdrawed_rewards: string;
  withdrawed_rewards_amount: string
}

type DataType = {
  data: UserInfoType
  success?: boolean
  error?: null | string
}

type Data = {
  SaleTigerRewardSummariesData: DataType,
  status: number
}




// 获取用户信息
export const getSaleTigerRewardSummariesData = createAsyncThunk('account/SaleTigerRewardSummaries', async (_, thunkkAPI?: any) => {
  try {
    const res = await http.get('/sale_tiger_reward_summaries', {
      "headers": {
        "Authorization": `Bearer ${getToken().miner_token}`,
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 SaleTigerRewardSummaries', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  SaleTigerRewardSummariesData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getSaleTigerRewardSummaries',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSaleTigerRewardSummariesData.fulfilled, (state, action) => {
        state.SaleTigerRewardSummariesData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getSaleTigerRewardSummariesData.rejected, (state, action: any) => {
        state.status = 401
        state.SaleTigerRewardSummariesData.data.tiger_one_rewards = '0'
        state.SaleTigerRewardSummariesData.data.tiger_two_rewards = '0'
      })
  },
  reducers: {}
})


export default slice.reducer

import { Box, Stack, Button, ButtonProps, Typography, Grid } from "@mui/material"
import styled from "@mui/system/styled"
import { useEffect, useState } from "react";
import GiftIcon from 'assets/icons/GiftIcon.svg'
// import { isAuth } from "utils/token";
// import { useOutletContext } from "react-router";
import FeerIcon from 'assets/images/nftIcons/free/free.jpg'
import { useOutletContext } from "react-router";
import FreeMintMiner from "../minMiner/freeMint";

const BuyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '15px 0px',
  fontSize: '22px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 600,
  flex: 1,
  lineHeight: '32px',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const BuyMinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '4px 0px',
  fontSize: '18px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 600,
  flex: 1,
  lineHeight: '32px',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

// const ClaimButton = styled(Button)<ButtonProps>(({ theme }) => ({
//   color: '#fff',
//   backgroundColor: '#1aae70',
//   padding: '20px 0px',
//   fontSize: '20px',
//   fontFamily: `'Public Sans',sans-serif`,
//   fontWeight: 600,
//   width: '100%',
//   lineHeight: '18px',
//   borderRadius: '20px',
//   '&:hover': {
//     backgroundColor: '#19A56A',
//     color: '#fff',
//   },
// }));






type DataType = {
  name: string;
  id: number;
  token_id: number;
  hashRate: string;
  url: string;
  pngUrl: string;
}

type PropsType = {
  windowWidth: number
  windowHeight: number
  onChange: () => void
  changeNFT: () => void
}





const FeerNft = ({ windowHeight, windowWidth, onChange, changeNFT }: PropsType) => {

  const NftData: DataType[] = [
    { name: 'Mini', id: 6, token_id: 1, hashRate: '10', url: FeerIcon, pngUrl: FeerIcon },
  ]



  const outLentData: any = useOutletContext()

  useEffect(() => {

  }, [outLentData?.newData])









  const [checkName, setCheckName] = useState('')
  const [checkHashRate, setCheckHashRate] = useState('')
  const [checkId, setCheckId] = useState<number>(0)
  const [checkUrl, setCheckUrl] = useState('')
  const [openMintMiner, setOpenMintMiner] = useState(false)
  const [checkStackTokenId, setCheckStackTokenId] = useState(0)
  // const outLentData: any = useOutletContext()



  const checkMiner = (name: string, id: number, hashRate: string, url: string, token_id: number) => {
    setCheckStackTokenId(token_id)
    setCheckName(name)
    setCheckId(id)
    setCheckHashRate(hashRate)
    setCheckUrl(url)
    outLentData?.checkOpen(false)
    setOpenMintMiner(true)
  }

  useEffect(() => {

  }, [checkName, checkId, checkHashRate])



  const handleMintClose = () => {
    setOpenMintMiner(false)
  }

  useEffect(() => {

  }, [openMintMiner])


  // const onCliam = () => {

  // }



  // const connectWallet = () => {
  //   outLentData?.walletConnect()

  // }







  return (
    <>

      <FreeMintMiner goNFT={changeNFT} token_id={checkStackTokenId} hashRate={checkHashRate} windowHeight={windowHeight} url={checkUrl} id={checkId} windowWidth={windowWidth} onChange={onChange} BuyName={checkName} open={openMintMiner} handleConfirmClose={handleMintClose} />
      {/* <Stack alignItems="center" direction="row" spacing="20px"> */}

      <>
        {
          windowWidth >= 600 ? (
            <>
              <Grid container rowSpacing="20px" columnSpacing="10px">

                {
                  NftData.map((item, index) => {
                    return (
                      <Grid item xs={3} key={index}>

                        <Box component="button" onClick={() => checkMiner(item.name, item.id, item.hashRate, item.pngUrl, item.token_id)} sx={{ backgroundColor: '#fff', cursor: 'pointer', borderRadius: '20px', border: 0, padding: '10px', '&:hover': { border: '3px solid #1aae70' } }}>
                          <Stack alignItems="start" spacing="10px">
                            {/* <img src={nfticon} style={{ borderRadius: '10px', width: '48px' }} /> */}
                            <img src={item.url} style={{ borderRadius: '10px', width: '100%' }} />
                            {/* <img src={defaultPicIcon} /> */}
                            <Stack alignItems="start" spacing="2px">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '14px', lineHeight: '22px' }}  >
                                HAYA Genesis Miner NFT
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                                HAYA {item.name} Miner - Gift
                              </Typography>


                            </Stack>
                            <Stack alignItems="start" spacing="6px" width="100%">
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '14px', lineHeight: '22px' }}  >
                                  Hash Rate
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}  >
                                  {item.hashRate} E
                                </Typography>

                              </Stack>
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '14px', lineHeight: '22px' }}  >
                                  Period of Validity
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}  >
                                  7 days
                                </Typography>

                              </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" width="100%">
                              <BuyButton onClick={() => checkMiner(item.name, item.id, item.hashRate, item.pngUrl, item.token_id)}>
                                <img src={GiftIcon} style={{ width: '22px', marginRight: '10px' }} /> Free Mint
                              </BuyButton>

                            </Stack>



                          </Stack>

                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </>
          ) : (
            <>
              <Grid container rowSpacing="10px" columnSpacing="10px">

                {
                  NftData.map((item, index) => {
                    return (
                      <Grid item xs={6} key={index}>

                        <Box component="button" onClick={() => checkMiner(item.name, item.id, item.hashRate, item.pngUrl, item.token_id)} sx={{ backgroundColor: '#fff', cursor: 'pointer', borderRadius: '10px', border: 0, padding: '10px', '&:hover': { border: '3px solid #1aae70' } }}>
                          <Stack alignItems="start" spacing="10px">
                            {/* <img src={nfticon} style={{ borderRadius: '10px', width: '48px' }} /> */}
                            <img src={item.url} style={{ borderRadius: '10px', width: '100%' }} />
                            {/* <img src={defaultPicIcon} /> */}
                            <Stack alignItems="start" spacing="2px">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '10px', lineHeight: '16px', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}  >
                                HAYA Genesis Miner NFT
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '24px', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}  >
                                HAYA {item.name} Miner - Gift
                              </Typography>


                            </Stack>
                            <Stack alignItems="start" spacing="6px" width="100%">
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}  >
                                  Hash Rate
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '10px', lineHeight: '16px' }}  >
                                  {item.hashRate} E
                                </Typography>

                              </Stack>
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}  >
                                  Period of Validity
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '10px', lineHeight: '16px' }}  >
                                  7 days
                                </Typography>

                              </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" width="100%">
                              <BuyMinButton onClick={() => checkMiner(item.name, item.id, item.hashRate, item.pngUrl, item.token_id)}>
                                <img src={GiftIcon} style={{ width: '22px', marginRight: '10px' }} /> Free Mint
                              </BuyMinButton>
                            </Stack>
                          </Stack>

                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </>
          )
        }
      </>
      {/* </Stack> */}
    </>
  )
}


export default FeerNft
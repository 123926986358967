import { Box, Container, Stack, Typography } from "@mui/material"

import { useEffect, useState } from "react"

import FooterBlock from "layout/CommonLayout/FooterBlock"
import LeaderListTable from "./leaderBoardList"
import { useDispatch, useSelector } from "store"
import { getPoolRewardsData } from "store/reducers/Miner/poolRewards"
import { ethers } from "ethers"
import { MinerStakingContract, sepolia_rpc } from "config"
import MinerStakingAbi from 'abi/minerStaking.json'


const provider = new ethers.JsonRpcProvider(sepolia_rpc)



type PropsType = {
  windowWidth: number
  windowHeight: number
}







function transferToNumber(num: any) {
  if (num % 1 !== 0) {
    if (num == 0) {
      return num
    } else {
      const decimalPart = num.toString().split('.')[1]

      for (let i = 0; i < decimalPart?.length; i++) {
        if (Number(decimalPart[i]) !== 0) {
          // num *= 10 ** (i + 2)
          // num = Math.floor(num)
          // num /= 10 ** (i + 2)
          var parts = String(Math.floor(num * 100000) / 100000).split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".")?.replace(/,/g, "");
        }
      }
    }
  } else {
    return new Intl.NumberFormat().format(num)
  }
}






const LeaderBoardPage = ({ windowHeight, windowWidth }: PropsType) => {

















  const [timeValue, setTimeValue] = useState('1h')

  const OnCheckTime = (text: string) => {
    setTimeValue(text)
  }

  const { MiningPoolRewardsData } = useSelector((state) => state.getPoolRewards)

  const [miningDifficulty, setMiningDifficulty] = useState('')

  const getMiningDiffilty = async () => {
    const contract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, provider)
    await contract.getOccurredOutputFactorsLength().then(async (res) => {


      if (Number(res) !== 0) {
        await contract.getAdjustRecords(Number(res) - 1, Number(res) - 1).then(async (res1) => {

          setMiningDifficulty(String(res1[0][1]))
        })
      }
    })
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPoolRewardsData())
    getMiningDiffilty()

  }, [])






  return (
    <>

      {
        windowWidth >= 600 ? (
          <>
            <Container sx={{ width: '100%', '&.MuiContainer-root': { maxWidth: '100%', paddingLeft: '0px', paddingRight: '0px' } }}>

              <Box padding="60px 36px" sx={{ backgroundColor: '#fff' }}>
                <Box sx={{ maxWidth: '1340px', margin: '0 auto', padding: '0px' }}>
                  <Box mb="60px" sx={{ boxShadow: '0 0 50px 0 rgba(25, 0, 61, 0.1)', borderRadius: '20px', width: '100%', padding: '20px 0' }}>
                    <Stack direction="row" justifyContent="space-around" alignItems="center" padding="10px 20px">
                      <Stack spacing="10px" alignItems="center">

                        <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                          Total $HAYA Mined
                        </Typography>

                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}  >
                          {transferToNumber(Number(MiningPoolRewardsData?.data?.total_rewards) / (10 ** 18))}
                        </Typography>

                      </Stack>
                      <Box sx={{ width: '1px', backgroundColor: '#9b9b9b', height: '40px' }}></Box>


                      <Stack spacing="10px" alignItems="center">

                        <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                          Current Hashrates
                          {/* Total Active Miners */}
                        </Typography>

                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}  >
                          {Number(MiningPoolRewardsData?.data?.total_current_hashrates) / (10 ** 3)}
                        </Typography>


                      </Stack>
                      <Box sx={{ width: '1px', backgroundColor: '#9b9b9b', height: '40px' }}></Box>

                      <Stack spacing="10px" alignItems="center">

                        <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                          Output Factor
                          {/* Mining Rate */}
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}  >
                          {transferToNumber(Number(miningDifficulty))}
                        </Typography>

                      </Stack>


                    </Stack>

                  </Box>

                  <Box >
                    <Stack direction="row" justifyContent="space-between" alignItems="center" padding="0 10px">
                      <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '22px', lineHeight: '30px' }}  >
                        Leaderboard
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing="10px">
                        <Stack direction="row" alignItems="center" sx={{ backgroundColor: '#f6f6f6', borderRadius: '16px', padding: '2px 2px' }}>
                          <Box sx={timeValue == '1h' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('1h')}>
                            1h
                          </Box>
                          <Box sx={timeValue == '6h' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('6h')}>
                            6h
                          </Box>
                          <Box sx={timeValue == '24h' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('24h')}>
                            24h
                          </Box>
                          <Box sx={timeValue == 'All' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('All')}>
                            All
                          </Box>

                        </Stack>




                      </Stack>

                    </Stack>
                    <Box sx={{ marginTop: '10px' }}>
                      <LeaderListTable windowWidth={windowWidth} tigerType={timeValue} />

                    </Box>

                  </Box>
                </Box>




              </Box>


            </Container>
          </>
        ) : (
          <>
            <Container sx={{ width: `${windowWidth}px`, '&.MuiContainer-root': { maxWidth: `${windowWidth}px`, paddingLeft: '0px', paddingRight: '0px' } }}>

              <Box padding="30px 10px" sx={{ backgroundColor: '#F6F6F6' }}>
                <Box sx={{ padding: '0px' }}>
                  <Box mb="60px" sx={{ boxShadow: '0 0 50px 0 rgba(25, 0, 61, 0.1)', backgroundColor: '#fff', borderRadius: '20px', width: '100%', padding: '20px 0' }}>
                    <Stack alignItems="center" padding="20px 30px">
                      <Stack spacing="16px" alignItems="center" padding="20px">

                        <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                          Total $HAYA Mined
                        </Typography>

                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}  >
                          {transferToNumber(Number(MiningPoolRewardsData?.data?.total_rewards) / (10 ** 18))}
                        </Typography>

                      </Stack>
                      <Box sx={{ width: '100%', backgroundColor: '#F6F6F6', height: '1px' }}></Box>


                      <Stack spacing="16px" alignItems="center" padding="20px">

                        <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                          Current Hashrates
                        </Typography>

                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}  >
                          {Number(MiningPoolRewardsData?.data?.total_current_hashrates) / (10 ** 3)}
                        </Typography>


                      </Stack>
                      <Box sx={{ width: '100%', backgroundColor: '#F6F6F6', height: '1px' }}></Box>

                      <Stack spacing="16px" alignItems="center" padding="20px">

                        <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                          Output Factor
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '32px', lineHeight: '40px' }}  >
                          {transferToNumber(Number(miningDifficulty))}
                        </Typography>

                      </Stack>


                    </Stack>

                  </Box>

                  <Box sx={{ marginTop: '20px' }}>
                    <Stack alignItems="start" padding="0 10px" marginBottom="10px" spacing="10px">
                      <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '22px', lineHeight: '30px' }}  >
                        Leaderboard
                      </Typography>

                    </Stack>

                    <Stack direction="row" alignItems="center" sx={{ backgroundColor: 'rgba(118, 118, 128, 0.12)', borderRadius: '16px', padding: '2px 2px', width: 'fit-content' }}>
                      <Box sx={timeValue == '1h' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('1h')}>
                        1h
                      </Box>
                      <Box sx={timeValue == '6h' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('6h')}>
                        6h
                      </Box>
                      <Box sx={timeValue == '24h' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('24h')}>
                        24h
                      </Box>
                      <Box sx={timeValue == 'All' ? ({ p: '8px 25px', backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '8px 25px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTime('All')}>
                        All
                      </Box>

                    </Stack>

                    <Box sx={{ marginTop: '10px' }}>
                      <LeaderListTable windowWidth={windowWidth} tigerType={timeValue} />

                    </Box>

                  </Box>
                </Box>
              </Box>
              <Box sx={{}}>
                <FooterBlock windowWidth={windowWidth} />
              </Box>

            </Container>
          </>
        )
      }
    </>
  )
}

export default LeaderBoardPage
const FootLogo = () => {

  return (
    <svg width="121" height="42" viewBox="0 0 121 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6540_1872)">
        <path d="M38.5159 4.82415V14.4928C38.5159 23.9785 30.7737 31.6922 21.2595 31.6922C19.5689 31.6922 17.9066 31.4443 16.3135 30.9607V26.925C16.3135 24.2345 18.5122 22.044 21.2108 22.044H21.2595C22.2959 22.044 23.2794 21.8367 24.1817 21.4587C24.6612 21.2596 25.1124 21.0157 25.535 20.7231C27.5305 19.3616 28.8433 17.0735 28.8433 14.4887V4.82415C28.8433 2.17026 31.0135 0 33.6796 0C36.3457 0 38.5159 2.16619 38.5159 4.82415Z" fill="#1AAE70" />
        <path d="M26.1533 10.4571V14.4928C26.1533 17.1832 23.9587 19.3738 21.2601 19.3738H21.2113C20.3741 19.3738 19.5734 19.5079 18.8216 19.764C18.3217 19.9265 17.8421 20.146 17.3991 20.4061C15.1476 21.7188 13.6317 24.1532 13.6317 26.9331V36.6586C13.6317 39.3125 11.4614 41.4828 8.79532 41.4828C6.12924 41.4828 3.95898 39.3166 3.95898 36.6586V26.9331C3.95898 22.5357 5.62122 18.5244 8.35233 15.4804C9.38462 14.3261 10.5714 13.3142 11.88 12.4769C14.5705 10.7416 17.7771 9.73364 21.2113 9.73364C22.902 9.73364 24.5642 9.98562 26.1533 10.4652V10.4571Z" fill="#1AAE70" />
      </g>
      <g clip-path="url(#clip1_6540_1872)">
        <path d="M50.299 10.988H46.6748V30.3429H50.299V10.988Z" fill="black" />
        <path d="M62.5905 10.988H61.059V18.8579H53.2545C52.2539 18.8579 51.4424 19.6672 51.4424 20.6655C51.4424 21.6637 52.2539 22.473 53.2545 22.473H61.059V28.2558C61.059 29.4084 61.9956 30.3429 63.1512 30.3429H64.6827V13.0751C64.6827 11.9225 63.7461 10.988 62.5905 10.988Z" fill="black" />
        <path d="M77.5883 11.0012H75.2855L66.7988 30.3342H70.7406L77.2621 15.4776C77.2621 15.4776 78.5597 18.4418 79.9953 21.7219H77.13C76.1294 21.7219 75.3179 22.5313 75.3179 23.5295C75.3179 24.5278 76.1294 25.3371 77.13 25.3371H81.5772C82.1839 26.7232 82.7345 27.9827 83.1316 28.8908C83.5149 29.7675 84.383 30.3342 85.3416 30.3342H87.7249L79.9007 12.5093C79.4987 11.593 78.5912 11.0012 77.5883 11.0012Z" fill="black" />
        <path d="M112.675 12.5093C112.273 11.593 111.365 11.0012 110.363 11.0012H108.06L99.5732 30.3342H103.515L110.036 15.4781C110.036 15.4781 111.334 18.4423 112.77 21.7224H109.904C108.904 21.7224 108.092 22.5317 108.092 23.53C108.092 24.5282 108.904 25.3375 109.904 25.3375H114.352C114.958 26.7236 115.509 27.9831 115.906 28.8912C116.289 29.768 117.157 30.3347 118.116 30.3347H120.499L112.675 12.5093Z" fill="black" />
        <path d="M88.7061 11.0012H84.7676L91.4755 26.1173L91.4691 28.1593C91.4654 29.3592 92.4396 30.3338 93.6426 30.3338H95.335V26.0891H95.33L88.7061 11.0012Z" fill="black" />
        <path d="M98.1002 11.0012L94.3974 19.4364C94.2073 19.8698 94.2101 20.3627 94.4052 20.7938L94.7474 21.5494C95.2683 22.7006 96.9104 22.6911 97.4185 21.5344L102.042 11.0012H98.1002Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_6540_1872">
          <rect width="41.4747" height="41.4747" fill="white" transform="translate(0.5)" />
        </clipPath>
        <clipPath id="clip1_6540_1872">
          <rect width="73.8249" height="19.3548" fill="white" transform="translate(46.6748 10.988)" />
        </clipPath>
      </defs>
    </svg>


  )

}

export default FootLogo
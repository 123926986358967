import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"

type UserInfoType = {
  rank: number;
  user_wallet_address: string;
  owned_mined_rewards: string;
  referral_mined_rewards: string;
  total_rewards: string;
}

type DataType = {
  data: UserInfoType[]
  success?: boolean
  error?: null | string
  _total_records: number
}

type Data = {
  LeaderBoardsData: DataType,
  status: number
}

type Params = {
  duration_hours?: number;
  size: number
  page: number

}



// 获取用户信息
export const getLeaderBoardsData = createAsyncThunk('account/LeaderBoards', async (data: Params, thunkkAPI?: any) => {
  try {
    const res = await http.get('/leaderboard', {
      params: data,
      "headers": {
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 LeaderBoards', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  LeaderBoardsData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getLeaderBoards',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getLeaderBoardsData.fulfilled, (state, action) => {
        state.LeaderBoardsData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getLeaderBoardsData.rejected, (state, action: any) => {
        state.status = 401
        state.LeaderBoardsData.data = []
        state.LeaderBoardsData._total_records = 0

      })
  },
  reducers: {}
})


export default slice.reducer

type Props = {
  size: number
}

const RNDR = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 2500 2500" xmlSpace="preserve" enableBackground="new 0 0 2500 2500">

      <g id="Layer_x0020_1">
        <g id="_1704857643392">
          <path fillRule="evenodd" clipRule="evenodd" fill="#CF1011" d="M1250,0c690,0,1250,560,1250,1250s-560,1250-1250,1250S0,1940,0,1250S560,0,1250,0z"></path>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1250,954c163,0,296,132,296,296c0,163-132,296-296,296c-163,0-296-132-296-296C954,1087,1086,954,1250,954z     "></path>
            <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1257,476c93,1,185,19,271,52s166,81,236,143l2,2l0,0c159,142,246,334,257,531c12,198-52,400-193,560l-2,2     l0,0c-142,159-334,246-531,257c-198,12-400-52-560-193c-160-142-248-335-259-533c-12-198,52-400,193-560l67,59     c-125,141-181,320-171,495c11,175,88,346,229,471s320,181,495,171c175-11,346-88,471-229l2-2l0,0c124-141,180-319,169-493     c-11-175-88-346-229-471l-2-2l0,0c-61-54-131-96-206-125c-76-29-157-45-239-46l1-90L1257,476z"></path>
            <path fill="#FFFFFF" d="M810,481c54,0,102,22,137,57s57,84,57,137c0,54-22,102-57,137s-84,57-137,57c-54,0-102-22-137-57     s-57-84-57-137c0-54,22-102,57-137S757,481,810,481z M884,602c-19-19-45-31-74-31s-55,12-74,31s-31,45-31,74s12,55,31,74     s45,31,74,31s55-12,74-31s31-45,31-74S903,621,884,602z"></path>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default RNDR
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { setToken } from "utils/token"


type ChallengeType = {
  access_token: string
}

type VerifyTypeParams = {
  account: string
  signature: string
  inviter_code?: string

}

type DataType = {
  data: ChallengeType
  error: null | string
  success: boolean

}

type Data = {
  PostChallengeData: DataType
  code: number
}

// 验证挑战码
export const postChallengeData = createAsyncThunk('account/verify_challenge', async (data: VerifyTypeParams, thunkkAPI?: any) => {

  try {
    const res = await http.post('/verify_challenge', JSON.stringify(data), { "headers": { 'Content-Type': 'application/json' } })

    const newToken = {
      miner_token: res.data.data.access_token
    }
    setToken(newToken)
    // console.log('获取 postVerifyCallenge', res)

    return res

  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }

})


const initialState = {
  PostChallengeData: {},
} as Data


const slice = createSlice({
  name: 'postChallenge',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(postChallengeData.fulfilled, (state, action) => {
        state.PostChallengeData = action.payload?.data
        // state.code = action.payload?.status
      })
      .addCase(postChallengeData.rejected, (state, action) => {


      })
  },
  reducers: {}
})


export default slice.reducer

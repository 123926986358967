
import Mock from 'mockjs'



function generateRandomString (length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const getChallengedata = Mock.mock({
  'data': {
    "data": {
      "account": 'address|',
      "challenge": generateRandomString(6)
    },
    "error": null,
    "success": true,
  }
})

export { getChallengedata }
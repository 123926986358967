// types
import { DefaultConfigProps } from 'types/config';

// ==============================|| THEME CONSTANT  ||============================== //

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const APP_DEFAULT_PATH = '/';
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 260;
// 测试
// export const arb_url = 'https://sepolia.arbiscan.io/address/' //'https://sepolia.arbiscan.io/address/'
// export const hash_url = 'https://sepolia.arbiscan.io/tx/'
// export const net_id = 421614
// export const network_Name = 'Arbitrum Sepolia'
// export const sepolia_rpc = "https://arbitrum-sepolia-rpc.publicnode.com"
// export const H30_Address = '0x9B34a7d5da57e91eA70293c8cE023261c7F3b15d';
// export const total_Address = "0xd84db6F731964b6ee52F66c7E6e86c2Eb5685894";
// export const BasicIssuanceModule = "0x0Dd18972815D8A2b611F82F57E0bA26faaA972e1";
// export const HayaStakingContract = "0x5f4823AE6170243Cc575aA7c9A2F02d9BA108dc8";
// export const MinerContract = "0x6adf2a8dE884A63EB9aEF3a392A1dE4Ec4817f38"
// export const MinerFactoryContract = "0xae3C3B061A70486899573E9c95aA41a768eE6bB2"
// export const MinerStakingContract = "0x5102Fd53Ef4F2944B9341604aEF1715544fA2c7C"
// export const MinerFreeContract = "0x1452ff0522e1c05F9a6E451d3f964745e3f16B89";
// export const MinerFreeStakingContract = "0x158fb550a30905B3e289f8292BE55ddEb29fF338";
// export const uriAddress = 'QmcEpkB11zD5GDaX2yX7fXbv2BA1d4ruQmD5RFaRFkaA3A'
// export const hashRateList = [{ id: 6, hashRate: 10000 }, { id: 0, hashRate: 10000 }, { id: 1, hashRate: 100000 }, { id: 2, hashRate: 1005000 }, { id: 3, hashRate: 10100000 }]

// 主网

export const net_id = 42161
// export const total_Address = '0xA533cB06073C08Bd78E56541279ef3247BaE1BeA'
export const arb_url = 'https://arbiscan.io/address/'
export const hash_url = 'https://arbiscan.io/tx/'
export const sepolia_rpc = 'https://arbitrum-one-rpc.publicnode.com'
export const network_Name = 'Arbitrum One'//'https://sepolia.arbiscan.io/tx/'
export const H30_Address = '0x498127B0A9fBB955084ebB5cFf1e1e53dC670731';

export const HayaStakingContract = "0xe0d186eA6594375694C1B453dBC71125E922ef7C";
export const MinerContract = "0xe7e9E00aD66a85c3D2533bC62Ad0F2c48De4E731"
export const MinerFactoryContract = "0xbB83AD2063419b28D4D99aec0C813328b6D67Ac9"
export const MinerStakingContract = "0x5A69cA7A06e71561E4AbD5939092fbd6EaF7fE9b"
export const MinerFreeContract = "0x916ae43f3172e7482d0aae4065404bd7b760f5b0";
export const MinerFreeStakingContract = "0x9762F5E54b38122901007A8714Fbab24689403E4";
export const uriAddress = 'QmcEpkB11zD5GDaX2yX7fXbv2BA1d4ruQmD5RFaRFkaA3A'
export const FreeAirdrop = "0x078e267003Ef992DFA1AF5CeC240a1Cd3B94029C";
export const newMinerFreeContract = "0x86c44a995a321004b0e8B8C027b4e5D957A1337E"
export const hashRateList = [{ id: 6, hashRate: 10000 }, { id: 0, hashRate: 10000 }, { id: 1, hashRate: 100000 }, { id: 2, hashRate: 1005000 }, { id: 3, hashRate: 10100000 }]




// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  menuOrientation: 'vertical',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;

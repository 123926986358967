type Props = {
  size: number
}

const SOLColor = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="black" />
      <g clip-path="url(#clip0_6197_5951)">
        <path d="M23.9176 20.2562L21.2763 23.0463C21.2189 23.1069 21.1494 23.1553 21.0722 23.1883C20.995 23.2213 20.9118 23.2384 20.8276 23.2383H8.30663C8.24689 23.2383 8.18845 23.2211 8.13849 23.1888C8.08853 23.1566 8.04923 23.1106 8.02541 23.0567C8.0016 23.0027 7.99431 22.943 8.00444 22.885C8.01457 22.827 8.04168 22.7732 8.08244 22.7302L10.7257 19.94C10.783 19.8796 10.8522 19.8313 10.9292 19.7983C11.0061 19.7653 11.0891 19.7482 11.1731 19.7481H23.6934C23.7531 19.7481 23.8116 19.7653 23.8615 19.7975C23.9114 19.8298 23.9507 19.8757 23.9747 19.9297C23.9984 19.9837 24.0057 20.0433 23.9956 20.1013C23.9854 20.1593 23.9583 20.2131 23.9176 20.2562ZM21.2763 14.6375C21.2189 14.5769 21.1494 14.5286 21.0722 14.4956C20.995 14.4625 20.9118 14.4455 20.8276 14.4456H8.30663C8.24689 14.4456 8.18845 14.4628 8.13849 14.495C8.08853 14.5273 8.04923 14.5732 8.02541 14.6272C8.0016 14.6812 7.99431 14.7408 8.00444 14.7988C8.01457 14.8568 8.04168 14.9106 8.08244 14.9537L10.7257 17.7439C10.783 17.8043 10.8522 17.8525 10.9292 17.8855C11.0061 17.9186 11.0891 17.9357 11.1731 17.9358H23.6934C23.7531 17.9358 23.8116 17.9186 23.8615 17.8863C23.9114 17.8541 23.9507 17.8081 23.9747 17.7542C23.9984 17.7002 24.0057 17.6405 23.9956 17.5825C23.9854 17.5246 23.9583 17.4707 23.9176 17.4277L21.2763 14.6375ZM8.30663 12.6333H20.8276C20.9118 12.6334 20.995 12.6163 21.0722 12.5833C21.1494 12.5503 21.2189 12.502 21.2763 12.4414L23.9176 9.65117C23.9583 9.60814 23.9854 9.55432 23.9956 9.49633C24.0057 9.43833 23.9984 9.37869 23.9747 9.32472C23.9507 9.27075 23.9114 9.2248 23.8615 9.19253C23.8116 9.16026 23.7531 9.14307 23.6934 9.14307H11.1731C11.0891 9.14321 11.0061 9.16032 10.9292 9.19334C10.8522 9.22636 10.783 9.27458 10.7257 9.33503L8.08312 12.1252C8.0424 12.1682 8.01531 12.222 8.00515 12.2799C7.995 12.3378 8.00223 12.3974 8.02596 12.4514C8.04969 12.5053 8.08888 12.5512 8.13874 12.5836C8.1886 12.6159 8.24695 12.6332 8.30663 12.6333Z" fill="url(#paint0_linear_6197_5951)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_6197_5951" x1="9.35059" y1="23.5742" x2="22.2592" y2="8.80085" gradientUnits="userSpaceOnUse">
          <stop offset="0.08" stop-color="#9945FF" />
          <stop offset="0.3" stop-color="#8752F3" />
          <stop offset="0.5" stop-color="#5497D5" />
          <stop offset="0.6" stop-color="#43B4CA" />
          <stop offset="0.72" stop-color="#28E0B9" />
          <stop offset="0.97" stop-color="#19FB9B" />
        </linearGradient>
        <clipPath id="clip0_6197_5951">
          <rect width="16" height="14.0952" fill="white" transform="translate(8 9.14307)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SOLColor
import { Box, Button, ButtonProps, Stack, Typography } from "@mui/material"
import styled from "@mui/system/styled"
import TokenColorIcon from "assets/tokens"
import { getEthersSigner } from "contract/getEthersSigner"
import { config } from "contexts/wagmiConfig"
import { ethers } from "ethers"
import { MinerStakingContract } from "config"
import MinerStakingAbi from 'abi/minerStaking.json'
import moment from "moment"
import { useEffect, useState } from "react"
import CliamConfirm from "../claimTranstion/confirm"
import ClaimSucceed from "../claimTranstion/succeed"
import ListTable from "./nftList"
import ActivateMiner from "../minMiner/activateMiner"
import FeerIcon from 'assets/images/nftIcons/free/free.jpg'
import { useOutletContext } from "react-router"






type PropsType = {
  windowWidth: number
  windowHeight: number
  hashRateData: DataType[]
  onChange: () => void
  listData: ListDataType[]
  onUpdate: () => void
  nftChange: () => void
  hashData: HashDataType[]
}

type HashDataType = {
  id: number
  hashRate: string
}

interface ListDataType {
  key: number;
  name: string;
  index: string;
  token_id: string;
  activate_date: string;
  end_date: string;
  rewarded: string;
  status: string
  nowReward: string;
  new_token_id: number

}

const MinerButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '18px 60px',
  fontSize: '16px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '28px',
  borderRadius: '20px',
  '&:after': {
    boxShadow: 'none'
  },
}));

const MinerMinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '10px 8px',
  fontSize: '14px',
  width: '30%',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '18px',
  borderRadius: '20px',
  '&:after': {
    boxShadow: 'none'
  },
}));

const ClaimButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '18px 60px',
  fontSize: '16px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '28px',
  borderRadius: '20px',
  '&:after': {
    boxShadow: 'none'
  },
  display: 'none'
}));

const ClaimMinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '10px 8px',
  fontSize: '14px',
  width: '30%',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '18px',
  borderRadius: '20px',
  '&:after': {
    boxShadow: 'none'
  },
  display: 'none'
}));

const StakeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '18px 60px',
  fontSize: '16px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '28px',
  borderRadius: '20px',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1aae70',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

const StakeMinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '10px 8px',
  fontSize: '14px',
  width: '30%',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '18px',
  borderRadius: '20px',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1aae70',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

// function transferToNumber(num: any) {

//   if (num % 1 !== 0) {
//     const decimalPart = num.toString().split('.')[1]

//     for (let i = 0; i < decimalPart?.length; i++) {
//       if (Number(decimalPart[i]) !== 0) {
//         // num *= 10 ** (i + 2)
//         // num = Math.floor(num)
//         // num /= 10 ** (i + 2)
//         var parts = num.toFixed(2).split(".");
//         parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         return parts.join(".");
//       }
//     }
//   } else {
//     return num.toLocaleString()

//   }
// }



type DataType = {
  name: string;
  id: number
  price: string;
  hashRate: string
  url: string;
  pngUrl: string
}



const MyNFT = ({ windowHeight, windowWidth, hashRateData, onChange, listData, onUpdate, nftChange, hashData }: PropsType) => {



  const [hash, setHash] = useState('')
  const [newArr, setNewArr] = useState<number[]>([])
  const [timeArr, setTimeArr] = useState<any[]>([])

  const outLentData: any = useOutletContext()

  useEffect(() => {

  }, [outLentData?.rewardData])

  useEffect(() => {

  }, [outLentData?.hashUserRandData])



  useEffect(() => {
    const minngIdArr = outLentData?.newData.filter((item: any) => Number(item.miningNum) !== 0)
    const expiredIdArr = outLentData?.newData.filter((item: any) => Number(item.expired_num) !== 0)
    let mingArr: number[] = []
    let timesArr: any[] = []

    for (let j = 0; j < minngIdArr?.length; j++) {
      for (let i = 0; i < minngIdArr[j]?.mining_detail?.length; i++) {
        if (Number(minngIdArr[j].mining_detail[i].end_time) >= Number(moment().unix())) {
          mingArr.push(minngIdArr[j].id)
          timesArr.push(moment().subtract(60, 'seconds').unix())

        } else {
          mingArr.push(minngIdArr[j].id)
          timesArr.push(moment(minngIdArr[j].mining_detail[i].end_time))
        }
      }
    }

    for (let j = 0; j < expiredIdArr?.length; j++) {
      for (let i = 0; i < expiredIdArr[j]?.expired_detail?.length; i++) {
        if (Number(expiredIdArr[j].expired_detail[i].end_time) >= Number(moment().unix())) {
          mingArr.push(expiredIdArr[j].id)
          timesArr.push(moment().subtract(60, 'seconds').unix())

        } else {
          mingArr.push(expiredIdArr[j].id)
          timesArr.push(moment(expiredIdArr[j].expired_detail[i].end_time))
        }
      }
    }

    setNewArr(mingArr)
    setTimeArr(timesArr)

  }, [outLentData?.newData])

  useEffect(() => {

  }, [listData])


  useEffect(() => {

  }, [hashRateData])

  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSucced, setOpenSucced] = useState(false)




  const onClaimAll = async () => {
    const Signer = getEthersSigner(config)


    const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)
    outLentData?.checkOpen(false)

    setOpenConfirm(true)
    // handleConfirmClose()

    await StakingContract.claim(newArr, timeArr).then(async (res1) => {
      const res2 = await res1.wait()
      if (res2.blockNumber == null) {
      } else {
        setHash(String(res2.hash))
        setOpenConfirm(false)
        setOpenSucced(true)

      }

    }).catch((err) => {
      // openNotification('top')
      // handleConfirmClose()
      setOpenConfirm(false)
      outLentData?.checkOpen(true)
    })


  }


  const onCliamConfirmClose = () => {
    setOpenConfirm(false)
  }


  const onCliamSucceedClose = () => {
    setOpenSucced(false)
    onChange()
  }


  useEffect(() => {

  }, [openConfirm])


  useEffect(() => {

  }, [openSucced])

  const [openStack, setOpenStack] = useState(false)

  const handleStackClose = () => {
    setOpenStack(false)
  }


  const [checkName, setCheckName] = useState('')
  const [checkId, setCheckId] = useState(0)
  const [checkNum, setCheckNum] = useState('')
  const [checkTokenId, setCheckTokenId] = useState(0)

  useEffect(() => {

  }, [hash])



  const onStake = (num: string, name: string, id: number, token_id: number) => {

    setCheckId(id)
    setCheckName(name)
    setCheckTokenId(token_id)
    setCheckNum(num)
    outLentData?.checkOpen(false)
    setOpenStack(true)
  }

  const onGetMiner = () => {
    nftChange()

  }


  return (
    <>
      <ActivateMiner token_id={checkTokenId} url={Number(checkId) == 6 ? FeerIcon : hashRateData.filter((i) => i.id == Number(checkId))[0]?.pngUrl} windowHeight={windowHeight} windowWidth={windowWidth} NFTId={checkId} NFTName={checkName} NFTNum={checkNum} onChange={onChange} open={openStack} handleConfirmClose={handleStackClose} />
      <CliamConfirm windowWidth={windowWidth} handleConfirmClose={onCliamConfirmClose} open={openConfirm} RewardNum={String(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0))} />
      <ClaimSucceed windowWidth={windowWidth} handleConfirmClose={onCliamSucceedClose} open={openSucced} RewardNum={String(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0))} hash={hash} />

      {
        windowWidth >= 600 ? (
          <Box>

            {
              Number(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0)) == 0 ? (
                <>
                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '20px', padding: '20px', mb: '20px' }} display={Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.total_num), 0)) == 0 ? 'block' : 'none'}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" >
                      <Stack direction="row" alignItems="center" spacing="12px">
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '46px' }} >
                          You don't hold any HAYA miner,get a miner to start mining now!
                        </Typography>

                      </Stack>
                      <MinerButton sx={{ backgroundColor: '#1aae70', color: '#fff', '&:hover': { backgroundColor: '#19a56a', color: '#fff' } }} onClick={onGetMiner}>Get Miner</MinerButton>
                    </Stack>
                  </Box>
                </>

              ) : (
                <>
                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '20px', padding: '20px', mb: '20px' }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" spacing="12px">
                        <TokenColorIcon size={48} name="HAYA" />
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '46px' }}  >
                          Total Mining Reward: <span style={{ color: '#1aae70' }}>$HAYA {Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0) * 100000) / 100000}</span>
                        </Typography>

                      </Stack>
                      <ClaimButton sx={String(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0)) == '0' ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#fff', '&:hover': { backgroundColor: '#19a56a', color: '#fff' } }} onClick={onClaimAll}>Claim All</ClaimButton>
                    </Stack>
                  </Box>
                </>

              )
            }



            <>
              {
                outLentData?.newData.map((item: any, index: any) => {
                  return (
                    <>
                      {
                        Number(item.total_num) == 0 ? (
                          <></>
                        ) : (
                          <>
                            {
                              Number(item.num) !== 0 ? (
                                <>
                                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '20px', padding: '20px', mb: '20px' }} key={index}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                      <Stack direction="row" alignItems="center" spacing="12px">
                                        <img src={item.id == 6 ? FeerIcon : hashRateData.filter((i) => i.id == Number(item.id))[0]?.pngUrl} style={{ borderRadius: '10px', width: '48px' }} />
                                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '46px' }}  >
                                          You have <span style={{ color: '#1aae70' }}>{item.num} {`${item.id == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${item.name} Miner`}`} can be staking</span>
                                        </Typography>

                                      </Stack>
                                      <StakeButton onClick={() => onStake(item.num, item.name, item.id, item.token_id)}>Stake</StakeButton>
                                    </Stack>

                                  </Box>

                                </>
                              ) : (
                                <></>
                              )

                            }
                          </>
                        )
                      }
                    </>
                  )
                })
              }

            </>


            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '30px', mb: '10px' }}  >
              My Miners
            </Typography>
            <ListTable hashTotalData={hashData} nftChange={nftChange} onUpdate={onUpdate} windowWidth={windowWidth} listData={listData} hashData={hashRateData} windowHeight={windowHeight} />

          </Box>
        ) : (
          <Box>
            {
              Number(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0)) == 0 ? (
                <>
                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '10px', padding: '10px', mb: '10px', width: `${windowWidth}px` }} display={Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.total_num), 0)) == 0 ? 'block' : 'none'}>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" spacing="12px">
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '14px', lineHeight: '18px' }}  >
                          You don't hold any HAYA miner,get a miner to start mining now!
                        </Typography>

                      </Stack>
                      <MinerMinButton sx={{ backgroundColor: '#1aae70', color: '#fff', '&:hover': { backgroundColor: '#19a56a', color: '#fff' } }} onClick={onGetMiner}>Get Miner</MinerMinButton>
                    </Stack>
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '10px', padding: '10px', mb: '10px', width: `${windowWidth}px` }}>

                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" spacing="12px">
                        <TokenColorIcon size={32} name="HAYA" />
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '14px', lineHeight: '18px' }}  >
                          Total Mining Reward: <span style={{ color: '#1aae70' }}>$HAYA {Math.floor(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0) * 100000) / 100000}</span>
                        </Typography>

                      </Stack>
                      <ClaimMinButton sx={String(outLentData?.rewardData.reduce((sum: any, current: any) => sum + Number(current.now_RewardNum), 0)) == '0' ? { backgroundColor: '#000', color: '#6f6f6f', '&:hover': { backgroundColor: '#000', color: '#6f6f6f' } } : { backgroundColor: '#1aae70', color: '#fff', '&:hover': { backgroundColor: '#19a56a', color: '#fff' } }} onClick={onClaimAll}>Claim All</ClaimMinButton>
                    </Stack>
                  </Box>
                </>
              )
            }


            {
              Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) == 0 ? (
                <></>
              ) : (
                <>
                  {
                    outLentData?.newData.map((item: any, index: any) => {
                      return (
                        <>
                          {
                            Number(item.total_num) == 0 ? (
                              <></>
                            ) : (
                              <>
                                {
                                  Number(item.num) !== 0 ? (
                                    <>
                                      <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '10px', padding: '10px', mb: '10px' }} key={index}>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                          <Stack direction="row" alignItems="center" spacing="12px">
                                            <img src={item.id == 6 ? FeerIcon : hashRateData.filter((i) => i.id == Number(item.id))[0]?.pngUrl} style={{ borderRadius: '10px', width: '48px' }} />
                                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '14px', lineHeight: '18px' }}  >
                                              You have <span style={{ color: '#1aae70' }}>{item.num} {`${item.id == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${item.name} Miner`}`} can be staking</span>
                                            </Typography>

                                          </Stack>
                                          <StakeMinButton onClick={() => onStake(item.num, item.name, item.id, item.token_id)}>Stake</StakeMinButton>
                                        </Stack>

                                      </Box>

                                    </>
                                  ) : (
                                    <></>
                                  )

                                }
                              </>
                            )
                          }
                        </>
                      )
                    })
                  }

                </>
              )
            }


            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '30px', mb: '10px' }}  >
              My Miners
            </Typography>
            <ListTable hashTotalData={hashData} nftChange={nftChange} onUpdate={onUpdate} windowWidth={windowWidth} listData={listData} hashData={hashRateData} windowHeight={windowHeight} />

          </Box>
        )
      }
    </>
  )
}
export default MyNFT
import { Box, Button, ButtonProps, Dialog, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { MinerContract, MinerFreeContract, MinerFreeStakingContract, MinerStakingContract, network_Name, sepolia_rpc } from "config";
import upIcon from 'assets/icons/Up.svg'
import moment from "moment";
import TokenColorIcon from "assets/tokens";
import { getEthersSigner } from "contract/getEthersSigner";
import { config } from "contexts/wagmiConfig";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import CliamConfirm from "../claimTranstion/confirm";
import ClaimSucceed from "../claimTranstion/succeed";
import MinerStakingAbi from 'abi/minerStaking.json'
import minerAbi from 'abi/miner.json'
import MinerFeerStakingAbi from 'abi/minerFeerStaking.json'
import minerFeerAbi from 'abi/minFeer.json'
import { useAccount } from "wagmi";
import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import WarningIcon from '@mui/icons-material/Warning';
import StakeApprovalTokens from "./ApproveStake";
import Confirm from "./stakeTranstion/confirm";
import Succeed from "./stakeTranstion/succeed";
import StakeMiner from "./StakeMiner";
import SendMiner from "./send";



const provider = new ethers.JsonRpcProvider(sepolia_rpc)

type TypeProps = {
  windowWidth: number;
  windowHeight: number
  open: boolean;
  handleConfirmClose: () => void;
  name: string;
  id: string
  onChange: () => void
  status: string
  total_reward: string
  now_reward: string
  hashRate: string
  start_time: string
  end_time: string
  num: string;
  index: string
  token_id: number
  url: string;
  nftChange: () => void
}

const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  width: '100%',
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

function transferToNumber(num: any) {
  if (num % 1 !== 0) {
    const decimalPart = num.toString().split('.')[1]

    for (let i = 0; i < decimalPart?.length; i++) {
      if (Number(decimalPart[i]) !== 0) {
        // num *= 10 ** (i + 2)
        // num = Math.floor(num)
        // num /= 10 ** (i + 2)
        var parts = num.toFixed(2).split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    }
  } else {
    return num.toLocaleString()

  }
}

const SendButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#000',
  backgroundColor: '#F6F6F6',
  padding: '20px 60px',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#F6F6F6',
    color: '#000',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

const MintMoreButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#000',
  backgroundColor: '#F6F6F6',
  padding: '20px 60px',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#F6F6F6',
    color: '#000',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));







const MinerDetail = ({ windowHeight, windowWidth, handleConfirmClose, open, onChange, num, name, id, start_time, status, end_time, hashRate, total_reward, now_reward, url, nftChange, index, token_id }: TypeProps) => {



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));

  const { address } = useAccount()

  const [openApprove, setOpenApproval] = useState(false)
  const [openStackConfirm, setOpenStackConfirm] = useState(false)
  const [openStackSucced, setOpenStackSucced] = useState(false)



  useEffect(() => {
    console.log('open', open)

  }, [open])



  const notificonfig = {
    top: windowWidth >= 600 ? windowHeight * 0.1 : windowHeight * 0,

  }




  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement, err: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px', width: '-webkit-fill-available' }} color="#000">
                  {err}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '12px', fontWeight: 600, lineHeight: '18px', width: '160px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '600px' : '240px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,
      // duration: 0,

    });
  };



  const onStack = async () => {

    if (Number(id) == 6) {
      const FeerApprovedContract = new ethers.Contract(MinerFreeContract, minerFeerAbi, provider)

      await FeerApprovedContract.isApprovedForAll(address, MinerFreeStakingContract).then(async (res) => {
        if (!Boolean(res)) {
          handleConfirmClose()
          setOpenApproval(true)
        } else {
          const Signer = getEthersSigner(config)

          const FeerStakingContract = new ethers.Contract(MinerFreeStakingContract, MinerFeerStakingAbi, await Signer)

          setOpenStackConfirm(true)
          handleConfirmClose()

          await FeerStakingContract.mining(MinerFreeContract, token_id).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenStackConfirm(false)
              setOpenStackSucced(true)

            }

          }).catch((err) => {
            // console.log("错误结果", err)
            openNotification('top', `feermining ---- 💎 You have staked a Free Miner. You can gift this one to a friend! Don't forget, you can buy another Haya Miner! 🚀`)
            handleConfirmClose()
            setOpenStackConfirm(false)
          })

        }
      })

    } else {
      const ApprovedContract = new ethers.Contract(MinerContract, minerAbi, provider)

      await ApprovedContract.isApprovedForAll(address, MinerStakingContract).then(async (res) => {
        if (!Boolean(res)) {
          handleConfirmClose()
          setOpenApproval(true)
        } else {
          const Signer = getEthersSigner(config)

          const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)

          setOpenStackConfirm(true)
          handleConfirmClose()

          await StakingContract.mining(Number(id), 1).then(async (res1) => {
            const res2 = await res1.wait()
            if (res2.blockNumber == null) {
            } else {
              setHash(String(res2.hash))
              setOpenStackConfirm(false)
              setOpenStackSucced(true)

            }

          }).catch((err) => {
            // console.log("错误结果", err)
            openNotification('top', `Mining --- ${err}`)
            handleConfirmClose()
            setOpenStackConfirm(false)
          })

        }
      })
    }


  }

  const [hash, setHash] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSucced, setOpenSucced] = useState(false)
  const [openLevelUp, setOpenLevelUp] = useState<boolean>(false)

  const [newArr, setNewArr] = useState<number[]>([])
  const [timeArr, setTimeArr] = useState<any[]>([])




  useEffect(() => {

    if (Number(end_time) >= Number(moment().unix())) {
      setNewArr([Number(index)])
      setTimeArr([moment().subtract(120, 'seconds').unix()])

    } else {
      setNewArr([Number(index)])
      setTimeArr([moment(end_time)])
    }




  }, [id, end_time])


  const onClaim = async () => {
    const feeMinerContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, provider)

    await feeMinerContract.claimFeeForEachMiner().then(async (res: any) => {
      if (Number(id) == 6) {
        const Signer = getEthersSigner(config)

        const FeerStakingContract = new ethers.Contract(MinerFreeStakingContract, MinerFeerStakingAbi, await Signer)

        setOpenConfirm(true)
        handleConfirmClose()

        await FeerStakingContract.claim(timeArr[0], {
          from: address,
          value: BigInt(Math.floor(Number(res)))
        }).then(async (res1) => {
          const res2 = await res1.wait()
          if (res2.blockNumber == null) {
          } else {
            setHash(String(res2.hash))
            setOpenConfirm(false)
            setOpenSucced(true)

          }

        }).catch((err) => {
          openNotification('top', `freeClaim --value${res} --- ${err}`)
          handleConfirmClose()
          setOpenConfirm(false)
        })

      } else {
        const Signer = getEthersSigner(config)

        const StakingContract = new ethers.Contract(MinerStakingContract, MinerStakingAbi, await Signer)

        setOpenConfirm(true)
        handleConfirmClose()

        await StakingContract.claim(newArr, timeArr, {
          from: address,
          value: BigInt(Math.floor(Number(res)))
        }).then(async (res1) => {
          const res2 = await res1.wait()
          if (res2.blockNumber == null) {
          } else {
            setHash(String(res2.hash))
            setOpenConfirm(false)
            setOpenSucced(true)

          }

        }).catch((err) => {
          // console.log("错误结果", err)
          openNotification('top', `Claim --- ${err}`)
          handleConfirmClose()
          setOpenConfirm(false)
        })
      }
    }).catch((err) => {
      openNotification('top', `claimFeeForEachMiner --- ${err}`)

    })



  }

  const handleTranConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSuccedClose = () => {
    setOpenSucced(false)
  }

  const handleStackConfirmClose = () => {
    setOpenStackConfirm(false)
  }

  const handleStackSuccedClose = () => {
    setOpenStackSucced(false)
    setOpenLevelUp(true)
  }

  const handleApprovalClose = () => {
    setOpenApproval(false)
  }

  const handleLevelUpClose = () => {
    setOpenLevelUp(false)
  }

  const [openSend, setOpenSend] = useState(false)

  useEffect(() => {

  }, [openSend])

  const handleSendClose = () => {
    setOpenSend(false)
  }

  const onSend = () => {
    handleConfirmClose()
    setOpenSend(true)

  }

  const onMintNFT = () => {
    handleConfirmClose()
    nftChange()
  }




  return (
    <>
      {contextHolder}
      <SendMiner windowHeight={windowHeight} token_id={token_id} url={url} windowWidth={windowWidth} open={openSend} handleConfirmClose={handleSendClose} num={num} id={Number(id)} NFTName={name} onChange={onChange} />
      <StakeApprovalTokens token_id={token_id} url={url} windowHeight={windowHeight} windowWidth={windowWidth} handleApprovalClose={handleApprovalClose} open={openApprove} NFTName={name} NFTNum={num} id={Number(id)} onUpdate={onChange} />
      <Confirm windowWidth={windowWidth} open={openStackConfirm} handleConfirmClose={handleStackConfirmClose} NFTName={name} NFTNum={num} address={MinerStakingContract} />
      <Succeed url={url} windowWidth={windowWidth} open={openStackSucced} handleConfirmClose={handleStackSuccedClose} NFTName={name} NFTNum={num} address={MinerStakingContract} hash={hash} />
      <StakeMiner url={url} windowWidth={windowWidth} open={openLevelUp} handleConfirmClose={handleLevelUpClose} NFTName={name} NFTNum={num} onChange={onChange} />
      <CliamConfirm open={openConfirm} handleConfirmClose={handleTranConfirmClose} RewardNum={now_reward} windowWidth={windowWidth} />
      <ClaimSucceed open={openSucced} handleConfirmClose={handleSuccedClose} RewardNum={now_reward} windowWidth={windowWidth} hash={hash} />
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Miner Detail
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="20px" width="100%">
                    <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '0 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" spacing="10px">
                        <Box sx={{ width: '120px', height: '120px', textAlign: 'start', lineHeight: '120px' }}>
                          <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                        </Box>
                        <Stack alignItems="start" spacing="2px" justifyContent="start">
                          <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                            HAYA Genesis Miner NFT
                          </Typography>
                          <Stack direction="row" alignItems="center" spacing="10px">
                            <Typography sx={{ color: '#fff', fontSize: '22px', fontWeight: 600, lineHeight: '30px' }}>
                              {Number(id) == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${name} Miner`}
                            </Typography>
                            {
                              status == 'Mining' ? (
                                <>
                                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', padding: '4px 10px', borderRadius: '20px', width: 'fit-content' }}>
                                    <Stack direction="row" alignItems="center" spacing="6px">
                                      <Box sx={{ width: '10px', height: '10px', backgroundColor: '#1AAE70', borderRadius: '10px' }}>

                                      </Box>
                                      <Typography variant="body1" sx={{ color: '#1aae70', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                                        {status}
                                      </Typography>


                                    </Stack>

                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box sx={{ backgroundColor: '#464646', color: '#9b9b9b', fontSize: '16px', lineHeight: '20px', padding: '4px 10px', borderRadius: '20px' }}>
                                    {status}
                                  </Box>
                                </>
                              )
                            }

                          </Stack>

                        </Stack>


                      </Stack>

                    </Box>

                    <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '100%' }}>


                      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Stack alignItems="start" spacing="10px">
                          <Typography sx={{ color: '#C0C0C0', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>
                            Total Mining Reward
                          </Typography>
                          {
                            total_reward == '0' ? (
                              <>
                                <Typography sx={{ color: '#fff', fontSize: '22px', fontWeight: 600, lineHeight: '46px' }}>
                                  Unactivated
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Stack direction="row" alignItems="center" spacing="10px">
                                  <TokenColorIcon name="HAYA" size={48} />
                                  <Typography sx={{ color: '#fff', fontSize: '44px', fontWeight: 600, lineHeight: '46px' }}>
                                    {transferToNumber(Number(now_reward))}
                                  </Typography>


                                </Stack>
                              </>
                            )
                          }
                        </Stack>
                        {
                          now_reward == '0' ? (
                            <>
                              <Box sx={{ backgroundColor: '#000', color: '#6f6f6f', fontSize: '14px', lineHeight: '18px', borderRadius: '1000px', padding: '5px 35px' }}>
                                Claim
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box component="button" onClick={onClaim} sx={{ cursor: 'pointer', backgroundColor: '#1aae70', color: '#fff', fontSize: '14px', lineHeight: '18px', borderRadius: '1000px', padding: '5px 35px', border: 0 }}>
                                Claim
                              </Box>
                            </>
                          )
                        }
                      </Stack>

                    </Box>


                  </Stack>
                  <Box sx={{ backgroundColor: 'transparent', borderRadius: '20px', padding: '20px 20px 10px 20px', width: '100%' }}>
                    <Stack alignItems="center" spacing="10px" width="100%">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Level
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Period of validity
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {Number(id) == 6 ? `7 Days` : `180 Days`}

                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Hash rate
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {hashRate} E
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Activate Date
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {start_time == '' ? `Unactivated` : moment(Number(start_time) * 1000).format('YYYY/MM/DD HH:mm:ss')}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Expiration Date
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {end_time == '' ? `Unactivated` : moment(Number(end_time) * 1000).format('YYYY/MM/DD HH:mm:ss')}
                        </Typography>
                      </Stack>

                      <Box sx={{ backgroundColor: '#c0c0c0', height: '0.5px', width: '100%' }}></Box>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          NFT Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerContract?.substring(0, 6)}...{MinerContract?.substring(MinerContract.length - 4)}
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Blockchain
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {network_Name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token standard
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ERC-1155
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token ID
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {Number(id) == 6 ? `${token_id}` : `${id}`}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          MetaData
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ipfs://QmbG...g9XF
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>





                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%" spacing="10px" >
                  {
                    total_reward == '0' ? (
                      <>
                        <ViewDetailButton onClick={onStack}>Stake</ViewDetailButton>
                        <Stack direction="row" spacing="10px" alignItems="center" width="100%">
                          <SendButton onClick={onSend} sx={{ flex: 1 }}>Send</SendButton>
                          <MintMoreButton onClick={onMintNFT} >Mint More NFT</MintMoreButton>

                        </Stack>
                      </>
                    ) : (
                      <>
                        <MintMoreButton onClick={onMintNFT} sx={{ width: '100%' }}>Mint More NFT</MintMoreButton>
                      </>
                    )
                  }
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleConfirmClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0', width: `${windowWidth}px` } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                    Miner Detail
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 0px 10px 0px' }}>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="10px" width="100%">
                    <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '0 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" spacing="10px">
                        <Box sx={{ width: '120px', height: '120px', textAlign: 'start', lineHeight: '120px' }}>
                          <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                        </Box>
                        <Stack alignItems="start" spacing="2px" justifyContent="start">
                          <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                            HAYA Genesis Miner NFT
                          </Typography>
                          <Stack direction="row" alignItems="center" spacing="10px">
                            <Typography sx={{ color: '#fff', fontSize: '22px', fontWeight: 600, lineHeight: '30px' }}>
                              {Number(id) == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${name} Miner`}
                            </Typography>
                            {
                              status == 'Mining' ? (
                                <>
                                  <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', padding: '4px 10px', borderRadius: '20px', width: 'fit-content' }}>
                                    <Stack direction="row" alignItems="center" spacing="6px">
                                      <Box sx={{ width: '10px', height: '10px', backgroundColor: '#1AAE70', borderRadius: '10px' }}>

                                      </Box>
                                      <Typography variant="body1" sx={{ color: '#1aae70', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                                        {status}
                                      </Typography>


                                    </Stack>

                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box sx={{ backgroundColor: '#464646', color: '#9b9b9b', fontSize: '16px', lineHeight: '20px', padding: '4px 10px', borderRadius: '20px' }}>
                                    {status}
                                  </Box>
                                </>
                              )
                            }

                          </Stack>

                        </Stack>


                      </Stack>

                    </Box>

                    <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '12px 12px', width: '100%' }}>


                      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Stack alignItems="start" spacing="10px">
                          <Typography sx={{ color: '#C0C0C0', fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>
                            Total Mining Reward
                          </Typography>
                          {
                            total_reward == '0' ? (
                              <>
                                <Typography sx={{ color: '#fff', fontSize: '22px', fontWeight: 600, lineHeight: '46px' }}>
                                  Unactivated
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Stack direction="row" alignItems="center" spacing="10px">
                                  <TokenColorIcon name="HAYA" size={48} />
                                  <Typography sx={{ color: '#fff', fontSize: '44px', fontWeight: 600, lineHeight: '46px' }}>
                                    {transferToNumber(Number(now_reward))}
                                  </Typography>


                                </Stack>
                              </>
                            )
                          }
                        </Stack>
                        {
                          now_reward == '0' ? (
                            <>
                              <Box sx={{ backgroundColor: '#000', color: '#6f6f6f', fontSize: '14px', lineHeight: '18px', borderRadius: '1000px', padding: '5px 35px' }}>
                                Claim
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box component="button" onClick={onClaim} sx={{ cursor: 'pointer', backgroundColor: '#1aae70', color: '#fff', fontSize: '14px', lineHeight: '18px', borderRadius: '1000px', padding: '5px 35px', border: 0 }}>
                                Claim
                              </Box>
                            </>
                          )
                        }
                      </Stack>

                    </Box>


                  </Stack>
                  <Box sx={{ backgroundColor: 'transparent', borderRadius: '20px', padding: '12px 12px 10px 12px', width: '100%' }}>
                    <Stack alignItems="center" spacing="10px" width="100%">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Level
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Period of validity
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {Number(id) == 6 ? `7 Days` : `180 Days`}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Hash rate
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {hashRate} E
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Activate Date
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {start_time == '' ? `Unactivated` : moment(Number(start_time) * 1000).format('YYYY/MM/DD HH:mm:ss')}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Expiration Date
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {end_time == '' ? `Unactivated` : moment(Number(end_time) * 1000).format('YYYY/MM/DD HH:mm:ss')}
                        </Typography>
                      </Stack>

                      <Box sx={{ backgroundColor: '#c0c0c0', height: '0.5px', width: '100%' }}></Box>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          NFT Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerContract?.substring(0, 6)}...{MinerContract?.substring(MinerContract.length - 4)}
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Blockchain
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {network_Name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token standard
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ERC-1155
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token ID
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {Number(id) == 6 ? `${token_id}` : `${id}`}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          MetaData
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ipfs://QmbG...g9XF
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>





                </Box>
                <Stack width="100%" spacing="10px" >
                  {
                    total_reward == '0' ? (
                      <>
                        <ViewDetailButton onClick={onStack}>Stake</ViewDetailButton>
                        <Stack direction="row" spacing="10px" alignItems="center" width="100%">
                          <SendButton onClick={onSend} sx={{ flex: 1 }}>Send</SendButton>
                          <MintMoreButton onClick={onMintNFT} >Mint More NFT</MintMoreButton>

                        </Stack>
                      </>
                    ) : (
                      <>
                        <MintMoreButton onClick={onMintNFT} sx={{ width: '100%' }}>Mint More NFT</MintMoreButton>
                      </>
                    )
                  }
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }

    </>
  )

}
export default MinerDetail
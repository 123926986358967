import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "utils/axios";


type Parmas = {
  name: string
  password: string
}

type Product = {
  name: string
  id: string
}

type LoginData = {
  name: string
  token: string
  admin: string
  account: number
  products: Product[]
}

type Login = {
  status: number,
  data: LoginData
}

export const goLogin = createAsyncThunk('login/goLogin', async (data: Parmas) => {
  const res = await http.post<Login>('/signIn', JSON.stringify(data))



  return res.data.data
})

const initialState = {
  data: {},
} as any


const slice = createSlice({
  name: 'login',
  initialState,
  extraReducers: builder => {
    builder.addCase(goLogin.fulfilled, (state, action) => {
      console.log('action.payload', action.payload)
      return action.payload
    });
  },
  reducers: {}
})


export default slice.reducer
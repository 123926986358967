

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import BlockieAvatar from '../Miner/avater/blockieAvatat';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import moment from 'moment';
import TokenColorIcon from 'assets/tokens';
import { getSaleTigerRewardData } from 'store/reducers/Miner/saleTigerReward';



// third party

// assets

// ==============================|| LANDING - HEADER PAGE ||============================== //


function transferToNumber(num: any) {
  if (num % 1 !== 0) {
    if (num == 0) {
      return num
    } else {
      const decimalPart = num.toString().split('.')[1]

      for (let i = 0; i < decimalPart?.length; i++) {
        if (Number(decimalPart[i]) !== 0) {
          // num *= 10 ** (i + 2)
          // num = Math.floor(num)
          // num /= 10 ** (i + 2)
          var parts = String(Math.floor(num * 100000) / 100000).split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".")?.replace(/,/g, "");
        }
      }
    }
  } else {
    return new Intl.NumberFormat().format(num)
  }
}

interface DataType {
  commission_usdt_amount: string;
  commission_base_amount: string;
  tiger_level: number;
  partner_level: number;
  price: number;
  referrer_wallet_address: string;
  referee_wallet_address: string;
  miner_type: number;
  quantity: number;
  transaction_hash: string;
  block_height: number;
  block_timestamp: number
}


// 定义要覆盖默认的 table 背景元素的组件
const components = {
  table: (props: any) => {
    const { style } = props;
    const customStyle = {
      ...style,
      backgroundColor: "#fff", // 设置表格的背景颜色
      padding: '0px 20px',
      borderRadius: '20px'

    };
    return <table {...props} style={customStyle} />;
  },
  header: {
    // 自定义头部行组件
    wrapper: (props: any) => (
      <thead style={{ color: '#9b9b9b', fontSize: '13px', borderBottom: '1px solid #f0f0f0' }}>
        {props.children}
      </thead>
    ),
  },
};




type TypeProps = {
  windowWidth: number;
  tigerType: string;
}

const ListTable = ({ windowWidth, tigerType }: TypeProps) => {


  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Sales time',
      dataIndex: 'rank',
      key: 'rank',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}>
            {moment(Number(record.block_timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
          </Typography>
        </>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      align: 'start',
      key: 'address',
      render: (_, record) => (
        <>
          <Stack direction="row" alignItems="center" spacing="10px">
            {/* <img src={indexIcon} /> */}
            <BlockieAvatar style={{ width: '36px', height: '36px' }} address={record.referee_wallet_address} />
            <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              {record.referee_wallet_address.substring(0, 6)}...{record.referee_wallet_address.substring(record.referee_wallet_address.length - 4)}
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      title: 'NFT',
      dataIndex: 'total_mined',
      key: 'total_mined',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 700 }}  >
            {`${record.miner_type == 0 ? 'Mini ×' + record.quantity : record.miner_type == 1 ? 'Bronze ×' + record.quantity : record.miner_type == 2 ? 'Silver ×' + record.quantity : record.miner_type == 3 ? 'Gold ×' + record.quantity : ''}`}
          </Typography>
        </>
      )

    },
    {
      title: 'Total Price',
      key: 'referral_bonus',
      dataIndex: 'referral_bonus',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 700 }}  >
            {`${record.miner_type == 0 ? 10 * record.quantity : record.miner_type == 1 ? 100 * record.quantity : record.miner_type == 2 ? 1000 * record.quantity : record.miner_type == 3 ? 10000 * record.quantity : ''}`}
          </Typography>
        </>
      ),
    },
    {
      title: 'Inviter',
      key: 'total_reward',
      align: 'start',
      render: (_, record) => (
        <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
          {`${record.tiger_level == 1 ? 'Me' : record.referrer_wallet_address.substring(0, 6) + '...' + record.referrer_wallet_address?.substring(record.referrer_wallet_address.length - 4)}`}
        </Typography>
      ),
    },
    {
      title: 'sales reward',
      key: 'total_reward',
      align: 'end',
      render: (_, record) => (
        <>
          <Stack direction="row" alignItems="center" spacing="6px" justifyContent="end">
            <TokenColorIcon name="H20" size={22} />
            <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              {transferToNumber(Number(record.commission_base_amount) / (10 ** 18))} <span style={{ fontSize: '12px', color: '#6f6f6f' }}>$H20</span>
            </Typography>

          </Stack>
        </>
      ),
    }
  ];

  // const locale = {
  //   emptyText: 'Coming soon, stay tuned!',
  // };

  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const { SaleTigerRewardData } = useSelector(state => state.getSaleTigerReward)

  useEffect(() => {
    if (tigerType == 'All') {
      const paramsData = {
        tiger_level: 0,
        size: 20,
        page: page - 1
      }
      dispatch(getSaleTigerRewardData(paramsData))
    } else if (tigerType == 'Tier One') {
      const paramsData = {
        tiger_level: 1,
        size: 20,
        page: page - 1
      }
      dispatch(getSaleTigerRewardData(paramsData))
    } else if (tigerType == 'Tier Two') {
      const paramsData = {
        tiger_level: 2,
        size: 20,
        page: page - 1
      }
      dispatch(getSaleTigerRewardData(paramsData))
    }


  }, [tigerType])



  const pageChange = (pages: number) => {
    setPage(pages)
    if (tigerType == 'All') {
      const paramsData = {
        tiger_level: 0,
        size: 20,
        page: pages - 1
      }
      dispatch(getSaleTigerRewardData(paramsData))
    } else if (tigerType == 'Tier One') {
      const paramsData = {
        tiger_level: 1,
        size: 20,
        page: pages - 1
      }
      dispatch(getSaleTigerRewardData(paramsData))
    } else if (tigerType == 'Tier Two') {
      const paramsData = {
        tiger_level: 2,
        size: 20,
        page: pages - 1
      }
      dispatch(getSaleTigerRewardData(paramsData))
    }
    console.log('page', pages)

  }







  // scroll={{ x: 815 }}

  return (


    <Box sx={{ '& .ant-pagination .ant-pagination-item-active': { color: '#000', borderRadius: '6px', backgroundColor: '#f6f6f6', borderColor: '#fff' }, '& .ant-pagination .ant-pagination-item-active:hover': { borderColor: '#fff' }, '& .ant-pagination .ant-pagination-item-active:hover a': { color: '#000' }, '& .ant-pagination .ant-pagination-item-active a': { color: '#000' }, '& .ant-table-wrapper .ant-table-pagination.ant-pagination': { margin: '10px 30px' }, '& .ant-table-wrapper .ant-table-pagination.ant-pagination >li:first-child': { flex: 1, textAlign: 'left', fontSize: '16px', lineHeight: '32px', color: '#6f6f6f', fontWeight: 600 }, '& .ant-table-wrapper tfoot>tr>td': { padding: '10px' }, '& .ant-table-wrapper .ant-table-tbody >tr >td': { borderTop: '0.5px solid rgba(192, 192, 192, 0.5) ', padding: '10px 10px', borderBottom: '0px solid rgba(192, 192, 192, 0.5) ', fontWeight: 600, fontSize: '24px', lineHeight: '32px' }, '& .ant-table-wrapper .ant-table': { borderRadius: '20px' }, '& .ant-table-wrapper .ant-table-cell': { padding: '10px' }, '& .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child': { paddingLeft: 1 }, '& .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover': { backgroundColor: '#F6F6F6', cursor: 'pointer' } }}>
      {
        windowWidth >= 600 ? (
          <Table columns={columns} dataSource={SaleTigerRewardData?.data} pagination={{ showSizeChanger: false, hideOnSinglePage: true, pageSize: 20, onChange: (page: number) => pageChange(page), current: page, showTotal: (total) => `Show: ${total} Records`, total: SaleTigerRewardData?._total_records }} components={components} />
        ) : (
          <Table columns={columns} dataSource={SaleTigerRewardData?.data} scroll={{ x: 815 }} pagination={{ showSizeChanger: false, hideOnSinglePage: true, pageSize: 20, onChange: (page: number) => pageChange(page), current: page, showTotal: (total) => `Show: ${total} Records`, total: SaleTigerRewardData?._total_records }} components={components} />
        )
      }
    </Box>
  );
};

export default ListTable;

type Props = {
  size: number
}

const GRT = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} version="1.1" id="GRT" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 96 96" xmlSpace="preserve" enableBackground="new 0 0 96 96">

      <circle fill="#6747ED" cx="48" cy="48" r="48" />
      <g id="Symbols">
        <g transform="translate(-88.000000, -52.000000)">
          <path id="Fill-19" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M135.3,106.2c-7.1,0-12.8-5.7-12.8-12.8c0-7.1,5.7-12.8,12.8-12.8c7.1,0,12.8,5.7,12.8,12.8
          C148.1,100.5,142.4,106.2,135.3,106.2 M135.3,74.2c10.6,0,19.2,8.6,19.2,19.2s-8.6,19.2-19.2,19.2c-10.6,0-19.2-8.6-19.2-19.2
          S124.7,74.2,135.3,74.2z M153.6,113.6c1.3,1.3,1.3,3.3,0,4.5l-12.8,12.8c-1.3,1.3-3.3,1.3-4.5,0c-1.3-1.3-1.3-3.3,0-4.5l12.8-12.8
          C150.3,112.3,152.4,112.3,153.6,113.6z M161,77.4c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2
          C159.5,74.2,161,75.6,161,77.4z"/>
        </g>
      </g>
    </svg>


  )
}

export default GRT
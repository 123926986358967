import { Box, Stack, Button, ButtonProps, Typography, Grid, IconButton } from "@mui/material"
import styled from "@mui/system/styled"
import { useEffect, useState } from "react";
import MintMiner from "../minMiner";
import GiftButton from 'assets/icons/GiftButton.svg'
import { useAccount } from "wagmi";
// import { isAuth } from "utils/token";
// import { useOutletContext } from "react-router";
import { MinerFactoryContract, sepolia_rpc } from "config"

import minerFactoryAbi from 'abi/minerFactory.json'
import { ethers } from "ethers"
import tokenAbi from 'abi/token.json'
import ActivateMiner from "../minMiner/activateMiner";
import FeerIcon from 'assets/images/nftIcons/free/free.jpg'
import { useOutletContext } from "react-router";
// import nfticon from 'assets/images/icons/nfticon.gif'

const provider = new ethers.JsonRpcProvider(sepolia_rpc)

const BuyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '15px 0px',
  fontSize: '22px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 600,
  flex: 1,
  lineHeight: '32px',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const BuyMinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '4px 0px',
  fontSize: '18px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 600,
  flex: 1,
  lineHeight: '32px',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

// const ClaimButton = styled(Button)<ButtonProps>(({ theme }) => ({
//   color: '#fff',
//   backgroundColor: '#1aae70',
//   padding: '20px 0px',
//   fontSize: '20px',
//   fontFamily: `'Public Sans',sans-serif`,
//   fontWeight: 600,
//   width: '100%',
//   lineHeight: '18px',
//   borderRadius: '20px',
//   '&:hover': {
//     backgroundColor: '#19A56A',
//     color: '#fff',
//   },
// }));

const StakeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '18px 60px',
  fontSize: '16px',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '28px',
  borderRadius: '20px',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1aae70',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));

const StakeMinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '10px 8px',
  fontSize: '14px',
  width: '30%',
  fontFamily: `'Public Sans',sans-serif`,
  fontWeight: 500,
  lineHeight: '18px',
  borderRadius: '20px',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1aae70',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));



type DataType = {
  name: string;
  id: number;
  price: string;
  hashRate: string;
  url: string;
  pngUrl: string;
}

type PropsType = {
  windowWidth: number
  windowHeight: number
  onChange: () => void
  nftData: DataType[]
}





const AllNft = ({ windowHeight, windowWidth, onChange, nftData }: PropsType) => {



  const outLentData: any = useOutletContext()

  useEffect(() => {
    console.log('1111111', outLentData?.newData)

  }, [outLentData?.newData])


  const [allowance, setAllowance] = useState('')
  const [mintAddress, setMintAddress] = useState('')
  const [balance, setBalance] = useState('')

  const getAllowance = async () => {
    const contract = new ethers.Contract(MinerFactoryContract, minerFactoryAbi, provider)



    await contract.tokenForMint().then(async (res: any) => {
      setMintAddress(String(res))

      const TokenContract = new ethers.Contract(res, tokenAbi, provider)

      await TokenContract.allowance(address, MinerFactoryContract).then(async (res: any) => {

        setAllowance(String(res))
      })

      await TokenContract.balanceOf(address).then(async (res: any) => {
        // console.log('11111111', res)

        setBalance(String(Number(res) / (10 ** 6)))
      })
    })

  }

  useEffect(() => {

  }, [allowance])

  useEffect(() => {
    getAllowance()

  }, [])




  useEffect(() => {

  }, [nftData])

  const [checkName, setCheckName] = useState('')
  const [checkPrice, setCheckPrice] = useState('')
  const [checkHashRate, setCheckHashRate] = useState('')
  const [checkId, setCheckId] = useState<number>(0)
  const [checkUrl, setCheckUrl] = useState('')
  const [openMintMiner, setOpenMintMiner] = useState(false)
  // const outLentData: any = useOutletContext()



  const checkMiner = (name: string, price: string, id: number, hashRate: string, url: string) => {
    setCheckName(name)
    setCheckPrice(price)
    setCheckId(id)
    setCheckHashRate(hashRate)
    setCheckUrl(url)
    outLentData?.checkOpen(false)
    setOpenMintMiner(true)
  }

  useEffect(() => {

  }, [checkName, checkPrice, checkId, checkHashRate])



  const handleMintClose = () => {
    setOpenMintMiner(false)
  }

  useEffect(() => {

  }, [openMintMiner])

  const goGift = () => {

  }

  // const onCliam = () => {

  // }


  const { address } = useAccount()

  // const connectWallet = () => {
  //   outLentData?.walletConnect()

  // }

  const [openStack, setOpenStack] = useState(false)

  const handleStackClose = () => {
    setOpenStack(false)
  }

  const [checkStackName, setCheckStackName] = useState('')
  const [checkStackId, setCheckStackId] = useState(0)
  const [checkNum, setCheckNum] = useState('')
  const [checkStackTokenId, setCheckStackTokenId] = useState(0)

  const onStake = (id: number, num: string, name: string, token_id: number) => {
    setCheckStackId(id)
    setCheckStackTokenId(token_id)
    setCheckStackName(name)
    setCheckNum(num)
    outLentData?.checkOpen(false)
    setOpenStack(true)



  }




  return (
    <>
      <ActivateMiner token_id={checkStackTokenId} url={Number(checkId) == 6 ? FeerIcon : nftData.filter((i) => i.id == Number(checkId))[0]?.pngUrl} windowHeight={windowHeight} windowWidth={windowWidth} NFTId={checkStackId} NFTName={checkStackName} NFTNum={checkNum} onChange={onChange} open={openStack} handleConfirmClose={handleStackClose} />

      <MintMiner balance={balance} token_id={checkStackTokenId} hashRate={checkHashRate} mintAddress={mintAddress} windowHeight={windowHeight} url={checkUrl} allowance={allowance} id={checkId} windowWidth={windowWidth} onChange={onChange} BuyMoney={checkPrice} BuyName={checkName} open={openMintMiner} handleConfirmClose={handleMintClose} />
      {/* <Stack alignItems="center" direction="row" spacing="20px"> */}

      <>
        {
          windowWidth >= 600 ? (
            <>
              {
                Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) !== 0 ? (
                  <></>
                ) : (
                  <>
                    {
                      outLentData?.newData.map((item: any, index: any) => {
                        return (
                          <>
                            {
                              Number(item.total_num) == 0 ? (
                                <></>
                              ) : (
                                <>
                                  {
                                    Number(item.num) !== 0 ? (
                                      <>
                                        <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '20px', padding: '20px', mb: '20px' }} key={index}>
                                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Stack direction="row" alignItems="center" spacing="12px">
                                              {/* <img src={nfticon} style={{ borderRadius: '10px', width: '48px' }} /> */}
                                              <img src={item.id == 6 ? FeerIcon : nftData.filter((i) => i.id == Number(item.id))[0]?.pngUrl} style={{ borderRadius: '10px', width: '48px' }} />
                                              <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '46px' }}  >
                                                You have <span style={{ color: '#1aae70' }}>{item.num} {`${item.id == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${item.name} Miner`}`} can be staking</span>
                                              </Typography>

                                            </Stack>
                                            <StakeButton onClick={() => onStake(item.id, item.num, item.name, item.token_id)}>Stake</StakeButton>
                                          </Stack>

                                        </Box>

                                      </>
                                    ) : (
                                      <></>
                                    )

                                  }
                                </>
                              )
                            }
                          </>
                        )
                      })
                    }

                  </>
                )
              }
              <Grid container rowSpacing="20px" columnSpacing="10px">

                {
                  nftData.map((item, index) => {
                    return (
                      <Grid item xs={3} key={index}>

                        <Box component="button" onClick={() => checkMiner(item.name, item.price, item.id, item.hashRate, item.pngUrl)} sx={{ backgroundColor: '#fff', cursor: 'pointer', borderRadius: '20px', border: 0, padding: '10px', '&:hover': { border: '3px solid #1aae70' } }}>
                          <Stack alignItems="start" spacing="10px">
                            {/* <img src={nfticon} style={{ borderRadius: '10px', width: '48px' }} /> */}
                            <img src={item.url} style={{ borderRadius: '10px', width: '100%' }} />
                            {/* <img src={defaultPicIcon} /> */}
                            <Stack alignItems="start" spacing="2px">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '14px', lineHeight: '22px' }}  >
                                HAYA Genesis Miner NFT
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '22px', lineHeight: '30px' }}  >
                                HAYA {item.name} Miner
                              </Typography>


                            </Stack>
                            <Stack alignItems="start" spacing="6px" width="100%">
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '14px', lineHeight: '22px' }}  >
                                  Hash Rate
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}  >
                                  {item.hashRate} E
                                </Typography>

                              </Stack>
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '14px', lineHeight: '22px' }}  >
                                  Period of Validity
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '14px', lineHeight: '22px' }}  >
                                  180 days
                                </Typography>

                              </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                              <IconButton onClick={goGift} sx={{ width: 'auto', height: 'auto', padding: 0, borderRadius: '50%' }}>
                                <img src={GiftButton} style={{ width: '60px' }} />
                              </IconButton>
                              <BuyButton onClick={() => checkMiner(item.name, item.price, item.id, item.hashRate, item.pngUrl)}>${item.price}</BuyButton>
                              {/* {
                                address !== undefined && isAuth() ? (
                                  <>
                                    
                                  </>
                                ) : (
                                  <>
                                    <BuyButton onClick={connectWallet}>${item.price}</BuyButton>
                                  </>
                                )


                              } */}

                            </Stack>



                          </Stack>

                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </>
          ) : (
            <>
              {
                Number(outLentData?.newData.reduce((sum: any, current: any) => sum + Number(current.miningNum), 0)) !== 0 ? (
                  <></>
                ) : (
                  <>
                    {
                      outLentData?.newData.map((item: any, index: any) => {
                        return (
                          <>
                            {
                              Number(item.total_num) == 0 ? (
                                <></>
                              ) : (
                                <>
                                  {
                                    Number(item.num) !== 0 ? (
                                      <>
                                        <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.2)', borderRadius: '10px', padding: '10px', mb: '10px' }} key={index}>
                                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Stack direction="row" alignItems="center" spacing="12px">
                                              {/* <img src={nfticon} style={{ borderRadius: '10px', width: '48px' }} /> */}
                                              <img src={item.id == 6 ? FeerIcon : nftData.filter((i) => i.id == Number(item.id))[0]?.pngUrl} style={{ borderRadius: '10px', width: '48px' }} />
                                              <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '14px', lineHeight: '18px' }}  >
                                                You have <span style={{ color: '#1aae70' }}>{item.num} {`${item.id == 6 ? `HAYA Mini Miner - Gift` : `HAYA ${item.name} Miner`}`} can be staking</span>
                                              </Typography>

                                            </Stack>
                                            <StakeMinButton onClick={() => onStake(item.id, item.num, item.name, item.token_id)}>Stake</StakeMinButton>
                                          </Stack>

                                        </Box>

                                      </>
                                    ) : (
                                      <></>
                                    )

                                  }
                                </>
                              )
                            }
                          </>
                        )
                      })
                    }

                  </>
                )
              }
              <Grid container rowSpacing="10px" columnSpacing="10px">

                {
                  nftData.map((item, index) => {
                    return (
                      <Grid item xs={6} key={index}>

                        <Box component="button" onClick={() => checkMiner(item.name, item.price, item.id, item.hashRate, item.pngUrl)} sx={{ backgroundColor: '#fff', cursor: 'pointer', borderRadius: '10px', border: 0, padding: '10px', '&:hover': { border: '3px solid #1aae70' } }}>
                          <Stack alignItems="start" spacing="10px">
                            {/* <img src={nfticon} style={{ borderRadius: '10px', width: '48px' }} /> */}
                            <img src={item.url} style={{ borderRadius: '10px', width: '100%' }} />
                            {/* <img src={defaultPicIcon} /> */}
                            <Stack alignItems="start" spacing="2px">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}  >
                                HAYA Genesis Miner NFT
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}  >
                                HAYA {item.name} Miner
                              </Typography>


                            </Stack>
                            <Stack alignItems="start" spacing="6px" width="100%">
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}  >
                                  Hash Rate
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '10px', lineHeight: '16px' }}  >
                                  {item.hashRate} E
                                </Typography>

                              </Stack>
                              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '10px', lineHeight: '16px' }}  >
                                  Period of Validity
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontSize: '10px', lineHeight: '16px' }}  >
                                  180 days
                                </Typography>

                              </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                              <IconButton onClick={goGift} sx={{ width: 'auto', height: 'auto', padding: 0, borderRadius: '50%' }}>
                                <img src={GiftButton} style={{ width: '40px' }} />
                              </IconButton>
                              <BuyMinButton onClick={() => checkMiner(item.name, item.price, item.id, item.hashRate, item.pngUrl)}>${item.price}</BuyMinButton>
                              {/* {
                                address !== undefined && isAuth() ? (
                                  <>
                                    
                                  </>
                                ) : (
                                  <>
                                    <BuyMinButton onClick={connectWallet}>${item.price}</BuyMinButton>
                                  </>
                                )


                              } */}

                            </Stack>



                          </Stack>

                        </Box>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </>
          )
        }
      </>
      {/* </Stack> */}
    </>
  )
}


export default AllNft
type Props = {
  size: number
}

const KAS = ({ size }: Props) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={`${size}`} height={`${size}`} viewBox="0 0 250.000000 250.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M1135 2339 c-232 -30 -432 -128 -600 -294 -343 -338 -432 -855 -220
-1280 170 -343 491 -561 889 -607 158 -18 365 13 520 79 180 75 378 274 490
493 27 52 53 97 57 100 12 8 44 104 66 196 14 62 18 116 17 249 -1 149 -4 182
-27 266 -82 308 -249 523 -520 669 -43 23 -80 40 -83 37 -3 -3 34 -31 83 -62
137 -89 274 -231 358 -370 29 -46 4 -17 -47 55 -159 227 -439 406 -710 454
-119 21 -193 25 -273 15z m477 -759 c26 -173 48 -331 48 -350 0 -48 -91 -663
-100 -673 -8 -9 -229 21 -242 32 -4 3 8 109 27 235 19 126 33 231 31 233 -2 2
-115 -82 -250 -187 -136 -105 -253 -193 -260 -196 -12 -5 -156 173 -156 194 0
5 101 87 225 183 124 95 222 175 218 179 -4 4 -104 81 -222 171 -117 90 -215
169 -218 176 -5 12 133 198 148 198 5 0 121 -87 259 -193 139 -107 253 -192
255 -190 2 2 -11 107 -30 233 -18 126 -31 232 -29 235 6 6 213 38 234 36 11
-1 25 -72 62 -316z"/>
        <path d="M325 1933 c-49 -52 -137 -193 -172 -276 -51 -120 -63 -189 -63 -372
0 -191 18 -289 79 -447 73 -185 236 -400 386 -507 32 -23 8 5 -72 85 -69 68
-140 151 -168 194 -200 308 -250 688 -134 1016 38 108 67 167 129 262 27 39
47 72 44 72 -2 0 -15 -12 -29 -27z"/>
        <path d="M147 1287 c-20 -49 8 -277 48 -402 55 -167 151 -320 279 -443 115
-111 258 -202 388 -248 64 -22 42 0 -34 36 -380 178 -636 556 -667 982 -3 57
-9 86 -14 75z"/>
        <path d="M620 256 c0 -7 62 -41 128 -69 34 -14 62 -23 62 -18 0 5 -21 16 -47
26 -27 10 -69 29 -95 42 -27 13 -48 21 -48 19z"/>
        <path d="M728 170 c36 -29 168 -77 279 -99 167 -34 400 -23 525 26 64 24 68
34 7 17 -81 -21 -290 -37 -409 -31 -116 7 -262 40 -354 81 -69 31 -79 32 -48
6z"/>
        <path d="M865 150 c11 -5 27 -9 35 -9 9 0 8 4 -5 9 -11 5 -27 9 -35 9 -9 0 -8
-4 5 -9z"/>
        <path d="M928 133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
      </g>
    </svg>



  )
}

export default KAS
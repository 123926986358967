

// material-ui
import { Box, Stack, Typography } from '@mui/material';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import h20Icon from 'assets/icons/H20.svg'



// third party

// assets

// ==============================|| LANDING - HEADER PAGE ||============================== //


interface DataType {
  key: number;
  address: string;
  join_time: string;
  status: string;
  inviter: string;
  commission_reward: string;
}


// 定义要覆盖默认的 table 背景元素的组件
const components = {
  table: (props: any) => {
    const { style } = props;
    const customStyle = {
      ...style,
      backgroundColor: "#fff", // 设置表格的背景颜色
      padding: '0px 20px',
      borderRadius: '20px'

    };
    return <table {...props} style={customStyle} />;
  },
  header: {
    // 自定义头部行组件
    wrapper: (props: any) => (
      <thead style={{ color: '#9b9b9b', fontSize: '13px', lineHeight: '20px', fontWeight: 400 }}>
        {props.children}
      </thead>
    ),
  },
};
const columns: TableProps<DataType>['columns'] = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
    align: 'start',
    render: (_, record) => (
      <>
        <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '15px', lineHeight: '20px' }}  >
          {record.key}
        </Typography>
      </>
    ),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    align: 'start',
    key: 'address',
    render: (_, record) => (
      <>
        <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '15px', lineHeight: '20px' }}  >
          {record.address?.substring(0, 6)}...{record.address?.substring(record.address.length - 6)}
        </Typography>
      </>
    ),
  },
  {
    title: 'Join time',
    dataIndex: 'join_time',
    key: 'join_time',
    align: 'start',
    render: (_, record) => (
      <>
        <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '15px', lineHeight: '20px' }}  >
          {`${record.join_time}`}
        </Typography>
      </>
    )

  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    align: 'start',

    render: (_, record) => (
      <>
        {
          record.status == 'Mining' ? (
            <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', padding: '4px 10px', borderRadius: '20px', width: 'fit-content' }}>
              <Stack direction="row" alignItems="center" spacing="6px">
                <Box sx={{ width: '10px', height: '10px', backgroundColor: '#1AAE70', borderRadius: '10px' }}>

                </Box>
                <Typography variant="body1" sx={{ color: '#1aae70', fontWeight: 500, fontSize: '15px', lineHeight: '20px' }}  >
                  {record.status}
                </Typography>


              </Stack>

            </Box>
          ) : (
            <Box sx={{ backgroundColor: '#F6F6F6', padding: '4px 10px', borderRadius: '20px', width: 'fit-content' }}>

              <Typography variant="body1" sx={{ color: '#6f6f6f', fontWeight: 500, fontSize: '15px', lineHeight: '20px' }}  >
                {record.status}
              </Typography>
            </Box>
          )
        }
      </>
    ),
  },
  {
    title: 'Inviter',
    key: 'inviter',
    align: 'start',
    render: (_, record) => (
      <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '15px', lineHeight: '20px' }}  >
        {record.inviter}
      </Typography>
    ),
  },
  {
    title: 'Commission reward',
    key: 'commission_reward',
    align: 'start',
    render: (_, record) => (
      <>
        <Stack direction="row" alignItems="center" spacing="10px">
          <img src={h20Icon} />
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '15px', lineHeight: '20px' }}  >
            {record.commission_reward}
          </Typography>
        </Stack>
      </>
    ),
  }

];

const data: DataType[] = [
  {
    key: 1,
    address: '0x0000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Mining',
    inviter: 'Me',
    commission_reward: '000.00'
  },
  {
    key: 2,
    address: '0x1000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Mining',
    inviter: '0x0000...0000',
    commission_reward: '000.00'
  },
  {
    key: 3,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Mining',
    inviter: '0x0000...0000',
    commission_reward: '000.00'
  },
  {
    key: 4,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Mining',
    inviter: 'Me',
    commission_reward: '000.00'
  },
  {
    key: 5,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Mining',
    inviter: 'Me',
    commission_reward: '000.00'
  },
  {
    key: 6,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Unactivated',
    inviter: 'Me',
    commission_reward: '000.00'
  },
  {
    key: 7,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Unactivated',
    inviter: '0x0000...0000',
    commission_reward: '000.00'
  },
  {
    key: 8,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Unactivated',
    inviter: '0x0000...0000',
    commission_reward: '000.00'
  },
  {
    key: 9,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Unactivated',
    inviter: '0x0000...0000',
    commission_reward: '000.00'
  },
  {
    key: 10,
    address: '0x2000000000000000000000000000000000000000',
    join_time: '2024-5-01 23:05:40',
    status: 'Unactivated',
    inviter: '0x0000...0000',
    commission_reward: '000.00'
  },
];

type TypeProps = {
  windowWidth: number
}

const ListTable = ({ windowWidth }: TypeProps) => {

  return (


    <Box sx={{ '& .ant-table-wrapper .ant-table-tbody >tr >td': { borderTop: '0.5px solid rgba(192, 192, 192, 0.5) ', padding: '8px 8px', borderBottom: 0 }, '& .ant-table-wrapper .ant-table': { borderRadius: '20px' }, '& .ant-table-wrapper .ant-table-cell': { padding: '10px 10px' }, '& .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child': { paddingLeft: 1 }, '& .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover': { backgroundColor: '#F6F6F6' } }}>
      <Table columns={columns} dataSource={data} components={components} scroll={{ x: 815 }} />
    </Box>
  );
};

export default ListTable;



type Props = {
  size: number
}

const HAYColor = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="22" height="22" rx="11" fill="#1AAE70" />
      <path d="M16.0002 6.29965V9.12313C16.0002 11.8764 13.7596 14.1158 11.0075 14.1158C10.5184 14.1158 10.0371 14.0437 9.57617 13.9039V12.733C9.57617 11.9519 10.2118 11.3163 10.9928 11.3163H11.0075C11.3073 11.3163 11.5924 11.2566 11.8527 11.1461C11.9914 11.0886 12.1221 11.0176 12.2438 10.9331C12.8208 10.5375 13.2006 9.87372 13.2006 9.12313V6.29965C13.2006 5.52878 13.8284 4.8999 14.6004 4.8999C15.3724 4.8999 16.0002 5.52878 16.0002 6.29965Z" fill="white" />
      <path d="M12.4229 7.95211V9.12308C12.4229 9.9041 11.7883 10.5397 11.0073 10.5397H10.9927C10.7504 10.5397 10.5182 10.5792 10.3007 10.6524C10.1564 10.6998 10.0178 10.7629 9.88933 10.8384C9.23791 11.2193 8.7995 11.926 8.7995 12.7329V15.6984C8.7995 16.4693 8.17176 17.0981 7.39975 17.0981C6.62775 17.0981 6 16.4693 6 15.6984V12.7329C6 11.456 6.48124 10.2918 7.27127 9.40822C7.56993 9.07349 7.91367 8.77934 8.29235 8.53591C9.07111 8.03213 9.99865 7.74023 10.9927 7.74023C11.4818 7.74023 11.963 7.81349 12.4229 7.95211Z" fill="white" />
    </svg>
  )
}

export default HAYColor

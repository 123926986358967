import { lazy } from 'react';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import Miner from 'pages/Miner';
import Referrals from 'pages/Referrals';
import Leaderboard from 'pages/Leaderboard';
// const Miner = Loadable(lazy(() => import('pages/Miner')));;
// const Referrals = Loadable(lazy(() => import('pages/Referrals')));;

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const Mine = Loadable(lazy(() => import('pages/mine')))

// render - sample page
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <CommonLayout layout='landing' />,
  children: [
    {
      path: '/',
      element: <Mine />
    },
    {
      path: '/:invalid_code',
      element: <Mine />
    },
    {
      path: '/Miner',
      element: <Miner />
    },
    {
      path: '/Referrals',
      element: <Referrals />
    },
    {
      path: '/Leaderboard',
      element: <Leaderboard />
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '403',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;



type Props = {
  size: number
}

const MVI = ({ size }: Props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} enable-background="new 0 0 50 50" xmlSpace="preserve">  <image id="image0" width={`${size}`} height={`${size}`} x="0" y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAK
qElEQVRo3sWZTYwc13GAv6rX88P1kstfKRIUMTEPlA2aMWAZukTJIRAvEpAgvioXyzFyMxDkYiSH
WPAPfIkRINAhEU8WEgSIlThREEWUiTiSLTGmFZFew6RlSvzdJZfL3Z2dnZme7n5VOfTMzuzuzO6S
XCoPeJiZ7tev6quqrlfvjbAD7aWLN/el2LFMeaoQnsyVo4XySAxMFUqtUIhKt1AahTIbVS4Wytmo
nLHANLB4vzrIvT74Dz+/VV2JdryLPZcLz+SBJwplb1Q0KhQqRIUYoAdC0ftedrFCWSqUC4X6qai8
lquc31OQfSwg//L+repyEX+7i38pV05E5UAUJ6qUCodS6bIPro2BIVfIg5Mrd3LljULkZVPePpjd
HVC4m8H/9M7MsXZu38jN/9LhSZCJ0hoCeO9zrXn612T41ob7AsIE8BkT/sBEjjQTLk1G5nbUI2++
e6u+nBXPd92/Wgif7FvcQmn1qGAKUXfEM3QVMpUPo/CtqLzyWEp63x459cObh9pp/GYR7c8NDg1b
eWBpGbLufXumf3mfiZxw4VCjwk+mCtr3DHL6zdnDaTe+FI3n3aUiaxT5WGASRz5vwtGlCu9MFTTu
GuS/X5893E3j35r5s6VsGSj78cKII0+48OmlCm+NgxkJ8s5/zD7UTYuXYuTZYUUeFMza50fC4MgR
F35zqcLpqYLWliDn/vNWvb1SfMOiP9+fbdhqOwnjgArsCUpFhBzHe8PGwBxFmOjBFMN6J+tB2kv5
H3m0F0QQDYD5Wg2GRJXfh68x4reMuA+Osz8Enp7azZFdVSLwXtrhB2mLlvk4OQLyggjnrtb5u8eH
ctkaj7z3j9ePFZn9DeYPici6+N1ZzwQRThzYw+f3TPAJVSZVOVKt0TXjoyzfLMwSF/m0wOnFCnNT
xTqQX/37XDVt5F/36L83CIMHA+PAVBL4nYN7qAcdCjeoIJzrpGsCYQTMfkTqAV5fqBCniqHQat/O
nibjC7omAsrZvI88NLvcR5g5oAa4s765O4k5QYanGSnnC1Hk74EfXK33PHL51VvVbqN4EfMnV0UO
yd74so7ywvY9I0BuzlQ14ZGJ6uq46M6Pl1tc6ZahtVlqBuoiVAT+TZCYAKTz2XHJ/Zm13tjomdVg
3IEE4Dhn5prk7vzGZI0C52crKe+vdEhkY2oeJUfgmSAcBz9bhlbXn9PIQZRN2s7CJAjdPPKjm8uc
qSiFOi0cU0jGW3S9nINReA44Gy6/fGNf7NhfYP74GjMM5e8HGWbgFO5EZxBOQ89sWQFARZ1/Trxt
xzT6p0Zb8MF7ZihMxsvZ3DOfMuFYIpk9pQV7B2H1/wezUe62YPZG4alEc/+cR9fxQrYBo+VvdxAX
JIC4I86gK0gor6n3yhPfERgV4XOJFBxV38piIx53EHWSiiAVJdSVUFNCLaA1QSuKBIEAJmBAgZO5
k5mTRqNtRseM1J3MSmnhHmBEOJpo5NFV728DxgENwuShKrsfrTFxoEZ9d0KlroRE0CCIyJj0WRrA
caJBHo20MJpZ5HY753qry2wnJzO7KxgRHk00+tTmD2yc4OHje3j4t/agybZ2yuuFIggaoBICE9XA
/okKh/fW+axNMj3f4kezy2C9Sli2ESXKVKKRmuPoGOUdmHioQp4ZabNAEbKFnHQhp76vglbuHmZU
K8xppAWNdoFaCTxVCeyqBm50861gaklZ80AcA+PuVD4ROPT0Pub/d5mV2S5LF1o0rqbUDlbYdbBK
bX9CdXdCZVcgVMsQk1CGl0ivVuslg2hOjE5WOGkeaXUjS52c+XbBnTSnZUalIjwyWeOzD09yodFh
tp1tGiUCJGJ0xakHGQ3jAvFOQf1ghV9/9hDt2S7NaymtW13SRkHrdkbEsQSoCFQVqQpUFBLwIOUc
AgWQ4+RevvQ5TqEOQQhVZd9EwpE9VR7dW+PXdldx4H9uNkkczNY7YQjG6SZq3sCpG+WSsAFGIS4V
ZPM5uw7XmXy87JY7eTuStyLdVkHWiWRdI8+NIjrRnYgTpdwOuQoEQRLQihKqSrWm1OqBXf1eDVTC
QPbtds5KGgnWL/5HJwCBRqLGDM7DUKbIUTAxNdIrKbsO1wd8FaE2lVCbSpiktmH+MqOvK9M3yWaj
2s3lLnlmBB2UM2NgZlSNixodtXKPIBGCgfSvxbKnlzpYatvToFe9israfhcQhTkziykSndDTLZgQ
zEkMQk/PYJBEv6jq/FQN2wwmOBQ3M7rXu9vX5D7bwkrOQiMvlbZNYSwYP1VBzqixVFp+PAwdp/2z
Fdz8vpXcTrs61ybPYgkQN4VZCsYZ1USmxflFH2AcTHDoftAhnxl9SF6sRLqL+aBG3KJ1WgXtlWLk
veVWzsxcZzV0toD5RTCmFVhU583+uzAWxhxfjrTea/aq3SGIVmTm9XmuvjpH80pnS4jmYsb5t+c5
9+N5Ws18w/0rN9qkrUhw0J7y42AS8zcT80UFEOQ1Mea3glGDdLpN9/Law/H25ZT0SkqxWHDnTIPY
GZ8ULDrXfr5MayGjtZRz+8Za8MWljOvX26Xyq3LHwsyryWtqUhbgYULPK5yS6GwK40AzsvLDBtaK
pWSH9HKK5E4A0pmMlY/Ge2V5rkvjekpigkZo3O5isfRwkRuXPmiStcu1Q6P0FB8Lc0qc8+K9nUQh
ZAon1WhsByb7VYfmfy3huRObBfm1dDCmcJoXWlgx6qgHFi61sNRQLxVrLeR0VgrcnI8+aDI/01kT
UpvANEL0kyF6FqIPzrVkQt/Spn1PzL8YNyki+4tm590m1oxYEOJ8TpBeGaGQ3ujSvZmx67G1C2W3
kdO8lhIMLALBKVqRD8810Jpwc6aDeGmsbZybfc+Ft/obt1WQvCJZJfAdCn43RI5sBaO5k55rYYGy
nA+DMbFtrPyytQGk8VGHohnLlXro8G3hWhtTgaQ81N5s29wrQC+BfAfK/xlXQ2sVZipMi8i3xUlX
15BxYWal7uoMKoChMe0POmQLg4xUtCKNX7YHYTL82fOCxv58g6piOKQ0CiGShsi31Xx6TXpeH8dS
ke8KnBTDt4QZVc70VuJioaB5bmU1IhoXWmRz2VDqXAuzGv8jYHQVxj1EOSnOd/vX+n3D3wrZbk2r
ub9I9MeI/P7IinjEOzNqTPP9FcJkQGrKwk+W0bjdIx7GnM7wrwgvhkhK758H7SWRZNR02f4wV70T
v4L5LiIn7hXG2pGF04tY6O31dQuOzWHeAL4CPufeK6yHQMYekmYHwhUR+bLA9+8nzLRwNIdRJdAg
FDeGmQ7CzNX8+2p8WSNXVuWv68lmtskOhivV+fjHuF/HeCFA/V7DjO0cImz0TOpwksDXML89nID7
31XK78kWs5EdDLer8/HPcH8f46sBPnnPMHd3CPgh+LcceQVI0bUryV2D9GDS6nx8GfxdjD9V+EPD
px4QTAPhVYe/AqbdfVVzH/r07bzsY2Cozsdp8D8R5xU1vmTiJxQ5sEMwdxzeEHhZRd4Gz/p3h0fe
l0fWwWTqftqEt8U4Dv6ciDyj8ASwFwbnyFvAGLCEcMGjnxLktQDngay/6R+2/I55ZB0Map6hnDXj
rCh/jXNMjKcEnlTxoyCPAFMGNQUM7wrSEGFW4KKInxWRM2JMq7AIjnm5GPZZ7wbk/wCUdxgcH722
3QAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wMy0xNFQwMzozMzoxMCswMDowMKNJRc0AAAAldEVY
dGRhdGU6bW9kaWZ5ADIwMjQtMDMtMTRUMDM6MzM6MTArMDA6MDDSFP1xAAAAAElFTkSuQmCC" />
    </svg>


  )
}

export default MVI
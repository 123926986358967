type Props = {
  size: number
}

const DesIcon = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.474609" width="32" height="32" rx="10" fill="#C0C0C0" />
      <path d="M21.9559 11.4881C23.7483 14.1032 24.6335 17.053 24.3026 20.4489C24.3012 20.4632 24.2937 20.4764 24.2818 20.4851C22.9244 21.4741 21.6093 22.0744 20.3127 22.4724C20.3026 22.4755 20.2918 22.4753 20.2818 22.4719C20.2718 22.4686 20.2631 22.4622 20.2569 22.4537C19.9574 22.0401 19.6852 21.6041 19.4467 21.1462C19.433 21.1192 19.4455 21.0867 19.4737 21.0761C19.906 20.9145 20.317 20.7207 20.7124 20.4914C20.7436 20.4733 20.7456 20.429 20.7168 20.4077C20.6329 20.3459 20.5498 20.2809 20.4702 20.216C20.4553 20.204 20.4353 20.2016 20.4184 20.2097C17.8515 21.3859 15.0396 21.3859 12.4423 20.2097C12.4254 20.2022 12.4054 20.2048 12.3909 20.2166C12.3115 20.2815 12.2282 20.3459 12.1451 20.4077C12.1163 20.429 12.1187 20.4733 12.1501 20.4914C12.5454 20.7164 12.9565 20.9145 13.3882 21.0769C13.4162 21.0875 13.4295 21.1192 13.4156 21.1462C13.1823 21.6047 12.9101 22.0407 12.605 22.4543C12.5917 22.471 12.5698 22.4787 12.5492 22.4724C11.2587 22.0744 9.9436 21.4741 8.58624 20.4851C8.57493 20.4764 8.5668 20.4627 8.56561 20.4483C8.28906 17.5109 8.85267 14.5367 10.9101 11.4875C10.9151 11.4794 10.9226 11.4731 10.9314 11.4694C11.9437 11.0083 13.0283 10.6692 14.1619 10.4754C14.1825 10.4723 14.2032 10.4817 14.2139 10.4999C14.3539 10.7459 14.514 11.0615 14.6224 11.3194C15.8172 11.1383 17.0308 11.1383 18.2507 11.3194C18.359 11.067 18.5135 10.7459 18.653 10.4999C18.6579 10.4909 18.6656 10.4837 18.675 10.4793C18.6843 10.4749 18.6948 10.4736 18.705 10.4754C19.8391 10.6697 20.9237 11.0089 21.9353 11.4694C21.9442 11.4731 21.9516 11.4794 21.9559 11.4881ZM15.2298 17.0731C15.2423 16.2047 14.6043 15.4862 13.8034 15.4862C13.0091 15.4862 12.3772 16.1984 12.3772 17.0731C12.3772 17.9475 13.0216 18.6598 13.8034 18.6598C14.598 18.6598 15.2298 17.9475 15.2298 17.0731ZM20.5033 17.0731C20.5158 16.2047 19.8778 15.4862 19.0771 15.4862C18.2826 15.4862 17.6507 16.1984 17.6507 17.0731C17.6507 17.9475 18.2951 18.6598 19.0771 18.6598C19.8778 18.6598 20.5033 17.9475 20.5033 17.0731Z" fill="white" />
    </svg>



  )
}

export default DesIcon
import { Box, Button, ButtonProps, Dialog, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import upIcon from 'assets/icons/Up.svg'
import { FreeAirdrop, MinerFreeContract, network_Name } from "config";
import infoIcon from 'assets/icons/Info.svg'
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import freeClaimAbi from 'abi/freeAirdrop.json'
import { useAccount } from "wagmi";
import { NotificationPlacement } from 'antd/es/notification/interface';
import WarningIcon from '@mui/icons-material/Warning';
import { isAuth } from "utils/token";
import Confirm from "./FreeTransactionStatus/confirm";
import { notification } from "antd";
import Succeed from "./FreeTransactionStatus/succeed";
import FreeMintSucceed from "./freeMintSucced";
import { getEthersSigner } from "contract/getEthersSigner";
import { config } from "contexts/wagmiConfig";
import { useOutletContext } from "react-router-dom";
// import ConnectWallet from "layout/CommonLayout/components/connectWallet/walletConnect";



type TypeProps = {
  windowWidth: number;
  windowHeight: number
  open: boolean;
  handleConfirmClose: () => void;
  BuyName: string;
  onChange: () => void
  id: number
  hashRate: string
  url: string
  token_id: number
  goNFT: () => void
}

const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  width: '100%',
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
}));

const SelectButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  padding: '20px 40px',
  borderRadius: '20px',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
  width: '100%',
  color: '#fff',
  border: 0,
  backgroundColor: '#9b9b9b',
  "&.Mui-disabled": {
    zIndex: 100,
    color: '#fff',

  },
  '&:hover': {
    backgroundColor: '#9b9b9b',
    color: '#fff',
  },
}));







const FreeMintMiner = ({ windowWidth, handleConfirmClose, open, onChange, BuyName, id, windowHeight, hashRate, url, token_id, goNFT }: TypeProps) => {

  const outLentData: any = useOutletContext()

  useEffect(() => {

  }, [outLentData?.whiteList])
  useEffect(() => {

  }, [outLentData?.hasClaimed])

  useEffect(() => {

  }, [outLentData?.nftTokenId])



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));


  const [hash, setHash] = useState('')

  useEffect(() => {

  }, [hash])


  const notificonfig = {
    top: windowHeight * 0.4,

  }


  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement, err: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      closeIcon: windowWidth >= 600 ? true : false,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '400px' : '160px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,

    });
  };


  const [openSucced, setOpenSucced] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openLevelUp, setOpenLevelUp] = useState<boolean>(false)

  const handleTranstionConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSucceedClose = () => {
    setOpenSucced(false)
    setOpenLevelUp(true)
  }

  const handleLevelUpClose = () => {
    setOpenLevelUp(false)
    // onChange()
  }


  const confirmMint = async () => {
    const provider = getEthersSigner(config)
    const FreeMintContract = new ethers.Contract(FreeAirdrop, freeClaimAbi, await provider)
    setOpenConfirm(true)
    handleConfirmClose()

    await FreeMintContract.claim().then(async (res) => {

      const res1 = await res.wait()
      if (res1.blockNumber == null) {
      } else {
        setHash(String(res1.hash))
        setOpenConfirm(false)
        setOpenSucced(true)

      }


    }).catch((err) => {
      // console.log("错误结果", err)
      setOpenConfirm(false)
      openNotification('top', `freeClaim(mint) ---- ${err}`)
      handleConfirmClose()
    })
  }



  // const dispatch = useDispatch()

  // const onUpated = () => {
  //   dispatch(getUserInfoData())
  // }

  // const checkSuccess = (value: boolean) => {
  //   if (value) {
  //     dispatch(getUserInfoData())
  //   }
  // }

  const { address } = useAccount()

  // const connectWallet = () => {
  //   return (
  //     <ConnectWallet code="" windowWidth={windowWidth} windowHeight={windowHeight} onErrText={(err: string) => { }} onChange={onUpated} checkSuccess={checkSuccess} />
  //   )
  //



  return (
    <>
      {contextHolder}
      <Confirm windowWidth={windowWidth} NFTName={BuyName} open={openConfirm} handleConfirmClose={handleTranstionConfirmClose} />
      <Succeed windowWidth={windowWidth} NFTName={BuyName} open={openSucced} handleConfirmClose={handleSucceedClose} hash={hash} />
      <FreeMintSucceed windowHeight={windowHeight} windowWidth={windowWidth} open={openLevelUp} handleConfirmClose={handleLevelUpClose} NFTName={BuyName} NftId={id} token_id={token_id} url={url} onChange={onChange} />
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Miner Detail
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>
                  <Box sx={{ width: '100%', mb: '20px', textAlign: 'center', height: '240px', lineHeight: '240px' }}>
                    <img src={url} style={{ borderRadius: '10px', width: '240px', lineHeight: '240px', verticalAlign: 'middle' }} />
                  </Box>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="20px">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 700, lineHeight: '30px', textAlign: 'center' }}>
                      HAYA {BuyName} Miner - Gift
                    </Typography>
                    {/* <Typography sx={{ color: "#1AAE70", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      {totalNum} Minted
                    </Typography> */}
                  </Stack>
                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px 10px 20px' }}>
                    <Stack alignItems="center" spacing="10px">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Collection
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner name
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA {BuyName} Miner - Gift
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Period of validity
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          7 days
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Hash rate
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {hashRate} E
                        </Typography>
                      </Stack>

                      <Box sx={{ backgroundColor: '#c0c0c0', height: '0.5px', width: '100%' }}></Box>
                      <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px', textAlign: 'start', width: "100%" }}>
                        NFT Info
                      </Typography>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerFreeContract?.substring(0, 6)}...{MinerFreeContract?.substring(MinerFreeContract.length - 4)}
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Blockchain
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {network_Name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token standard
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ERC-1155
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token ID
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {outLentData?.nftTokenId}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ipfs://QmbG...g9XF
                        </Typography>
                      </Stack>

                    </Stack>

                  </Box>




                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%" alignItems="start" spacing="20px">
                  <Stack direction="row" alignItems="start" spacing="8px">
                    <img src={infoIcon} />
                    <Typography sx={{ color: '#6f6f6f', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                      Tip: Staking consumes the NFT, so it won't be returned after expiration.
                    </Typography>

                  </Stack>
                  {
                    address !== undefined ? (
                      <>
                        {
                          isAuth() ? (
                            <>
                              {
                                !outLentData?.whiteList ? (
                                  <>
                                    <SelectButton disabled>Not White List</SelectButton>
                                  </>
                                ) : (
                                  <>
                                    {
                                      !outLentData?.hasClaimed ? (
                                        <>
                                          <ViewDetailButton onClick={confirmMint}>Free Mint</ViewDetailButton>
                                        </>
                                      ) : (
                                        <>
                                          <SelectButton disabled>Minted</SelectButton>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }
                            </>
                          ) : (
                            <SelectButton disabled>unregistered</SelectButton>

                          )
                        }
                      </>
                    ) : (
                      <SelectButton disabled>Not connected</SelectButton>
                    )
                  }
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleConfirmClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0', width: `${windowWidth}px` } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                    Miner Detail
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 0px 10px 0px' }}>
                  <Box sx={{ width: '100%', mb: '20px', textAlign: 'center', height: '240px', lineHeight: '240px' }}>
                    <img src={url} style={{ borderRadius: '10px', width: '240px', lineHeight: '240px', verticalAlign: 'middle' }} />
                  </Box>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="10px">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 700, lineHeight: '30px', textAlign: 'center' }}>
                      HAYA {BuyName} Miner - Gift
                    </Typography>
                    {/* <Typography sx={{ color: "#1AAE70", fontSize: '16px', lineHeight: '22px', fontWeight: 600 }}>
                      {totalNum} Minted
                    </Typography> */}
                  </Stack>
                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '15px 15px 10px 15px' }}>
                    <Stack alignItems="center" spacing="10px">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner Info
                        </Typography>
                        <IconButton sx={{ width: 'auto', height: 'auto' }}>
                          <img src={upIcon} />
                        </IconButton>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Collection
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA Genesis Miner NFT
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Miner name
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          HAYA {BuyName} Miner - Gift
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Period of validity
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          7 days
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Hash rate
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {hashRate} E
                        </Typography>
                      </Stack>

                      <Box sx={{ backgroundColor: '#c0c0c0', height: '0.5px', width: '100%' }}></Box>
                      <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px', textAlign: 'start', width: "100%" }}>
                        NFT Info
                      </Typography>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerFreeContract?.substring(0, 6)}...{MinerFreeContract?.substring(MinerFreeContract.length - 4)}
                        </Typography>
                      </Stack>
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Blockchain
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {network_Name}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token standard
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ERC-1155
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Token ID
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {outLentData?.nftTokenId}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract address
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          ipfs://QmbG...g9XF
                        </Typography>
                      </Stack>

                    </Stack>

                  </Box>




                </Box>
                <Stack width="100%" alignItems="start" spacing="10px">
                  <Stack direction="row" alignItems="start" spacing="8px">
                    <img src={infoIcon} />
                    <Typography sx={{ color: '#6f6f6f', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                      Tip: Staking consumes the NFT, so it won't be returned after expiration.
                    </Typography>

                  </Stack>
                  {
                    address !== undefined ? (
                      <>
                        {
                          isAuth() ? (
                            <>
                              {
                                !outLentData?.whiteList ? (
                                  <>
                                    <SelectButton disabled>Not White List</SelectButton>
                                  </>
                                ) : (
                                  <>
                                    {
                                      !outLentData?.hasClaimed ? (
                                        <>
                                          <ViewDetailButton onClick={confirmMint}>Free Mint</ViewDetailButton>
                                        </>
                                      ) : (
                                        <>
                                          <SelectButton disabled>Minted</SelectButton>
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }
                            </>
                          ) : (
                            <SelectButton disabled>unregistered</SelectButton>

                          )
                        }
                      </>
                    ) : (
                      <SelectButton disabled>Not connected</SelectButton>
                    )
                  }
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }

    </>
  )

}
export default FreeMintMiner
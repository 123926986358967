import { Box, IconButton, Stack, Typography } from "@mui/material"
import downIcon from 'assets/icons/downIcon.svg'
import upIcon from 'assets/icons/upIcon.svg'
import { useCallback } from "react"
import { useEffect, useState } from "react"
import { debounce } from 'lodash';

type PropsType = {
  windowWidth: number
  windowHeight: number
}

type DataType = {
  title: string
  content: string
}


const ReadPage = ({ windowHeight, windowWidth }: PropsType) => {



  const data: DataType[] = [
    {
      title: 'What is Haya Miner? ',
      content: `Haya Miner is the mining program launched by Haya Finance (also Haya ). 
      Haya is the first decentralized crypto bank for digital residents. Its mission is to offer easy-to-use crypto native currency and index tokens, making advanced investments simple and accessible for everyone.
      `
    },
    {
      title: 'What is the $HAYA token?',
      content: '$HAYA is the utility and governance token of Haya Finance.'
    },
    {
      title: 'What is the structure of the mining rewards for Haya Miner?',
      content: `Haya Miner generates rewards in $HAYA tokens, with the amount varying based on the type of the miner and competitiveness of the mining program.
      Mining output calculation rules：
Hourly HAYA Output = Miner Hash Rate x Network Mining Coefficient
*Network Mining Coefficient = Total $HAYA Mined / Remaining Mining Power
Remaining Mining Power = Miner Hash Rate x Remaining Mining Hours

`
    },
    {
      title: 'Why $HAYA is backed by 20 mainstream crypto assets?',
      content: `The revenue from the mining program will be used to purchase $H20, an index token issued by Haya Finance that bundles 20 top-tier cryptocurrency assets. $H20 serves as both an on-chain ETF and a native crypto currency, enhancing liquidity for $HAYA.`
    },
    {
      title: 'What is the Haya Global Partner Program? ',
      content: `Haya Global Partner Program is a "Refer and Earn" rewarding plan. The program is structured into three levels, each offering unique benefits and opportunities for earning rewards. Partners of each level are required to stake a certain amount of $HAYA to be eligible for the program.
      Partner Levels & Benefits
Haya Agent:
Entry-level partners starting their journey with Haya.
Basic commission rates.
Haya Partner:
Mid-level partners with growing networks.
Competitive commission rates.
Haya Global Partner:
Top-tier partners with extensive networks and significant influence.
Exclusive rewards and higher commission rates
`
    }

  ]

  const [open, setOpen] = useState(new Array(data.length).fill(false))

  const onOpen = useCallback(debounce((index: number) => {
    setOpen(open.map((bool, i) => i == index ? true : false))

  }, 200), [])

  const onClose = useCallback(debounce((index: number) => {
    setOpen(open.map((bool, i) => i == index ? false : false))

  }, 200), [])

  useEffect(() => {

  }, [open])
  return (
    <>
      {
        windowWidth >= 600 ? (
          <>
            <Box sx={{ maxWidth: '1340px', margin: '0 auto', padding: '0px', backgroundColor: '#fff', borderRadius: '20px' }}>
              <Stack alignItems="center" width="100%" sx={{ borderRadius: '20px' }}>
                {
                  data.map((item, index) => {
                    return (
                      <>
                        {
                          !open[index] ? (
                            <>

                              <Box sx={{ width: '100%', cursor: 'pointer', border: 0, backgroundColor: 'transparent', padding: 0 }} component="button" onMouseEnter={() => onOpen(index)} >
                                <Stack alignItems="center" direction="row" justifyContent="space-between" padding="20px 30px" width="100%">
                                  <Typography variant="body1" sx={{ color: '#000000', fontWeight: 600, fontSize: '22px', lineHeight: '36px' }}>
                                    {item.title}
                                  </Typography>
                                  <IconButton onClick={() => onOpen(index)} sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                                    <img src={downIcon} />
                                  </IconButton>
                                </Stack>
                              </Box>
                              {
                                index == data?.length - 1 ? (
                                  <></>
                                ) : (
                                  <Box sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)', height: '0.5px' }}></Box>
                                )
                              }
                            </>
                          ) : (
                            <>
                              {
                                <Box sx={{ width: '100%', cursor: 'pointer', border: 0, backgroundColor: 'transparent', padding: 0 }} component="button" onMouseEnter={() => onClose(index)} onMouseLeave={() => onClose(index)}>
                                  <Stack spacing="8px" alignItems="start" padding="20px 30px" width="100%" sx={{ backgroundColor: '#1aae70', borderRadius: index == 0 ? '20px 20px 0 0' : index == data.length - 1 ? '0 0 20px 20px' : 0 }}>
                                    <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '22px', lineHeight: '36px' }}>
                                        {item.title}
                                      </Typography>
                                      <IconButton onClick={() => onClose(index)} sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                                        <img src={upIcon} />
                                      </IconButton>
                                    </Stack>
                                    <Typography textAlign="start" variant="body1" sx={{ color: '#fff', fontWeight: 400, fontSize: '18px', lineHeight: '24px', wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
                                      {item.content}
                                    </Typography>

                                  </Stack>
                                </Box>
                              }

                              {
                                index == data?.length - 1 ? (
                                  <></>
                                ) : (
                                  <Box sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.1)', height: '0.5px' }}></Box>
                                )
                              }


                            </>
                          )
                        }


                      </>

                    )
                  })
                }



              </Stack>

            </Box>
          </>
        ) : (
          <>
            <Box sx={{ width: '100%', padding: '0px', backgroundColor: '#fff', borderRadius: '20px', }}>
              <Stack alignItems="start" width="100%" sx={{ borderRadius: '20px' }}>
                {
                  data.map((item, index) => {
                    return (
                      <>
                        {
                          !open[index] ? (
                            <>

                              <Box sx={{ width: '100%', cursor: 'pointer', border: 0, backgroundColor: 'transparent', padding: 0 }} component="button" onMouseEnter={() => onOpen(index)} >
                                <Stack alignItems="center" direction="row" justifyContent="space-between" padding="20px 10px" width="100%">
                                  <Typography variant="body1" sx={{ color: '#000000', textAlign: 'start', fontWeight: 600, fontSize: '16px', lineHeight: '22px' }}>
                                    {item.title}
                                  </Typography>
                                  <IconButton onClick={() => onOpen(index)} sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                                    <img src={downIcon} />
                                  </IconButton>
                                </Stack>
                              </Box>
                              {
                                index == data?.length - 1 ? (
                                  <></>
                                ) : (
                                  <Box sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.2)', height: '0.5px' }}></Box>
                                )
                              }
                            </>
                          ) : (
                            <>
                              {
                                <Box sx={{ width: '100%', cursor: 'pointer', border: 0, backgroundColor: 'transparent', padding: 0 }} component="button" onMouseEnter={() => onClose(index)} onMouseLeave={() => onClose(index)}>
                                  <Stack spacing="8px" alignItems="start" padding="20px 10px" width="100%" sx={{ backgroundColor: '#1aae70', borderRadius: index == 0 ? '20px 20px 0 0' : index == data.length - 1 ? '0 0 20px 20px' : 0 }}>
                                    <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontSize: '16px', lineHeight: '22px', textAlign: 'start' }}>
                                        {item.title}
                                      </Typography>
                                      <IconButton onClick={() => onClose(index)} sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                                        <img src={upIcon} />
                                      </IconButton>
                                    </Stack>
                                    <Typography textAlign="start" variant="body1" sx={{ color: '#fff', fontWeight: 400, fontSize: '14px', textAlign: 'start', lineHeight: '22px', wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
                                      {item.content}
                                    </Typography>

                                  </Stack>
                                </Box>
                              }

                              {
                                index == data?.length - 1 ? (
                                  <></>
                                ) : (
                                  <Box sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.1)', height: '0.5px' }}></Box>
                                )
                              }


                            </>
                          )
                        }


                      </>

                    )
                  })
                }



              </Stack>

            </Box>
          </>
        )
      }
    </>
  )
}

export default ReadPage
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken, setToken } from "utils/token"


type ChallengeType = {
  access_token: string
}

type Data = {
  data: ChallengeType
  error: null | string
  success: boolean
}


// 更新令牌token
export const updateRenewTokenData = createAsyncThunk('account/renew_token', async () => {
  const res = await http.post('/renew_token', "", {
    "headers": {
      "Authorization": `Bearer ${getToken().miner_token}`,
      "Content-Type": "application/json"
    }
  }

  )
  const newToken = {
    miner_token: res.data.data.access_token
  }
  setToken(newToken)
  // console.log('获取 updateRenewToken', res.data)
  return res.data
})

const initialState = {
  data: {},
  error: null,
  success: true,
} as Data


const slice = createSlice({
  name: 'updateRenewToken',
  initialState,
  extraReducers: builder => {
    builder.addCase(updateRenewTokenData.fulfilled, (state, action) => {
      state = action.payload
    });
  },
  reducers: {}
})


export default slice.reducer

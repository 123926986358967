
import { useEffect } from "react"
import { useOutletContext } from "react-router"
import { message } from 'antd';
import { clearToken, isAuth } from "utils/token"
import { useDispatch } from "store"
import { getUserInfoData } from "store/reducers/Miner/getUserInfo"
import MinerPage from "./Miner";

type PropsType = {
  windowWidth: number
  windowHeight: number
}

const MinerIndex = ({ windowHeight, windowWidth }: PropsType) => {


  // const [data, setData] = useState<string[]>([])

  const outLentData: any = useOutletContext()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuth()) {
      dispatch(getUserInfoData()).then((res) => {
        console.log('res', res)
        if (res.payload == undefined) {
          clearToken()

        }

      }).catch((err) => {
        console.log(err)
      })
    }

  }, [isAuth()])


  // const getTokenDetail = async () => {
  //   const BasicContract = new ethers.Contract(BasicIssuanceModule, basicIssAbi, provider)

  //   await BasicContract.getRequiredComponentUnitsForIssue(
  //     H30_Address,
  //     String(1 * (10 ** 18))
  //   ).then(async function (result: any) {
  //     let newArr: string[] = []
  //     let addresses: string[] = []
  //     // setResData(result)
  //     // console.log(result)

  //     for (let i = 0; i < result[0].length; i++) {
  //       addresses.push(result[0][i])
  //     }
  //     const symbolsContract = new ethers.Contract(total_Address, totalAbi, provider)

  //     await symbolsContract.batchFetchBaseInfos(addresses).then(async (res) => {
  //       for (let i = 0; i < res[0].length; i++) {
  //         newArr.push(String(res[0][i])?.split('-')[0])
  //       }
  //     })
  //     setData(newArr)
  //   })
  // }

  const [messageApi] = message.useMessage();







  useEffect(() => {
    if (!outLentData?.userCheck) {
      messageApi.open({
        type: 'warning',
        content: 'Please enter the invitation code',
        duration: 3,
        className: 'custom-class',
        style: {
          marginTop: '74px',
        },
      });
    }

  }, [outLentData?.userCheck])

  useEffect(() => {

    // console.log('错误信息', outLentData.errText)

    if (outLentData?.errText?.length !== 0) {
      // console.log('错误信息111111', String(outLentData.errText).split(':')[1])
      messageApi.open({
        type: 'error',
        content: `${String(outLentData?.errText).split(':')[1]}`,
        duration: 3,
        className: 'custom-class',
        style: {
          marginTop: '74px',
        },
      });
    }

  }, [outLentData?.errText])





  return (
    <>
      <MinerPage windowHeight={windowHeight} windowWidth={windowWidth} />


    </>
  )
}

export default MinerIndex
type Props = {
  size: number
}

const TelegramIcon = ({ size }: Props) => {
  return (
    <svg width={`${size}`} height={`${size}`} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.474609" width="32" height="32" rx="10" fill="#C0C0C0" />
      <path d="M10.4196 15.226C10.4196 15.226 17.0538 12.5918 19.3546 11.6642C20.2366 11.2932 23.2277 10.1059 23.2277 10.1059C23.2277 10.1059 24.6082 9.58653 24.4932 10.848C24.4548 11.3674 24.1481 13.1854 23.8413 15.1518C23.3811 17.9345 22.8826 20.9769 22.8826 20.9769C22.8826 20.9769 22.8059 21.8302 22.154 21.9786C21.5021 22.127 20.4283 21.4592 20.2366 21.3108C20.0832 21.1995 17.3605 19.5299 16.3635 18.7136C16.0951 18.491 15.7883 18.0458 16.4018 17.5264C17.7824 16.302 19.4313 14.7808 20.4283 13.8162C20.8885 13.3709 21.3487 12.3321 19.4313 13.5935C16.7086 15.4116 14.0243 17.1183 14.0243 17.1183C14.0243 17.1183 13.4107 17.4893 12.2603 17.1553C11.1098 16.8215 9.76767 16.3762 9.76767 16.3762C9.76767 16.3762 8.84739 15.8197 10.4196 15.226Z" fill="white" />
    </svg>



  )
}

export default TelegramIcon
type Props = {
  size: number
}

const Weth = ({ size }: Props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={`${size}px`} height={`${size}px`} viewBox={`0 0 ${size} ${size}`} enable-background="new 0 0 50 50" xmlSpace="preserve">  <image id="image0" width={`${size}`} height={`${size}`} x="0" y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAW
GklEQVRo3q16WXNcx5XmdzLvVrcWAIUq7AuB4gKK4CqbptQU2bIsUrI0bbW7I9rjbrnbCvc6jpgn
P8zL/AU/TYQjJmba4xmpTYfsiY7WUJZapGWQLbcsqy2JImkSCwFiR6FQqP1umWceqlAEtVjyjDLi
REVlZVbe754831kyCZ+ydbtJClkLAkhprZMxlxu+L/0oFJo1komkKwK1B6GOwZIBMQiRNmHJio9o
WUWqrpTimGlrQ0pVCzwYUkoGIECqWK/wp32Wj2r0yQBSFHAkmJmrXkMDQFciZYZRNKhUlItYD0ZK
JYkoxcw9zGyAoAAADAlCQCQKACqCqCJAy4Y05izDWCrVqz4AJJwYEUgIEJe8mv7MgXQ6CSIClGBi
xWAgGamoRzOPKa1PWiRPJ2E+QEBSQgibpDAhIIggSIBA0MwIoXTEWivoegPqN1UOrirwm0KIOVPK
vCCx7UehdqQBEEFpjarf+J009LFAeuOdVAkaZFkWp+Mp5CvFHj8InlJanydg3CSZ7TeS2YNGxh2S
HRiWKQybHeggBxZJSBJgMOocYl1VsRSVMB9u47Yq+DOqmC9F3joRFgSJ1w0hXnJN526hXlbJmGu0
tK9+FyDGh7UQp4g1rde2NQAmos6lrfXjSutHmHEuTc6J/WY6ts/MYNxMY8zo4pzRhb1mltNGBwjM
dV2nkmrQqqqgEYWQTLBhwCZJLht2J1tDTHpIg48w0Zivo76yX7+ccuP/Vq7XigDQGU9IrRkOGXqj
XvpE7XxII91ukuphgI5YHFW/kfbD4CwzP2dDPpyV8fgpe9h82j2Ak/YQdwmXAIaGBkAo6QAz4SZu
hRtYj6q0IKq8YfmAIAIIipiZGQEUN6IAxVqZNv2qqnNYBvCGKeXzpmG9YUsjb1qmX/casCA5Yo2S
V/utYNoa6XDipJkpJk0u1Csshej1o+DLivWfdZFz8qQ9lHg8luMH7UGMGh3okUnYZKHBAX4TbuB1
7w6uNhbodrCBEgeIJxLoGumnzoFRuLEYpJAwIWAwAQBVqlXoO3PYXGwY7AVpAp1hRi9rfa6uvBdd
QZcqjbqfsF1iYpFyXF326h8Lpq2RlO1SpBXFLQe1yO8NwvBJzfrZDrIf/n1nzPqTxGE+bY+iR8RJ
QaPMPs9HRbzlLdG/eHfxDvIodhvo6s2gP9uL3OgY9uVyGOgfgOM4AABmBgNg1qhUqlheWsKdO3ew
vLyMfD6P9bU1bG4WvCiKXjWkfMG27Hcdw1zYrGw3Uo5LDFDFq+uPBdLhuEIzs21a8MIg64XBlxXr
v+wg+8QZZ9T588QJPuvsgUOSNBg1HeJ6uMH/u3ETF8NZKsoAe8bHcPZLX8TZM2ewb+8+dHV0wnEc
mKYJIcR9izIztNYIwxCNRgP5fB7Xrl3DpUuXcOXqFayvrdfDIFwE8Kop5AXXdt7p78w0FgqrRIyP
1MwOEPKiCLZhmI0wOA/m/5iC9fDv2SOxP08e54ftEXQLlxiMabXF/1yfppert3DbrqL7aA4PP/QQ
HjpxEocnJzE+Pg7XdX8XwgEzY3NzEzMzM7h+/TouXbqE119/ndfX1leI8IZpmP/Yk878dHF9pdDh
JqTSCh9kNZlyXFn26jphxww/CiZDrZ6Nk/nEGWfUfTZ5nB91xqhDOFRHiBVV5X+s3cTfV39NN1N1
HD71IL759WfxF3/2DZw8eRK9vb0wTRNaa2jWYGaAGQxubqvdohmaGZo1CIR4PI7BwUEcOnQIg4OD
0FqT53mpWq221/O9/obnbXSnulbG9uzxi4UtxEwLXhTeA+LajuGFgZZCZLXSzwrQH+81ujJ/kTzB
T7r7YZNBBGBNVfkn9Rv4+8q/0XpS4ct/8DT+7q/+Bl989IvoyWZBRE0BQDsOcafvt0h7HAAQwTAM
9Pb24oEHHsDQ8DC2torG8spyJgqjLs16u9HwForVciANw7CkyaGKmkBsw6Thrh6UvNpEqNU3h2Ty
6B+4E/SUewBDMgWfIyrC58uNOfy38q9oNc146stP4VvffA6nT59GMpkEACil2g/zaQDsltb+gtJN
LVqWhXQ6jZHhYXbdGBWLRWtjY6O34TWkVmp+sLt3q+572iCBpBVDPfQhD4/sxdJ2vtuPwnOC8eRJ
e7D7G8njnDPTAEAeFP/CX8T/qr5DN+wyHj33OP7ur/8GJ0+ehG3b0LpJIoZhNKMl+sTw7cOMs6Md
ISAEQakmIDcWo8HhYaSSSdxdvGvnN/KpMAyjMIruxAxr0wsDRKwpVBFLFWmjHnjHSOMbGRE79vux
cfMpdz/SIgafFS1GJfp++W36ubGC44+cwre+8U2cPn0aruuCWX/4zf5/N7rnE6hpO5lMZocQkoWt
QsbzvTtdHZ23uhIdqlyvUKQVSyKYoYrOxmF+/ajV2/24k+NJqxeusGhdV/GKN8M/qr1PTq4Pf/uX
f40nzp1HKpUCtzQhpPwMQdzTEIiaZAEgHo+jp6cH5XJZvHftWqpWqwkGVgjYqNXrYdyJSZHuSscU
6z4LIvU5axDH7H5IEBjM02GBXwqm0eiy8Njps3j0zFmk02kopcAASIg2M+3Q6GclACCEgFIKYMbY
2BgeeeQRjI+P26ZpPer5/h+VatV0yIpNwxCGV6nt0cy9tjCMg1YWe80Ma1ZU1j6u++u4rjZp4uTn
8YfPPIOBgYHWIgyAWvbBza3FzZCdmdssxC1wu3YKCPSh/radAGj6/ua4HU0rrWFZFiYnJ/HMM89g
e3u7a2Z6+lAE7gawFCnFYturPiBAPb0yQSNGJxIijgiMuWgL1/w1apgaD33hFB5+6GE4jgMigmGY
kFK2xIAQEiQEpJQwDANSSojW74ZhtEVK4yP7759zbxwJAdM0YVkWAKCvr4/Pnz/PExMTJIToY8aR
A2N7O7XSbCjWIwZRpwMptlQDdd2AYo2bYR7TusjZTJb2DY9BCIFSqQQigm3bAIAwDKG1hm3bEEIg
CAJEUdQCa4CZEUVR++0TEUzTbPfvboZhgIja/TsAlVIIgqBtKyMjI8jlcnBdt69erT22vL56Ix1P
vmMQyNCAnI228D+rv0ZCWDRp9mA62kIhLXD0+DFYpolLr13C9Mw0Ojs7cejQIbDWuHHjBirVKk6c
OIF0Oo3r169jdnYWXV1dyOVyKBQKmJmZacdWyWQSub17EQYBZmdnEYZNzyylRC6Xg+M4mJubQxiG
mJiYwPj4OBYXF/Hee++hq6sLjz32GA0ODuLgwYPI5XIdM9Mzx7xGY7is6T0D4MiE1Ju6gZ/6s3Dq
Ns5ZY/QrbwkYSeD4gw/CdV1MXZnCxYsXMT4+jnqjgUqljIv/5yK2t7fxta99DaOjo7h48SKuXr2K
/fv344knnsD169fx6quvtg04k83izCNn4HkNTE1NoVKpAAASiQTOnDnTXGdqCtVqFU8++STOnz+P
N998ExcuXMCBAweQy+UwNDSEyclJHD5yRKysrvbU67XOSCsIAOggGyOyA3GY+Ndwif9L7S38wl9E
3I5h/969GBsbg2lZyOfzWF1dxfraGmZnZjE3N4fV1VUsLi1hfmEBi4uLyOfz0FojkUggCAJsbW0h
CALE43F0dnQimUzCcRxorVEoFLC9vY1YLIZ0Og0ShK2tLaysrGBtbQ3lchlbxS3k83lUqlV4ngci
4lwup/ft2wcpZUxrTgz0DQgDIAYRj5ldeMxMYwU1+hdvASXtIxFzMTw0jPHxceTGx2HbNjY3N7G0
tIRSuYwwDBFFEcqlEha1xsrKCgQR0uk04ok4mBlCCOzZswcnv/AFHD1yBMePH8fKygrW19eRz+eR
SCbw+OOP4ytf+Qpu3bqFV376CpgZ5XIZ8/PzWFtdg9Ya1k4wqjWSyRRns1lIKSWALsuxUgaDrYr2
ZULY+GP3ECJoBKGP12kelmWho7MDHR0dGBkZQVdXF9bX17G8vIxqrQatNZyYg1qthvzmJvL5PFId
HRgaHIQUEmErOtVaQ0URLNvG3r17MTQ0hF/+8pf4+c9/jo5UB048+CBOnTrV/D/HAWuNxcVFXLp8
GYt374KZ21E1M5NpGkilUjAMgxic2ipudRkA3AZHZkHVKUYGH7UHeCbYxHvBOgVSQxjNbLizsxND
Q0NoNBoobBawvLIMZkZ/Xz/q9ToWFhYQRiH2jO3BeC4Hy7LaRj49PY3l5WXMzMzgYMuIxY4zRdPh
7I63NDOWl5exuroKz/NgWRYMw0CkFLTWkFJyCwgYMKMocgWBIgbzQljEa41ZWlFl5MxudMJCRNx2
Sol4HCOjIzAtCytrKygWi4jH40in09gsFLC2tgZDGujr7UNPT09z4bBJxalUCqOjo8jt3YtUKoUw
CBAEAZgZSin4vtfeplo347eenh5MTk5iaGgIRNT08LvaDuUDEIi0IYSgaUGUX9NV/WL9On5Ue58W
wiI0McBoR7fxRAIjwyMwpERxqwgiQjabRSaTgec10Gg0YJomMtksstksDMNAGIYQQmB8fBxf+tKX
8PTTT2NgYAB+ELQdqNj1udunHDp0CF/96lfxuc99DkIIhGEI415cR77v7zybIkMGhinNq1oH+31W
0e1oC68Es+jXMSpoD2k/RKNWbwMZHhlBdyYDMTMDIkJvby/2H9iPxcVFCCFg2zb2jI4il8thenq6
Ha4Ui0Vcu3YNkYqQjMfhxGIA0M7n70u0WsBc18XAwAAymQyklDtbasfxUj6fRxiGmoCqG3eLBgHL
RLToCKOeICu5qEp8O8qjqD0yIqBSLkMphWQigZHhYbhuDFEUQQiB/v5+HJw4iKtXrrZV39/fj76+
Pty6dQvb29toNBqYnp7G7Ows3n//fSQTSRw5ehSVchnVahVEhHq9AWZGEASotmi2UCigXq+jWq2i
VquhUqnsRA3s+z4XCgUEQQAiqnYkO0qGJU3tq3AxRc5cN8UypCAbDCYhuFKr0szsDCYmJpDNZjEy
MoKxsTH09fUhFovh6LFjmJiYwPDwMHp6epDL5TAyOgLbtuG6Lnp7e9HX17fDNkgkEnAcBzHHQXd3
NwYGBhCPx5FMJkBEiMVi6Ovrg+/76OvrQyKRQDqdRn9/P3p7exFPxHdCJVEoFKCU0gBqC0t3fcM0
pDIjeaOuwlc08/AhMzt00h7CG/5dzC3M47XLl3Fw4iAymQz6+/vxp//+63jo1EOIu3EcPXYU3Zlu
PPfcczh37hx6e3tx7OgxSCkxMTGBb3/721heXm4DicfjeOCBB5DNZtHX14cjR47AMAwcP3YMlmVh
//79+M53voN6vY7R0VEMDQ1hz549+PznP4+enh4cnjwMFUV08+ZNzM3NgZWuSBJl3/M1umIJkU10
GpZpPipJvHHKGuT/1HlG/1HikO4zEnz08BH+yU9+wkopZq05DEOOoqj5nZm11hxFEUdRxFpr3t2U
UhyGYVt2j/moeVprjnaN01qzUuq+9SqViv7ud7/LRw4fDlJu/GeOYZ7rjCUNQYJkvrodCYhZSeK9
O2q79Ia3QD3k4oToRbhdxVapeC+ZIgIJaidSOwZKrYzunm9o/nYv3L/HTLsTp91sRUTtjLOdl7RI
oMVQ2vd9zM7OYXZ2dqPWaLxpmua8bZlaqNYIyzDyUsofberG5d+Em2GJfSJB2KyWcOP2LawsLyOK
mmE7K93eLh+V2WEnqWqFFDvCWreySW47y3t9H8gwdasm1pqrlILneXTnzh2an78D3/e3DCnfmBzd
v1SqV0n6UciuZYtQqci27ZVQKa0J+yPWmYKqiaJqQGuNbLZpzDHXBWsN2Xqb91dA7lVRqHUGJXYJ
fQTV0gfnfOC/NDNEK49ZWVmmF1/8MV7/2c+C4lbxbSL6h4WNlbW47UgJAAkzRkQEQxpKaVUMWQUl
5Y0GpNMJYWFza5OVAB09ehSZTAZ6J539jIsOH9V2tKy15qmpK/S9731PTU9P3ybQP1nS+EWgorpj
WkICQI+bRAgtABgxwy4zsOzrUClwmkA9242q4QU+MpkMRkZG0NHR0QwvtPpQgfqzBKCUgpQSzIxr
167h+eefp6mpqdBreBfjsdgLSTexEoahllKyBICS30DMskGCtGgWByIQ/EirTEMH40yIhWGIra0t
SqZSGBwchOu6bQPf2RafVdsJi3aMfG5uDj/4wQ/opZdeCouFrZtSyv8RN52pql+PDGlQpVHTcmdy
b2eaPD8wGoGfDVR0TCl1VDOPM6OHiNwgCMx8Po+N9XU4joPR0VHE4/F2ueaz0syOJnZsZW5uDs+/
8AJ+/OKLuLuwcBvAf3Us++VivVKypQkCEERhUyMJyxFVvzHih8HjSus/1KyfZMbvAZwDkGLAZWYj
CAJsbGxga2sL8Xgc/f39SKVS7frT/aWfT6+hJlNpKKXbhQsAPD09TRcuXMCFCxd4bm5uXiv9omWa
L3TG4suKtbSFwTtHcnIk00c1vzEURdFXFev/oFk/wcwHAB4E0M1AHIAhhCBJAkHYBLO+vg63VQG0
LAuyxf//r1tsZy4zo16vY25ujn74wx/ihxcu6NnpmRWloh9bhvlCzLTnGp6nm4wGBKpVwACz6Ufh
Y4r5b5lxmIEYmmeLEoBoSYsfm2+vZS9YWFjAyuoqnFgMPdksbNtuAWFEbQ01z0FYf0xFkbDbofLm
5iZee+01+v73v4+XX36Z5+/ML0RR+KIhjX9IOO51FUVRBKaUFeNC495tCYMBqVnv1awnARgEqgEw
GbxjPwIA8e4qIhFqtRrefvvtZia3soLbt25hYmICo6OjGBwcbBfVPmonfeATtVpV3F1cwp25OXr3
3Xdx+fJlvPXWW+VqpfIegV61DPOfXMu5UaiWgqQdEwTCarV4X6mSEo5re4H/LaX1f2ZwhoCI72nj
tzbLMklKyVIa6OzsxOTkJM6ePUtnzpxBLpeDbVkQUn5wGgNNZgqCgEulEm7duiWmpqYwNTWl5u/M
e5VKpaKi6BdE9N8liX+1pVFSSkcgfOwxtREzrShS0a8AXFRa/ztu1lPvW5gAjabs3tRgzRSqkBvK
Q61WQ71ex9ramrxy5Qr19PRQf38/stksQATdYiIApLVGtVrF2toabbaKFmura9HK8tJsrV6/Iki8
Y9v2ux3J1Dur+fVqDc1TZ0kf/24JAOKWEw+VOqW0+hMNPg4gDUYHwAkG7E/SjBAClmlCSIkgCNp5
dyKRaAJB80RLkNAkKGTNUbVabZRKpWoURWWAK4LEuhTyzZhl/3PMtn9T3i56wjLBIEkM/UmXbdoU
k7BjsUirPq31Ps18lJknGBgGeJCBDDPvAGJ84MbELrbinSiVQBERIiIRAVDM7AEoE6EMRhnAJggL
hpC3iWhea11KxNzSgd7R7bcXb0UCBEcY0AAMImw1qvypgLQBWTFTg3sZ3KuU7gK4k4EupZTdmsHg
D8/bWYUILEhoIYQPQCmtFAAlQCERebukalv21tjwyOavb75fv+8ZHFcwM1lC6uInALgPyGCyi6pR
INC6VGaZJiccl0I/ELXAo1ArUkp9agchDckAIEiwaF11IgCuabMgAdMymIRgy7J4Ob8O0zCkKSVF
WrHWrLvtOM+X8r/TNaf/C4xKqVdgQIm2AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDI0LTAzLTE0VDAz
OjMzOjQ3KzAwOjAwLg51JwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyNC0wMy0xNFQwMzozMzo0Nysw
MDowMF9TzZsAAAAASUVORK5CYII=" />
    </svg>


  )
}

export default Weth
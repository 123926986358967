import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken } from "utils/token";

type UserInfoType = {
  tiger_one_count: string;
  tiger_two_count: string;
}

type DataType = {
  data: UserInfoType
  success?: boolean
  error: null | string
}

type Data = {
  InviteCountData: DataType,
  status: number
}

// 获取用户信息
export const getInviteCountData = createAsyncThunk('account/inviteCount', async (_, thunkkAPI?: any) => {
  try {
    const res = await http.get('/invite_count', {
      "headers": {
        "Authorization": `Bearer ${getToken().miner_token}`,
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 getInviteCount', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  InviteCountData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getInviteCount',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getInviteCountData.fulfilled, (state, action) => {
        state.InviteCountData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getInviteCountData.rejected, (state, action: any) => {
        state.status = 401
        state.InviteCountData.data = {
          tiger_one_count: '---',
          tiger_two_count: '---'
        }
      })
  },
  reducers: {}
})


export default slice.reducer

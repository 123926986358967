

// material-ui
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import BlockieAvatar from '../Miner/avater/blockieAvatat';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import one from 'assets/icons/one.png';
import two from 'assets/icons/two.png';
import three from 'assets/icons/three.png';
import { getLeaderBoardsData } from 'store/reducers/Miner/leaderBoards';
import { useAccount } from 'wagmi';
import { getUserLeaderBoardsData } from 'store/reducers/Miner/userLeaderboardLocation';
import { FaCaretDown } from "react-icons/fa6";



// third party

// assets

// ==============================|| LANDING - HEADER PAGE ||============================== //


function transferToNumber(num: any) {
  if (num % 1 !== 0) {
    if (num == 0) {
      return num
    } else {
      const decimalPart = num.toString().split('.')[1]

      for (let i = 0; i < decimalPart?.length; i++) {
        if (Number(decimalPart[i]) !== 0) {
          // num *= 10 ** (i + 2)
          // num = Math.floor(num)
          // num /= 10 ** (i + 2)
          var parts = String(Math.floor(num * 100000) / 100000).split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".")?.replace(/,/g, "");
        }
      }
    }
  } else {
    return new Intl.NumberFormat().format(num)
  }
}

interface DataType {
  rank: number;
  user_wallet_address: string;
  owned_mined_rewards: string;
  referral_mined_rewards: string;
  total_rewards: string;
}


// 定义要覆盖默认的 table 背景元素的组件
const components = {
  table: (props: any) => {
    const { style } = props;
    const customStyle = {
      ...style,
      backgroundColor: "#fff", // 设置表格的背景颜色
      padding: '0px 20px',
      borderRadius: '20px'

    };
    return <table {...props} style={customStyle} />;
  },
  header: {
    // 自定义头部行组件
    wrapper: (props: any) => (
      <thead style={{ color: '#9b9b9b', fontSize: '13px', borderBottom: '1px solid #f0f0f0' }}>
        {props.children}
      </thead>
    ),
  },
};



type TypeProps = {
  windowWidth: number;
  tigerType: string;
}

const LeaderListTable = ({ windowWidth, tigerType }: TypeProps) => {

  const { address } = useAccount()


  const [title, setTitle] = useState('total_rewards')

  const columns: TableProps<DataType>['columns'] = [
    {
      title: <><Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
        #
      </Typography></>,
      dataIndex: 'rank',
      key: 'rank',
      align: 'start',
      render: (_, record, index) => (
        <>
          {
            record?.rank > 3 ? (
              <>
                <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
                  {record?.rank}
                </Typography>
              </>
            ) : (
              <>
                {
                  record?.rank == 1 ? (
                    <>
                      <img src={one} />
                    </>
                  ) : (
                    <></>
                  )
                }
                {
                  record?.rank == 2 ? (
                    <>
                      <img src={two} />
                    </>
                  ) : (
                    <></>
                  )
                }
                {
                  record?.rank == 3 ? (
                    <>
                      <img src={three} />
                    </>
                  ) : (
                    <></>
                  )
                }
              </>
            )
          }
        </>
      ),
    },
    {
      title: <><Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
        Address
      </Typography></>,
      dataIndex: 'address',
      align: 'start',
      key: 'address',
      render: (_, record) => (
        <>
          <Stack direction="row" alignItems="center" spacing="10px">
            {/* <img src={indexIcon} /> `${address == undefined ? '#000' : String(address) == record.user_wallet_address ? '#1aae70' : '#000'}` */}
            <BlockieAvatar style={{ width: '36px', height: '36px' }} address={record?.user_wallet_address} />
            <Typography variant="body1" sx={{ color: address == undefined ? '#000' : String(address)?.toLowerCase() == record?.user_wallet_address ? '#1aae70' : '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              {address !== undefined ? `${String(address)?.toLowerCase() == record?.user_wallet_address ? `${record?.user_wallet_address?.substring(0, 6)}...${record?.user_wallet_address?.substring(record?.user_wallet_address.length - 4)} (Me)` : `${record?.user_wallet_address?.substring(0, 6)}...${record?.user_wallet_address?.substring(record?.user_wallet_address.length - 4)}`} ` : `${record?.user_wallet_address?.substring(0, 6)}...${record?.user_wallet_address?.substring(record?.user_wallet_address.length - 4)}`}
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      title: <>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Stack direction="row" alignItems="center" spacing="4px">
            <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              Total Mined $HAYA
            </Typography>
          </Stack>
          <IconButton onClick={() => onChange('owned_mined_rewards')} sx={{ width: 'auto', height: 'auto', padding: '0' }} >
            <FaCaretDown color={title == 'owned_mined_rewards' ? '#1aae70' : '#9b9b9b'} size={20} />
          </IconButton>

        </Stack>
      </>,
      dataIndex: 'total_mined',
      key: 'total_mined',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {transferToNumber(Number(record?.owned_mined_rewards) / (10 ** 18))}
          </Typography>
        </>
      )

    },
    {
      title: <>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Stack direction="row" alignItems="center" spacing="4px">
            <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              Referral Bonus $HAYA
            </Typography>
          </Stack>
          <IconButton onClick={() => onChange('referral_mined_rewards')} sx={{ width: 'auto', height: 'auto', padding: '0', }} >
            <FaCaretDown color={title == 'referral_mined_rewards' ? '#1aae70' : '#9b9b9b'} size={20} />
          </IconButton>

        </Stack>
      </>,
      key: 'referral_bonus',
      dataIndex: 'referral_bonus',
      align: 'start',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {transferToNumber(Number(record?.referral_mined_rewards) / (10 ** 18))}
          </Typography>
        </>
      ),
    },
    {
      title: <>
        <Stack direction="row" alignItems="center" spacing="8px">
          <Stack direction="row" alignItems="center" spacing="4px">
            <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
              Total Reward $HAYA
            </Typography>
          </Stack>
          <IconButton onClick={() => onChange('total_rewards')} sx={{ width: 'auto', height: 'auto', padding: '0' }} >
            <FaCaretDown color={title == 'total_rewards' ? '#1aae70' : '#9b9b9b'} size={20} />
          </IconButton>

        </Stack>
      </>,
      key: 'total_reward',
      align: 'end',
      render: (_, record) => (
        <>
          <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontSize: '16px', lineHeight: '20px' }}  >
            {transferToNumber(Number(record?.total_rewards) / (10 ** 18))}
          </Typography>
        </>
      ),
    }
  ];


  // const locale = {
  //   emptyText: 'Coming soon, stay tuned!',
  // };

  const dispatch = useDispatch()
  const [data, setData] = useState<DataType[]>([{ rank: 0, user_wallet_address: '', owned_mined_rewards: '', referral_mined_rewards: '', total_rewards: '' }])
  const [page, setPage] = useState(1)
  const { getLeaderBoards, getUserLeaderBoards } = useSelector(state => state)

  const { LeaderBoardsData } = getLeaderBoards
  const { status, UserLeaderBoardsData } = getUserLeaderBoards

  useEffect(() => {
    if (tigerType == 'All') {
      const paramsData = {
        order_by: title,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '1h') {
      const paramsData = {
        order_by: title,
        duration_hours: 1,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '6h') {
      const paramsData = {
        order_by: title,
        duration_hours: 6,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '24h') {
      const paramsData = {
        order_by: title,
        duration_hours: 24,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    }


  }, [tigerType])

  // useEffect(() => {
  //   console.log('11111111111111111', LeaderBoardsData?.data?.length)
  //   if (LeaderBoardsData?.data?.length !== 0 && LeaderBoardsData?.data?.length !== undefined) {
  //     console.log('2222222222222', LeaderBoardsData?._total_page)
  //     if (LeaderBoardsData?._total_page !== 1) {
  //       if (tigerType == 'All') {
  //         const paramsData1 = {
  //           size: 20,
  //           page: LeaderBoardsData?._total_page - 1
  //         }
  //         dispatch(getTotalData(paramsData1))
  //       } else if (tigerType == '1h') {
  //         const paramsData1 = {
  //           duration_hours: 1,
  //           size: 20,
  //           page: LeaderBoardsData?._total_page - 1
  //         }
  //         dispatch(getTotalData(paramsData1))
  //       } else if (tigerType == '6h') {
  //         const paramsData1 = {
  //           duration_hours: 6,
  //           size: 20,
  //           page: LeaderBoardsData?._total_page - 1
  //         }
  //         dispatch(getTotalData(paramsData1))
  //       } else if (tigerType == '24h') {
  //         const paramsData1 = {
  //           duration_hours: 24,
  //           size: 20,
  //           page: LeaderBoardsData?._total_page - 1
  //         }
  //         dispatch(getTotalData(paramsData1))
  //       }

  //     }

  //   }

  // }, [LeaderBoardsData?.data])

  useEffect(() => {
    if (status !== 401) {

      if (tigerType == 'All') {
        const paramsData = {
          order_by: title
        }
        dispatch(getUserLeaderBoardsData(paramsData))
      } else if (tigerType == '1h') {
        const paramsData = {
          order_by: title,
          duration_hours: 1,
        }
        dispatch(getUserLeaderBoardsData(paramsData))
      } else if (tigerType == '6h') {
        const paramsData = {
          order_by: title,
          duration_hours: 6
        }
        dispatch(getUserLeaderBoardsData(paramsData))
      } else if (tigerType == '24h') {
        const paramsData = {
          order_by: title,
          duration_hours: 24
        }
        dispatch(getUserLeaderBoardsData(paramsData))
      }

    }



  }, [status, tigerType, title])



  useEffect(() => {
    if (status !== 401) {
      if (address !== undefined) {
        if (LeaderBoardsData?.data !== undefined) {
          if (LeaderBoardsData?.data?.some(item => item.user_wallet_address == String(address)?.toLowerCase())) {
            setData(LeaderBoardsData?.data)

          } else {
            let arr = LeaderBoardsData?.data
            console.log('11111111111data', arr)
            if (UserLeaderBoardsData?.data == undefined) {
              setData(arr)
            } else if (arr?.length !== 0) {
              let newArr = [...arr, UserLeaderBoardsData?.data]


              // newArr?.push({ rank: UserLeaderBoardsData?.data?.rank, user_wallet_address: UserLeaderBoardsData?.data?.user_wallet_address, owned_mined_rewards: UserLeaderBoardsData?.data?.owned_mined_rewards, referral_mined_rewards: UserLeaderBoardsData?.data?.referral_mined_rewards, total_rewards: UserLeaderBoardsData?.data?.total_rewards })
              setData(newArr)
            }
          }


        } else {

          setData([])
        }


      } else {
        let arr = LeaderBoardsData?.data
        setData(arr)
        // console.log('11111111111data', UserLeaderBoardsData?.data, LeaderBoardsData?.data)
        // if (UserLeaderBoardsData?.data == undefined) {

        // } else if (arr?.length !== 0) {
        //   let newArr = [...arr]


        //   newArr?.push({ rank: UserLeaderBoardsData?.data?.rank, user_wallet_address: UserLeaderBoardsData?.data?.user_wallet_address, owned_mined_rewards: UserLeaderBoardsData?.data?.owned_mined_rewards, referral_mined_rewards: UserLeaderBoardsData?.data?.referral_mined_rewards, total_rewards: UserLeaderBoardsData?.data?.total_rewards })
        //   setData(newArr)
        // }

      }

    } else {
      setData(LeaderBoardsData?.data)
    }

  }, [status, LeaderBoardsData, UserLeaderBoardsData])



  const pageChange = (pages: number) => {
    setPage(pages)
    if (tigerType == 'All') {
      const paramsData = {
        order_by: title,
        size: 20,
        page: pages - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '1h') {
      const paramsData = {
        order_by: title,
        duration_hours: 1,
        size: 20,
        page: pages - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '6h') {
      const paramsData = {
        order_by: title,
        duration_hours: 6,
        size: 20,
        page: pages - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '24h') {
      const paramsData = {
        order_by: title,
        duration_hours: 24,
        size: 20,
        page: pages - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    }
    // console.log('page', pages)

  }

  const onChange = (value: string) => {
    setTitle(value)
    if (tigerType == 'All') {
      const paramsData = {
        order_by: value,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '1h') {
      const paramsData = {
        order_by: value,
        duration_hours: 1,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '6h') {
      const paramsData = {
        order_by: value,
        duration_hours: 6,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    } else if (tigerType == '24h') {
      const paramsData = {
        order_by: value,
        duration_hours: 24,
        size: 20,
        page: page - 1
      }
      dispatch(getLeaderBoardsData(paramsData))
    }


  }

  useEffect(() => {

  }, [data])











  // scroll={{ x: 815 }}

  return (


    <Box sx={{ '& .ant-pagination .ant-pagination-item-active': { color: '#000', borderRadius: '6px', backgroundColor: '#f6f6f6', borderColor: '#fff' }, '& .ant-pagination .ant-pagination-item-active:hover': { borderColor: '#fff' }, '& .ant-pagination .ant-pagination-item-active:hover a': { color: '#000' }, '& .ant-pagination .ant-pagination-item-active a': { color: '#000' }, '& .ant-table-wrapper .ant-table-pagination.ant-pagination': { margin: '10px 30px' }, '& .ant-table-wrapper tfoot>tr>td': { padding: '10px' }, '& .ant-table-wrapper .ant-table-tbody >tr >td': { borderTop: '0.5px solid rgba(192, 192, 192, 0.5) ', padding: '10px 10px', borderBottom: '0px solid rgba(192, 192, 192, 0.5) ', fontWeight: 600, fontSize: '24px', lineHeight: '32px' }, '& .ant-table-wrapper .ant-table': { borderRadius: '20px' }, '& .ant-table-wrapper .ant-table-cell': { padding: '10px' }, '& .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child': { paddingLeft: 1 }, '& .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover': { backgroundColor: '#F6F6F6', cursor: 'pointer' } }}>
      {
        windowWidth >= 600 ? (
          <Table columns={columns} dataSource={data} pagination={{ showSizeChanger: false, hideOnSinglePage: true, pageSize: data?.length > 20 ? data?.length : 20, onChange: (page: number) => pageChange(page), current: page, total: LeaderBoardsData?._total_records }} components={components} />
        ) : (
          <Table columns={columns} dataSource={data} scroll={{ x: 815 }} pagination={{ showSizeChanger: false, hideOnSinglePage: true, pageSize: data?.length > 20 ? data?.length : 20, onChange: (page: number) => pageChange(page), current: page, total: LeaderBoardsData?._total_records }} components={components} />
        )
      }
    </Box>
  );
};

export default LeaderListTable;

import { Box, Button, ButtonProps, Dialog, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { MinerContract, MinerFactoryContract } from "config";
import AddIcon from 'assets/icons/Add.svg'
import ReduceIcon from 'assets/icons/Reduce.svg'
import { useEffect, useState } from "react";
import { getEthersSigner } from "contract/getEthersSigner";
import { config } from "contexts/wagmiConfig";
import { ethers } from "ethers";
import minerFactoryAbi from 'abi/minerFactory.json'
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import WarningIcon from '@mui/icons-material/Warning';
import Confirm from "./transactionStatus/confirm";
import Succeed from "./transactionStatus/succeed";
import StakeApprovalTokens from "./ApproveNFT";
import MintSucceed from "./mintSucceed";


type TypeProps = {
  windowWidth: number;
  windowHeight: number
  open: boolean;
  handleConfirmClose: () => void;
  onChange: () => void
  NFTName: string
  NftPrice: string
  NftId: number
  allowance: string
  mintAddress: string
  url: string
  token_id: number
  balance: string

}

const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  width: '100%',
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
}));


const SelectButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  padding: '20px 40px',
  borderRadius: '20px',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
  width: '100%',
  color: '#fff',
  border: 0,
  backgroundColor: '#9b9b9b',
  "&.Mui-disabled": {
    zIndex: 100,
    color: '#fff',

  },
  '&:hover': {
    backgroundColor: '#9b9b9b',
    color: '#fff',
  },
}));










const ConfirmMint = ({ windowWidth, handleConfirmClose, open, onChange, NftPrice, NFTName, NftId, allowance, windowHeight, mintAddress, url, token_id, balance }: TypeProps) => {



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));

  const [amount, setAmount] = useState(1)

  const [openSucced, setOpenSucced] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const [openApproval, setOpenApproval] = useState(false)
  const [openLevelUp, setOpenLevelUp] = useState<boolean>(false)


  const handleTranstionConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSucceedClose = () => {
    setOpenSucced(false)
    setOpenLevelUp(true)
  }

  const handleLevelUpClose = () => {
    setOpenLevelUp(false)
    // onChange()
  }


  useEffect(() => {

  }, [openConfirm])

  useEffect(() => {

  }, [openSucced])


  const handleApprovalClose = () => {
    setOpenApproval(false)
  }

  const [hash, setHash] = useState('')

  useEffect(() => {

  }, [hash])


  const notificonfig = {
    top: windowHeight * 0.4,

  }


  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement, err: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      closeIcon: windowWidth >= 600 ? true : false,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '400px' : '160px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,

    });
  };







  const Mint = async () => {

    if (BigInt(allowance) > BigInt((Number(amount) * Number(NftPrice)) * (10 ** 6))) {
      const provider = getEthersSigner(config)
      const StakingContract = new ethers.Contract(MinerFactoryContract, minerFactoryAbi, await provider)
      setOpenConfirm(true)
      handleConfirmClose()

      await StakingContract.mintMiners([NftId], [amount]).then(async (res) => {

        const res1 = await res.wait()
        if (res1.blockNumber == null) {
        } else {
          setHash(String(res1.hash))
          setOpenConfirm(false)
          setOpenSucced(true)

        }


      }).catch((err) => {
        // console.log("错误结果", err)
        setOpenConfirm(false)
        openNotification('top', `mintMiners ---- ${err}`)
        handleConfirmClose()
      })
    } else {
      handleConfirmClose()
      setOpenApproval(true)
    }
  }

  // const valueRef = useRef<any>(null)

  // amount = 1 






  const onReduce = () => {
    // console.log('ref', valueRef.current?.value)
    // if (Number(valueRef.current?.value) > 1) {
    //   valueRef.current.value--
    //   // setAmount(valueRef.current.innerText)
    // }
    if (amount > 1) {
      setAmount(amount - 1)
    }
  }

  const onAdd = () => {
    // valueRef.current.innerText++
    setAmount(amount + 1)
  }



  // useEffect(() => {

  // }, [amount])



  return (
    <>
      <MintSucceed token_id={token_id} url={url} windowHeight={windowHeight} windowWidth={windowWidth} NftId={NftId} NFTName={NFTName} NFTNum={String(amount)} open={openLevelUp} handleConfirmClose={handleLevelUpClose} onChange={onChange} />
      <Confirm windowWidth={windowWidth} NFTName={NFTName} NFTNum={String(amount)} NFTPrice={NftPrice} open={openConfirm} handleConfirmClose={handleTranstionConfirmClose} />
      <Succeed windowWidth={windowWidth} NFTName={NFTName} NFTNum={String(amount)} NFTPrice={NftPrice} hash={hash} open={openSucced} handleConfirmClose={handleSucceedClose} />
      <StakeApprovalTokens price={String(Number(NftPrice) * Number(amount))} url={url} allowanceNum={allowance} mintAddress={mintAddress} onUpdate={onChange} windowHeight={windowHeight} windowWidth={windowWidth} NFTNum={String(amount)} NFTPrice={NftPrice} id={NftId} NFTName={NFTName} open={openApproval} handleApprovalClose={handleApprovalClose} />
      {contextHolder}
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Confirm Mint
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="20px">
                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '0 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Stack alignItems="start" spacing="2px" justifyContent="center">
                          <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                            HAYA Genesis Miner NFT
                          </Typography>
                          <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '30px' }}>
                            HAYA {NFTName} Miner
                          </Typography>

                        </Stack>
                        <Box sx={{ width: '120px', height: '120px', lineHeight: '120px' }}>
                          <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                        </Box>

                      </Stack>

                    </Box>

                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '32px' }}>
                          Amount
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing="12px" >
                          <IconButton onClick={onReduce} sx={{ width: 'auto', height: 'auto' }}>
                            <img src={ReduceIcon} />
                          </IconButton>
                          {/* <CodeInput inputRef={valueRef} defaultValue={1} /> */}
                          <Typography sx={{ color: '#000', fontSize: '22px', width: '64px', textAlign: 'center', fontWeight: 600, lineHeight: '36px' }}>
                            {amount}
                          </Typography>
                          <IconButton onClick={onAdd} sx={{ width: 'auto', height: 'auto' }}>
                            <img src={AddIcon} />
                          </IconButton>


                        </Stack>

                      </Stack>

                    </Box>


                  </Stack>
                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '10px 20px' }}>
                    <Stack alignItems="center" spacing="10px">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerFactoryContract?.substring(0, 6)}...{MinerFactoryContract?.substring(MinerFactoryContract.length - 4)}
                        </Typography>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          NFT Receiver
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerContract?.substring(0, 6)}...{MinerContract?.substring(MinerContract.length - 4)}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Mint fee
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {NftPrice} USDT
                        </Typography>
                      </Stack>

                      {/* <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Estimated gas fee
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          0.001 ETH
                        </Typography>
                      </Stack> */}

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '36px' }}>
                          Total
                        </Typography>
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '36px' }}>
                          {Number(NftPrice) * Number(amount)} USDT
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>





                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%" >
                  {
                    Number(balance) >= Number(NftPrice) * Number(amount) ? (
                      <>
                        <ViewDetailButton onClick={Mint}>Confirm Mint</ViewDetailButton>
                      </>
                    ) : (
                      <>
                        <SelectButton disabled>Insufficient Balance</SelectButton>
                      </>
                    )
                  }


                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleConfirmClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0', width: `${windowWidth}px` } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                    Confirm Mint
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 0px 10px 0px' }}>


                  <Stack alignItems="center" spacing="10px" justifyContent="center" mb="10px">
                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '0 10px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Stack alignItems="start" spacing="2px" justifyContent="center">
                          <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                            HAYA Genesis Miner NFT
                          </Typography>
                          <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '30px' }}>
                            HAYA {NFTName} Miner
                          </Typography>

                        </Stack>
                        <Box sx={{ width: '120px', height: '120px', lineHeight: '120px' }}>
                          <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                        </Box>

                      </Stack>

                    </Box>

                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '10px 10px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '32px' }}>
                          Amount
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing="12px" >
                          <IconButton onClick={onReduce} sx={{ width: 'auto', height: 'auto' }}>
                            <img src={ReduceIcon} />
                          </IconButton>
                          <Typography sx={{ color: '#000', fontSize: '22px', width: '64px', textAlign: 'center', fontWeight: 600, lineHeight: '36px' }}>
                            {amount}
                          </Typography>
                          <IconButton onClick={onAdd} sx={{ width: 'auto', height: 'auto' }}>
                            <img src={AddIcon} />
                          </IconButton>


                        </Stack>

                      </Stack>

                    </Box>


                  </Stack>
                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '10px 10px' }}>
                    <Stack alignItems="center" spacing="10px">
                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9b9b9b', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Contract
                        </Typography>
                        <Typography sx={{ color: '#1aae70', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerFactoryContract?.substring(0, 6)}...{MinerFactoryContract?.substring(MinerFactoryContract.length - 4)}
                        </Typography>

                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          NFT Receiver
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {MinerContract?.substring(0, 6)}...{MinerContract?.substring(MinerContract.length - 4)}
                        </Typography>
                      </Stack>

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Mint fee
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          {NftPrice} USDT
                        </Typography>
                      </Stack>

                      {/* <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#9B9B9B', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          Estimated gas fee
                        </Typography>
                        <Typography sx={{ color: '#464646', fontSize: '14px', fontWeight: 600, lineHeight: '22px' }}>
                          0.001 ETH
                        </Typography>
                      </Stack> */}

                      <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '36px' }}>
                          Total
                        </Typography>
                        <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '36px' }}>
                          {Number(NftPrice) * Number(amount)} USDT
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>





                </Box>
                <Stack width="100%" >
                  {
                    Number(balance) >= Number(NftPrice) * Number(amount) ? (
                      <>
                        <ViewDetailButton onClick={Mint}>Confirm Mint</ViewDetailButton>
                      </>
                    ) : (
                      <>
                        <SelectButton disabled>Insufficient Balance</SelectButton>
                      </>
                    )
                  }
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }

    </>
  )

}
export default ConfirmMint
import { CSSProperties, useEffect, useState } from "react";

import { createIcon } from "@download/blockies";

const BlockieAvatar: React.FC<{
  style?: CSSProperties;
  address: string;
  diameter?: number;
  alt?: string;
  rounded?: boolean;
}> = ({ style, address, diameter = 64, rounded = true, alt = "" }) => {
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    const data: HTMLCanvasElement = createIcon({ seed: address?.toLowerCase() });
    !!data && setImgSrc(data.toDataURL());
  }, [address]);

  return (
    <img
      style={{
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: rounded ? 999 : 0,
        ...style,
      }}
      src={imgSrc}
      alt={alt}
    />
  );
};

export default BlockieAvatar;
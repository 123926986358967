import { Dialog, IconButton, Box, Drawer, Stack, Typography, Button, ButtonProps } from "@mui/material";
import styled from "@mui/system/styled";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from "react";
import { H30_Address, HayaStakingContract, sepolia_rpc } from "config";
import InfoIcon from 'assets/icons/Info.svg'
import levelAbi from 'abi/level.json';
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import lowerIcon from 'assets/icons/lower.png';
import agentIcon from 'assets/icons/agent.png';
import moment from 'moment'
import crownStateIcon from 'assets/icons/crownState.png'
import ConfirmStake from "../confirm/stakeConfirm";
import tokenAbi from 'abi/token.json'
import ConfirmUnStake from "../confirm/unstakeConfirm";
import CooperativeHandshakeIcon from 'assets/icons/Cooperative-handshake.svg'
import { useDispatch, useSelector } from "store";
import { getPartnerLevelData } from "store/reducers/Miner/partnerLevel";



const provider = new ethers.JsonRpcProvider(sepolia_rpc)



type PropsType = {
  windowWidth: number
  windowHeight: number
  open: boolean;
  handleClose: () => void;
}

function ValueNumber(num: number) {

  if (num % 1 !== 0) {
    const decimalPart = num.toString().split('.')[1]

    for (let i = 0; i < decimalPart.length; i++) {
      if (Number(decimalPart[i]) !== 0) {
        num *= 10 ** (i + 4)
        num = Math.round(num)
        num /= 10 ** (i + 4)
        var parts = num.toString().split(".");
        // console.log(parts)
        // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    }
  } else {
    num *= 10000
    num = Math.round(num)
    num /= 10000

    return String(num)

  }
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '600px',
    width: '600px',
    padding: '20px 20px',
    borderRadius: '20px',
    position: 'relative'

  },
  '& .MuiDialogContent-root': {
    padding: 0,

  },
  '& .MuiDialogActions-root': {
    padding: 0
  }

}));

const UpgradeButton = styled(Button)<ButtonProps>(({ theme }) => ({

  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const UnstakeButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#000',
  backgroundColor: '#f6f6f6',

  padding: '20px 40px',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  borderRadius: '20px',
  '&.Mui-disabled': {
    backgroundColor: '#f6f6f6',
    color: '#c0c0c0',
  },
  '&:hover': {
    backgroundColor: '#f6f6f6',
    color: '#000',
  },
}));

type DataType = {
  stakeTime: string;
  unlockTime: string;
  level: Number;
  levelAmounts: string
}

type LevelAmountType = {
  id: number;
  amounts: string
}

function timeDifference(time1: any, time2: any) {
  const target = moment(time1)
  // console.log('1111111hours', time1.diff(moment().unix(), 'days'))
  // let result = '';
  if (target.diff(time2, 'days') > 0) {
    return `${target.diff(time2, 'days')}days ${target.diff(time2, 'hours') % 24}hours`
    // result += `${target.diff(time2, 'days')}days`;
  } else if (target.diff(time2, 'hours') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'hours')}hours`
    // result += `${target.diff(time2, 'hours')}hours`;
  } else if (target.diff(time2, 'minutes') > 0) {
    // if (result) {
    //   result += `/`;
    // }
    return `${target.diff(time2, 'minutes')}minutes`
    // result += ;
  } else {
    return `${target.diff(time2, 'seconds')}seconds`
  }


  // return result;
}


function formatWithK(num: number) {
  return Math.round(num).toLocaleString()
  // if (num >= 1000 && num < 10000) {
  //   return `${Math.round(num) / 1000}K`;
  // }
  // return num.toString();
}

const UpdatePage = ({ windowHeight, windowWidth, open, handleClose }: PropsType) => {

  const { address } = useAccount()

  const [levelData, setLevelData] = useState<DataType[]>([])
  const [levelName, setLevelName] = useState('')
  const [checkState, setCheckState] = useState('Premium_Agent')
  const [checkIndex, setCheckIndex] = useState<any>()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openUnConfirm, setOpenUnConfirm] = useState(false)
  const [HayaNum, setHayaNum] = useState('')
  const [level, setLevel] = useState('')
  const [AllowanceNum, setAllowanceNum] = useState('')

  const [levelAmounts, setLevelAmounts] = useState<LevelAmountType[]>([
    { id: 0, amounts: '' },
    { id: 1, amounts: '' },
    { id: 2, amounts: '' }
  ])

  useEffect(() => {

  }, [HayaNum])

  useEffect(() => {

  }, [level])


  const handleSwapClose = () => {
    setOpenConfirm(false)
  }

  const handleUnStakeClose = () => {
    setOpenUnConfirm(false)
  }

  const getUpgradeCost = async (currentLevel: number, newLevel: number) => {
    const StakesContract = new ethers.Contract(HayaStakingContract, levelAbi, provider)

    await StakesContract.calculateUpgradeCost(currentLevel, newLevel).then(async (res: any) => {

      setHayaNum(String(Math.round(Number(res) / (10 ** 18))))



    })
  }


  const getAllowance = async () => {
    const contract = new ethers.Contract(H30_Address, tokenAbi, provider)

    await contract.allowance(address, HayaStakingContract).then(async (res: any) => {

      setAllowanceNum(String(res))
    })
  }

  useEffect(() => {

  }, [AllowanceNum])

  const getLevelAmounts = async () => {
    const StakesContract = new ethers.Contract(HayaStakingContract, levelAbi, provider)

    const ids = [0, 1, 2]
    for (let i = 0; i < ids.length; i++) {
      await StakesContract.levelAmounts(ids[i]).then((res1: any) => {
        setLevelAmounts((pre) => pre.map((item) => item.id == ids[i] ? { ...item, amounts: String(Math.round(Number(res1) / (10 ** 18))) } : item))
      })
    }

  }





  const getStakes = async () => {
    const StakesContract = new ethers.Contract(HayaStakingContract, levelAbi, provider)

    await StakesContract.stakes(address).then(async (res: any) => {

      if (Number(res[2]) == 0) {
        setLevelName('Agent')
      } else if (Number(res[2]) == 1) {
        setLevelName('Partner')
      } else if (Number(res[2]) == 2) {
        setLevelName('Global Partner')
      }
      await StakesContract.levelAmounts(res[2]).then((res1: any) => {
        setLevelData((pre) => [{ stakeTime: String(res[0]), unlockTime: String(res[1]), level: Number(res[2]), levelAmounts: String(Number(res1) / (10 ** 18)) }])
      })



    })
  }

  useEffect(() => {
    console.log('leveldata', levelData)

  }, [levelData])

  useEffect(() => {

  }, [levelName])

  useEffect(() => {
    if (checkState == "Partner") {
      getUpgradeCost(Number(levelData[0]?.level), 1)
      setLevel('1')
    } else if (Number(levelData[0]?.level) !== 2 && checkState == "Premium_Agent") {
      getUpgradeCost(Number(levelData[0]?.level), 2)
      setLevel('2')
    }
  }, [checkState, levelData])


  useEffect(() => {
    getLevelAmounts()
    if (address !== undefined) {
      getStakes()
      getAllowance()

    }
  }, [address])



  const onUpgrade = () => {
    setOpenConfirm(true)

  }

  const onSelectState = (text: string) => {
    setCheckState(text)
    if (text == "Partner") {
      setCheckIndex(1)
    } else if (text == "Premium_Agent") {
      setCheckIndex(2)
    }
  }

  useEffect(() => {

  }, [checkIndex])

  const onUnstake = () => {
    setOpenUnConfirm(true)
  }
  const dispatch = useDispatch()

  const onChange = () => {
    if (address !== undefined) {
      getStakes()
      dispatch(getPartnerLevelData())
      getAllowance()

    }
  }

  const onUnChange = () => {
    handleClose()
  }

  const { getPartnerLevel } = useSelector((state) => state)
  const { PartnerLevelData } = getPartnerLevel
  return (
    <>
      <ConfirmUnStake open={openUnConfirm} handleSwapClose={handleUnStakeClose} HayaNum={levelData[0]?.levelAmounts} windowHeight={windowHeight} windowWidth={windowWidth} onChange={onUnChange} />
      <ConfirmStake open={openConfirm} handleSwapClose={handleSwapClose} HayaNum={HayaNum} windowWidth={windowWidth} level={level} windowHeight={windowHeight} onChange={onChange} allowanceNum={AllowanceNum} />
      {
        windowWidth >= 600 ? (
          <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p="0 10px" mb="20px">
              <Typography sx={{ color: "#000000", fontSize: '22px', lineHeight: '24px', fontWeight: 600 }}>
                Haya Partner Level
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: "#6f6f6f", width: '24px', height: '24px' }}

              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack alignItems="center" spacing="20px">
                <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '12px 20px', width: '100%' }}>
                  <Stack alignItems="start" spacing="10px">
                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                      My Haya Partner Level
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing="12px">
                      {
                        PartnerLevelData?.data?.partner_level == 0 ? (
                          <>
                            <img src={lowerIcon} />
                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '32px', lineHeight: '36px' }}  >
                              {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}

                              {/* {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''} */}
                              {/* {levelName} */}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <img src={agentIcon} />
                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '26px', lineHeight: '36px' }}  >
                              {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}

                              {/* {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''} */}

                            </Typography>
                          </>
                        )
                      }
                      {
                        PartnerLevelData?.data?.option_type == 3 ? (
                          <>
                            <Box sx={{ borderRadius: '20px', fontWeight: 600, padding: '4px 10px', color: '#f5b91f', backgroundColor: 'rgba(245, 185, 31, 0.2)', fontSize: '13px', lineHeight: '32px' }}>
                              {/* Temporary: {moment(Number(PartnerLevelData?.data?.expired_timestamp) * 1000).format('MM/DD/YYYY HH:mm:ss')} left */}
                              Temporary: {timeDifference(Number(PartnerLevelData?.data?.expired_timestamp) * 1000, Number(moment().unix()) * 1000)} left



                            </Box>
                          </>
                        ) : (
                          <></>
                        )
                      }
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        My Level
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {/* {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''} */}
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}

                        {/* {levelName == 'Agent' ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName} */}

                        {/* {levelName} */}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        My Staking $HAYA
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {levelData.length !== 0 ? ValueNumber(Number(levelData[0]?.levelAmounts)) : '0'} $HAYA
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Start Date
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {levelData[0]?.stakeTime !== '0' ? moment(Number(levelData[0]?.stakeTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : moment(Number(PartnerLevelData?.data?.update_timestamp) * 1000).format('MM/DD/YYYY HH:mm:ss')}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        $HAYA Unlock Time
                      </Typography>
                      {
                        levelData[0]?.level == 0 ? (
                          <>
                            <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                              {/* {levelData[0]?.unlockTime !== '0' ? moment(Number(levelData[0]?.unlockTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : `∞`} */}
                              NA
                            </Typography>

                          </>
                        ) : (
                          <>
                            {
                              Number(levelData[0]?.unlockTime) * 1000 <= Number(moment().valueOf()) ? (
                                <>
                                  <Stack direction="row" alignItems="center" spacing="8px">
                                    <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                      {levelData[0]?.unlockTime !== '0' ? moment(Number(levelData[0]?.unlockTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : `NA`}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#1AAE70', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                      Unlocked
                                    </Typography>
                                  </Stack>
                                </>

                              ) : (
                                <>
                                  <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                    {levelData[0]?.unlockTime !== '0' ? moment(Number(levelData[0]?.unlockTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : `NA`}
                                  </Typography>
                                </>
                              )

                            }
                          </>
                        )
                      }

                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" spacing="8px">
                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Privilege
                      </Typography>
                      <Box sx={{ flex: 1, backgroundColor: '#6F6F6F', height: '0.5px' }}></Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between" >
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Referrals Node Sales Commission
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? `Tier One: 0%; Tier Two: 0%` : PartnerLevelData?.data?.partner_level == 1 ? `Tier One: 2%; Tier Two: 1%` : PartnerLevelData?.data?.partner_level == 2 ? `Tier One: 3%; Tier Two: 1%` : '' : levelData[0]?.level == 0 ? `Tier One: 0%; Tier Two: 0%` : levelData[0]?.level == 1 ? `Tier One: 2%; Tier Two: 1%` : `Tier One: 3%; Tier Two: 1%`}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Referrals Mining Commission
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? `Tier One: 4%; Tier Two: 0%` : PartnerLevelData?.data?.partner_level == 1 ? `Tier One: 5%; Tier Two: 2%` : PartnerLevelData?.data?.partner_level == 2 ? `Tier One: 6%; Tier Two: 2%` : '' : levelData[0]?.level == 0 ? `Tier One: 4%` : levelData[0]?.level == 1 ? `Tier One: 5%; Tier Two: 2%` : `Tier One: 6%; Tier Two: 2%`}

                        {/* {levelData[0]?.level == 0 ? `Tier One: 4%` : levelData[0]?.level == 1 ? `Tier One: 5%; Tier Two: 2%` : `Tier One: 6%; Tier Two: 2%`} */}

                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Mining Pool Share
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level !== 2 ? `none` : `Share 3% of the global node reward pool.` : levelData[0]?.level !== 2 ? `none` : `Share 3% of the global node reward pool.`}
                        {/* {levelData[0]?.level !== 2 ? `none` : `Share 3% of the global node reward pool.`} */}
                      </Typography>
                    </Stack>

                  </Stack>
                </Box>
                <Stack direction="row" alignItems="center" spacing="20px" padding="0 10px">
                  <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '22px' }}  >
                    Become HAYA {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''}, enjoy both long-term and short term rewards!
                  </Typography>
                  <img src={CooperativeHandshakeIcon} />

                </Stack>

                <Stack alignItems="center" spacing="10px" width="100%">
                  {
                    levelData[0]?.level == 0 ? (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" width="100%">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                  Agent
                                </Typography>

                              </Stack>
                              <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', color: '#1aae70', padding: '10px 30px', borderRadius: '20px', fontSize: '14px', lineHeight: '32px' }}>
                                Your Level
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Tier One: 0%; Tier Two: 0%
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 4%; Tier Two: 0%
                              </Typography>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                None
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>
                            </Stack>


                          </Stack>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" width="100%">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                  Agent
                                </Typography>

                              </Stack>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Tier One: 0%; Tier Two: 0%
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 4%; Tier Two: 0%
                              </Typography>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                None
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>
                            </Stack>


                          </Stack>
                        </Box>
                      </>
                    )
                  }

                  {
                    levelData[0]?.level == 1 ? (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                  Partner
                                </Typography>

                              </Stack>
                              <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', color: '#1aae70', padding: '10px 30px', borderRadius: '20px', fontSize: '14px', lineHeight: '32px' }}>
                                Your Level
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 2%; Tier Two: 1%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 5%; Tier Two: 2%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Stake 2,000 $HAYA for 1 year
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>
                            </Stack>


                          </Stack>
                        </Box>
                      </>
                    ) : (
                      <>
                        {
                          levelData[0]?.level == 0 ? (
                            <>
                              <Box component="button" onClick={() => onSelectState('Partner')} sx={{ cursor: 'pointer', backgroundColor: checkIndex == 1 ? '#E8F7F1' : '#F6F6F6', border: checkIndex == 1 ? '2px solid #1aae70' : 0, borderRadius: '20px', padding: '20px 20px', width: '100%' }}  >
                                <Stack alignItems="start" spacing="10px" >
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Stack direction="row" alignItems="center" spacing="12px">
                                      <img src={crownStateIcon} />
                                      <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                        Partner
                                      </Typography>

                                    </Stack>
                                    <Box onClick={() => onSelectState('Partner')} sx={{ backgroundColor: checkIndex == 1 ? '#1AAE70' : '#000', color: '#FFFFFF', padding: '10px 30px', borderRadius: '20px', fontSize: '14px', lineHeight: '32px', cursor: 'pointer', border: 0 }} component="button" >
                                      Stake {formatWithK(Number(levelAmounts.filter((item) => item.id == 1)[0].amounts))} $HAYA
                                    </Box>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Node Sales Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 2%; Tier Two: 1%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Mining Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 5%; Tier Two: 2%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Require
                                    </Typography>
                                    <Typography variant="body1" sx={{ textAlign: 'start', paddingLeft: '175px', color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                      Stake 2,000 $HAYA for 1 year
                                      {/* <br /> */}
                                      {/* Option 2: Stake a Haya Bronze Miner (100U) + Your friends achieve a sales volume of 500U
                                      <br />
                                      Achieving any of the above options will automatically upgrade your account to Partner. */}
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Mining Pool Share
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      None
                                    </Typography>
                                  </Stack>


                                </Stack>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px', width: '100%' }}  >
                                <Stack alignItems="start" spacing="10px" >
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Stack direction="row" alignItems="center" spacing="12px">
                                      <img src={crownStateIcon} />
                                      <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                        Partner
                                      </Typography>

                                    </Stack>


                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Node Sales Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 2%; Tier Two: 1%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Mining Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 5%; Tier Two: 2%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Require
                                    </Typography>
                                    <Typography variant="body1" sx={{ textAlign: 'start', paddingLeft: '175px', color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', }}  >
                                      Stake 2,000 $HAYA for 1 year
                                      {/* <br /> */}
                                      {/* Option 2: Stake a Haya Bronze Miner (100U) + Your friends achieve a sales volume of 500U
                                      <br />
                                      Achieving any of the above options will automatically upgrade your account to Partner. */}
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Mining Pool Share
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      None
                                    </Typography>
                                  </Stack>


                                </Stack>
                              </Box>
                            </>
                          )
                        }
                      </>
                    )
                  }
                  {
                    levelData[0]?.level == 2 ? (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 20px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                  Global Partner
                                </Typography>

                              </Stack>
                              <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', color: '#1aae70', padding: '10px 30px', borderRadius: '20px', fontSize: '14px', lineHeight: '32px' }}>
                                Your Level
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 3%; Tier Two: 1%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 6%; Tier Two: 2%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Stake 2,000 $HAYA for 1 year
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', textAlign: 'end' }}  >
                                Share 3% of the global node reward pool.
                              </Typography>
                            </Stack>




                          </Stack>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box component="button" onClick={() => onSelectState('Premium_Agent')} sx={{ cursor: 'pointer', backgroundColor: checkIndex == 2 ? '#E8F7F1' : '#F6F6F6', border: checkIndex == 2 ? '2px solid #1aae70' : 0, borderRadius: '20px', padding: '20px 20px', width: '100%' }}>
                          <Stack alignItems="start" spacing="10px" >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '22px', lineHeight: '36px' }}  >
                                  Global Partner
                                </Typography>

                              </Stack>
                              <Box onClick={() => onSelectState('Premium_Agent')} sx={{ backgroundColor: checkIndex == 2 ? '#1AAE70' : '#000', color: '#FFFFFF', padding: '10px 30px', borderRadius: '20px', fontSize: '14px', lineHeight: '32px', cursor: 'pointer', border: 0 }} component="button">
                                Stake {formatWithK(Number(levelAmounts.filter((item) => item.id == 2)[0].amounts))} $HAYA
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 3%; Tier Two: 1%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 6%; Tier Two: 2%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start', paddingLeft: '175px' }}  >
                                Stake 100,000 $HAYA for 1 year.
                                <br />
                                {/* Option 2: Stake a Haya Gold Miner (10,000U) + Your friends achieve a sales volume of 50,000U
                                <br />
                                Achieving any of the above options will automatically upgrade your account to Global  Partner. */}
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', textAlign: 'end' }}  >
                                Share 3% of the global node reward pool.
                              </Typography>
                            </Stack>



                          </Stack>
                        </Box>
                      </>
                    )
                  }
                </Stack>
                <Stack direction="row" alignItems="center" spacing="8px" width="100%">
                  <img src={InfoIcon} />
                  <Typography variant="body1" sx={{ color: '#6F6F6F', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                    Tip: Haya Global Partners also enjoy sharing 3% of the global node reward pool. Haya Global Partners participate in the distribution based on the proportion of sales points under their account relative to the total sales points in the network. The sales points are determined by the sales volume of users under their account.
                  </Typography>



                </Stack>
                {
                  levelData[0]?.level == 0 ? (
                    <>
                      <Box sx={{ height: '80px' }}>
                        <UpgradeButton sx={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px' }} onClick={onUpgrade}>{checkIndex == 1 ? `Upgrade to Partner` : checkIndex == 2 ? `Upgrade to Global Partner` : `Upgrade`}</UpgradeButton>
                      </Box>


                    </>
                  ) : (
                    <>
                      {
                        levelData[0]?.level == 2 ? (
                          <>
                            {
                              Number(levelData[0]?.unlockTime) * 1000 <= Number(moment().valueOf()) ? (
                                <>
                                  <Box sx={{ height: '80px' }}>
                                    <UnstakeButton sx={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px' }} onClick={onUnstake}>Unstake</UnstakeButton>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box sx={{ height: '80px' }}>
                                    <UnstakeButton sx={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px' }} disabled>Unstake</UnstakeButton>
                                  </Box>
                                </>
                              )
                            }
                          </>
                        ) : (
                          <>
                            <Box height="160px">
                              <Stack alignItems="center" spacing="10px" width="100%">
                                <UpgradeButton sx={{ position: 'absolute', bottom: '110px', left: '20px', right: '20px' }} onClick={onUpgrade}>Upgrade</UpgradeButton>
                                {
                                  Number(levelData[0]?.unlockTime) * 1000 <= Number(moment().valueOf()) ? (
                                    <>
                                      <UnstakeButton sx={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px' }} onClick={onUnstake}>Unstake</UnstakeButton>
                                    </>
                                  ) : (
                                    <>
                                      <UnstakeButton sx={{ position: 'absolute', bottom: '20px', left: '20px', right: '20px' }} disabled>Unstake</UnstakeButton>
                                    </>
                                  )
                                }


                              </Stack>
                            </Box>
                          </>
                        )
                      }
                    </>
                  )
                }


              </Stack>

            </DialogContent>
          </BootstrapDialog>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0' } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" p="0 10px" mb="10px">
                <Typography sx={{ color: "#000000", fontSize: '18px', fontWeight: 600 }}>
                  Haya Partner Level
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{ color: "#9b9b9b" }}

                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack alignItems="center" spacing="20px">
                <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '12px 20px', width: '100%' }}>
                  <Stack alignItems="start" spacing="10px">
                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                      My Haya Partner Level
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing="12px">
                      {
                        PartnerLevelData?.data?.partner_level == 0 ? (
                          <>
                            <img src={lowerIcon} />
                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '32px', lineHeight: '36px' }}  >
                              {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <img src={agentIcon} />
                            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '32px', lineHeight: '36px' }}  >
                              {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}
                            </Typography>
                          </>
                        )
                      }

                    </Stack>
                    {
                      PartnerLevelData?.data?.option_type == 3 ? (
                        <>
                          <Box sx={{ borderRadius: '20px', fontWeight: 600, padding: '4px 10px', color: '#f5b91f', backgroundColor: 'rgba(245, 185, 31, 0.2)', fontSize: '14px', lineHeight: '32px' }}>
                            Temporary: {timeDifference(Number(PartnerLevelData?.data?.expired_timestamp) * 1000, Number(moment().unix()) * 1000)} left

                          </Box>
                        </>
                      ) : (
                        <></>
                      )
                    }
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        My Level
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : '' : levelName}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        My Staking $HAYA
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {levelData.length !== 0 ? ValueNumber(Number(levelData[0]?.levelAmounts)) : '0'} $HAYA
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Start Date
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {levelData[0]?.stakeTime !== '0' ? moment(Number(levelData[0]?.stakeTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : moment(Number(PartnerLevelData?.data?.update_timestamp) * 1000).format('MM/DD/YYYY HH:mm:ss')}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        $HAYA Unlock Time
                      </Typography>
                      {
                        levelData[0]?.level == 0 ? (
                          <>
                            <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                              {/* {levelData[0]?.unlockTime !== '0' ? moment(Number(levelData[0]?.unlockTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : `∞`} */}
                              NA
                            </Typography>

                          </>
                        ) : (
                          <>
                            {
                              Number(levelData[0]?.unlockTime) * 1000 <= Number(moment().valueOf()) ? (
                                <>
                                  <Stack direction="row" alignItems="center" spacing="8px">
                                    <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                      {levelData[0]?.unlockTime !== '0' ? moment(Number(levelData[0]?.unlockTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : `NA`}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#1AAE70', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                      Unlocked
                                    </Typography>
                                  </Stack>
                                </>

                              ) : (
                                <>
                                  <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                    {levelData[0]?.unlockTime !== '0' ? moment(Number(levelData[0]?.unlockTime) * 1000).format('MM/DD/YYYY HH:mm:ss') : `NA`}
                                  </Typography>
                                </>
                              )

                            }
                          </>
                        )
                      }

                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" spacing="8px">
                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Privilege
                      </Typography>
                      <Box sx={{ flex: 1, backgroundColor: '#6F6F6F', height: '0.5px' }}></Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between" display={levelData[0]?.level == 0 ? 'none' : 'flex'}>
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Referrals Node Sales Commission
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? `Tier One: 0%; Tier Two: 0%` : PartnerLevelData?.data?.partner_level == 1 ? `Tier One: 2%; Tier Two: 1%` : PartnerLevelData?.data?.partner_level == 2 ? `Tier One: 3%; Tier Two: 1%` : '' : levelData[0]?.level == 0 ? `Tier One: 0%; Tier Two: 0%` : levelData[0]?.level == 1 ? `Tier One: 2%; Tier Two: 1%` : `Tier One: 3%; Tier Two: 1%`}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Referrals Mining Commission
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level == 0 ? `Tier One: 4%; Tier Two: 0%` : PartnerLevelData?.data?.partner_level == 1 ? `Tier One: 5%; Tier Two: 2%` : PartnerLevelData?.data?.partner_level == 2 ? `Tier One: 6%; Tier Two: 2%` : '' : levelData[0]?.level == 0 ? `Tier One: 4%` : levelData[0]?.level == 1 ? `Tier One: 5%; Tier Two: 2%` : `Tier One: 6%; Tier Two: 2%`}

                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                      <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '18px' }}  >
                        Mining Pool Share
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#FFFFFF', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                        {PartnerLevelData?.data?.option_type == 3 ? PartnerLevelData?.data?.partner_level !== 2 ? `none` : `Share 3% of the global node reward pool.` : levelData[0]?.level !== 2 ? `none` : `Share 3% of the global node reward pool.`}
                      </Typography>
                    </Stack>

                  </Stack>
                </Box>
                <Stack direction="row" alignItems="center" spacing="20px" padding="0 10px">
                  <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '22px' }}  >
                    Become HAYA {PartnerLevelData?.data?.partner_level == 0 ? 'Agent' : PartnerLevelData?.data?.partner_level == 1 ? 'Partner' : PartnerLevelData?.data?.partner_level == 2 ? 'Global Partner' : ''}, enjoy both long-term and short term rewards!
                  </Typography>
                  <img src={CooperativeHandshakeIcon} />

                </Stack>

                <Stack alignItems="center" spacing="10px" width="100%">
                  {
                    levelData[0]?.level == 0 ? (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 10px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" width="100%">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                  Agent
                                </Typography>

                              </Stack>
                              <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', color: '#1aae70', padding: '10px 14px', borderRadius: '20px', fontSize: '12px', lineHeight: '24px' }}>
                                Your Level
                              </Box>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Tier One: 0%; Tier Two: 0%
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 4%; Tier Two: 0%
                              </Typography>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                None
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>
                            </Stack>


                          </Stack>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 10px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" width="100%">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                  Agent
                                </Typography>

                              </Stack>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Tier One: 0%; Tier Two: 0%
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 4%; Tier Two: 0%
                              </Typography>

                            </Stack>
                            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                                None
                              </Typography>

                            </Stack> */}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>
                            </Stack>


                          </Stack>
                        </Box>
                      </>
                    )
                  }

                  {
                    levelData[0]?.level == 1 ? (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 10px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                  Partner
                                </Typography>

                              </Stack>
                              <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', color: '#1aae70', padding: '10px 14px', borderRadius: '20px', fontSize: '12px', lineHeight: '24px' }}>
                                Your Level
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 2%; Tier Two: 1%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 5%; Tier Two: 2%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Stake 2,000 $HAYA for 1 year



                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                None
                              </Typography>
                            </Stack>


                          </Stack>
                        </Box>
                      </>
                    ) : (
                      <>
                        {
                          levelData[0]?.level == 0 ? (
                            <>
                              <Box component="button" onClick={() => onSelectState('Partner')} sx={{ cursor: 'pointer', backgroundColor: checkIndex == 1 ? '#E8F7F1' : '#F6F6F6', border: checkIndex == 1 ? '2px solid #1aae70' : 0, borderRadius: '20px', padding: '20px 20px', width: '100%' }}  >
                                <Stack alignItems="start" spacing="10px" >
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Stack direction="row" alignItems="center" spacing="12px">
                                      <img src={crownStateIcon} />
                                      <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                        Partner
                                      </Typography>

                                    </Stack>
                                    <Box onClick={() => onSelectState('Partner')} sx={{ backgroundColor: checkIndex == 1 ? '#1AAE70' : '#000', color: '#FFFFFF', padding: '10px 14px', borderRadius: '20px', fontSize: '12px', lineHeight: '24px', cursor: 'pointer', border: 0 }} component="button" >
                                      Stake {formatWithK(Number(levelAmounts.filter((item) => item.id == 1)[0].amounts))} $HAYA
                                    </Box>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Node Sales Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 2%; Tier Two: 1%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Mining Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 5%; Tier Two: 2%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Require
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Stake 2,000 $HAYA for 1 year
                                      {/* Option 2: Stake a Haya Bronze Miner (100U) + Your friends achieve a sales volume of 500U
                                      Achieving any of the above options will automatically upgrade your account to Partner. */}
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Mining Pool Share
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      None
                                    </Typography>
                                  </Stack>


                                </Stack>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 10px', width: '100%' }}  >
                                <Stack alignItems="start" spacing="10px" >
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Stack direction="row" alignItems="center" spacing="12px">
                                      <img src={crownStateIcon} />
                                      <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                        Partner
                                      </Typography>

                                    </Stack>


                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Node Sales Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 2%; Tier Two: 1%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Referrals Mining Commission
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Tier One: 5%; Tier Two: 2%
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Require
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      Stake 2,000 $HAYA for 1 year
                                      {/* Option 2: Stake a Haya Bronze Miner (100U) + Your friends achieve a sales volume of 500U
                                      Achieving any of the above options will automatically upgrade your account to Partner. */}
                                    </Typography>

                                  </Stack>
                                  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                      Mining Pool Share
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                      None
                                    </Typography>
                                  </Stack>


                                </Stack>
                              </Box>
                            </>
                          )
                        }
                      </>
                    )
                  }
                  {
                    levelData[0]?.level == 2 ? (
                      <>
                        <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '20px 10px', width: '100%' }} >
                          <Stack alignItems="start" spacing="10px" >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                  Global Partner
                                </Typography>

                              </Stack>
                              <Box sx={{ backgroundColor: 'rgba(26, 174, 112, 0.1)', color: '#1aae70', padding: '10px 14px', borderRadius: '20px', fontSize: '12px', lineHeight: '24px' }}>
                                Your Level
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 3%; Tier Two: 1%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 6%; Tier Two: 2%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Stake 2,000 $HAYA for 1 year

                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', textAlign: 'end' }}  >
                                Share 3% of the global node reward pool.
                              </Typography>
                            </Stack>




                          </Stack>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box component="button" onClick={() => onSelectState('Premium_Agent')} sx={{ cursor: 'pointer', backgroundColor: checkIndex == 2 ? '#E8F7F1' : '#F6F6F6', border: checkIndex == 2 ? '2px solid #1aae70' : 0, borderRadius: '20px', padding: '20px 20px', width: '100%' }}>
                          <Stack alignItems="start" spacing="10px" >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Stack direction="row" alignItems="center" spacing="12px">
                                <img src={crownStateIcon} />
                                <Typography variant="body1" sx={{ color: '#000', fontWeight: 700, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '36px' }}  >
                                  Global Partner
                                </Typography>

                              </Stack>
                              <Box onClick={() => onSelectState('Premium_Agent')} sx={{ backgroundColor: checkIndex == 2 ? '#1AAE70' : '#000', color: '#FFFFFF', padding: '10px 14px', borderRadius: '20px', fontSize: '12px', lineHeight: '24px', cursor: 'pointer', border: 0 }} component="button">
                                Stake {formatWithK(Number(levelAmounts.filter((item) => item.id == 2)[0].amounts))} $HAYA
                              </Box>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Node Sales Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 3%; Tier Two: 1%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Referrals Mining Commission
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Tier One: 6%; Tier Two: 2%
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Require
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'end' }}  >
                                Stake 100,000 $HAYA for 1 year
                                {/* Option 2: Stake a Haya Gold Miner (10,000U) + Your friends achieve a sales volume of 50,000U
                                Achieving any of the above options will automatically upgrade your account to Global  Partner. */}
                              </Typography>

                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                              <Typography variant="body1" sx={{ color: '#9B9B9B', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px', textAlign: 'start' }}  >
                                Mining Pool Share
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#464646', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', textAlign: 'end' }}  >
                                Share 3% of the global node reward pool.
                              </Typography>
                            </Stack>



                          </Stack>
                        </Box>
                      </>
                    )
                  }
                </Stack>
                <Stack direction="row" alignItems="center" spacing="8px" width="100%">
                  <img src={InfoIcon} />
                  <Typography variant="body1" sx={{ color: '#6F6F6F', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '22px' }}  >
                    Tip: Haya Global Partners also enjoy sharing 3% of the global node reward pool. Haya Global Partners participate in the distribution based on the proportion of sales points under their account relative to the total sales points in the network. The sales points are determined by the sales volume of users under their account.
                  </Typography>



                </Stack>
                {
                  levelData[0]?.level == 0 ? (
                    <>
                      <Box sx={{ height: '70px' }}>
                        <UpgradeButton sx={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px' }} onClick={onUpgrade}>{checkIndex == 1 ? `Upgrade to Partner` : checkIndex == 2 ? `Upgrade to Global Partner` : `Upgrade`}</UpgradeButton>
                      </Box>
                    </>
                  ) : (
                    <>
                      {
                        levelData[0]?.level == 2 ? (
                          <>
                            {
                              Number(levelData[0]?.unlockTime) * 1000 <= Number(moment().valueOf()) ? (
                                <>
                                  <Box sx={{ height: '70px' }}>
                                    <UnstakeButton sx={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px' }} onClick={onUnstake}>Unstake</UnstakeButton>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box sx={{ height: '70px' }}>
                                    <UnstakeButton sx={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px' }} disabled>Unstake</UnstakeButton>
                                  </Box>
                                </>
                              )
                            }
                          </>
                        ) : (
                          <>
                            <Box sx={{ height: '160px' }}>
                              <Stack alignItems="center" spacing="10px" width="100%">
                                <UpgradeButton sx={{ position: 'fixed', bottom: '110px', left: '20px', right: '20px' }} onClick={onUpgrade}>Upgrade</UpgradeButton>
                                {
                                  Number(levelData[0]?.unlockTime) * 1000 <= Number(moment().valueOf()) ? (
                                    <>
                                      <UnstakeButton sx={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px' }} onClick={onUnstake}>Unstake</UnstakeButton>
                                    </>
                                  ) : (
                                    <>
                                      <UnstakeButton sx={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px' }} disabled>Unstake</UnstakeButton>
                                    </>
                                  )
                                }


                              </Stack>
                            </Box>
                          </>
                        )
                      }
                    </>
                  )
                }


              </Stack>
            </Box>
          </Drawer>


        )
      }

    </>
  )
}

export default UpdatePage
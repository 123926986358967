
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Drawer, Stack } from '@mui/material';
import TokenColorIcon from 'assets/tokens';
import { ButtonProps } from '@mui/material/Button';
// import Web3 from 'web3';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers'
import { H30_Address, HayaStakingContract } from 'config';
import InfoIcon from '@mui/icons-material/Info';
import { FaCheck } from "react-icons/fa";
import WarningIcon from '@mui/icons-material/Warning';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import { LoadingButton } from '@mui/lab';
import { getEthersSigner } from 'contract/getEthersSigner';
import { config } from 'contexts/wagmiConfig';
import tokenAbi from 'abi/token.json';
import Confirm from '../TransactionStatus/confirm';
import Succeed from '../TransactionStatus/succeed';
import levelAbi from 'abi/level.json';
import StakeLevelUp from '../levelUp/stakeLevelUp';


// const sepolia_rpc = "https://sepolia.infura.io/v3/0edd253962184b628e0cfabc2f91b0ae"















type TypeProps = {
  open: boolean;
  handleApprovalClose: () => void;
  allowanceNum: string;
  windowWidth: number;
  windowHeight: number;
  HayaNum: string;
  level: string;
  onUpdate: () => void;
}



const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  padding: '12px 0',
  boxShadow: 'none',
  fontSize: '18px',
  fontWeight: 600,
  borderRadius: '20px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
}));



export default function StakeApprovalTokens({ open, handleApprovalClose, windowWidth, windowHeight, onUpdate, allowanceNum, HayaNum, level }: TypeProps) {

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));


  const ApprovalButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
    backgroundColor: '#1AAE70',
    borderRadius: '20px',
    color: '#fff',
    boxShadow: 'none',
    fontSize: windowWidth >= 600 ? '16px' : '14px',
    lineHeight: '14px',
    padding: windowWidth >= 600 ? '15px 30px' : '10px 20px',
    ".MuiLoadingButton-loadingIndicator": {
      color: '#fff'

    },
    "&.MuiLoadingButton-loading": {
      opacity: 'inherit',
      zIndex: 100,
      backgroundColor: '#1AAE70',

    },
    '&:hover': {
      backgroundColor: "#19A56A",
      color: '#fff',
    },
  }));

  const notificonfig = {
    top: windowHeight * 0.4,

  }



  const [openSucced, setOpenSucced] = useState(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openLevelUp, setOpenLevelUp] = useState<boolean>(false)


  const handleConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSucceedClose = () => {
    setOpenSucced(false)
    setOpenLevelUp(true)
  }

  const handleLevelUpClose = () => {
    setOpenLevelUp(false)
  }

  useEffect(() => {

  }, [openLevelUp])

  useEffect(() => {

  }, [openConfirm])

  useEffect(() => {

  }, [openSucced])


  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }} color="#000">
                  {errValue}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack padding="10px 0" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }} color="#000">
                  {errValue}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      closeIcon: windowWidth >= 600 ? true : false,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '400px' : '160px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,

    });
  };






  const [disable, setDisabled] = useState<boolean>(true)

  const [approval, setApproval] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)






  // const provider = new ethers.BrowserProvider(window.ethereum)


  const [errValue, setErrValue] = useState('The transaction submission was either cancelled or failed.')


  useEffect(() => {

  }, [errValue])


  const SwapButton = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: windowWidth >= 600 ? '18px 0' : '15px 0',
    borderRadius: '20px',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '20px',
    width: '100%',
    backgroundColor: disable ? '#9B9B9B' : '#1AAE70',
    color: '#fff',
    boxShadow: 'none',
    "&.Mui-disabled": {
      color: '#fff',

    },
    '&:hover': {
      backgroundColor: '#19A56A',
      color: '#fff',
    },
  }));


  const [hash, setHash] = useState('')

  useEffect(() => {

  }, [hash])



  const handleDone = async () => {
    // const signer = await provider.getSigner()
    const provider = getEthersSigner(config)
    // Math.floor(Number(Number(inputToNum) / Number(inputFromNum)) * Number(1 + (Number(slippage) / 100)) * (10 ** 18))

    const StakeContract = new ethers.Contract(HayaStakingContract, levelAbi, await provider)
    setOpenConfirm(true)
    handleApprovalClose()

    await StakeContract.upgradeLevel(level).then(async (res) => {

      const res1 = await res.wait()
      if (res1.blockNumber == null) {
      } else {
        setHash(String(res1.hash))
        setOpenConfirm(false)
        setOpenSucced(true)

      }


    }).catch((err) => {
      // console.log("错误结果", err)
      openNotification('top')
      handleApprovalClose()
    })

    // BigInt(Number(inputToNum) * (10 ** 18))

    // console.log(new Date().getTime() + 10000)




  }

  const OnApproval = async () => {

    const provider = getEthersSigner(config)
    const ApproveContract = new ethers.Contract(H30_Address, tokenAbi, await provider)

    setLoading(true)

    await ApproveContract.approve(HayaStakingContract, ethers.MaxUint256).then(async (res) => {





      const res1 = await res.wait()

      if (res1.blockNumber == null) {
      } else {

        setLoading(false)

        setApproval(true)

      }

    }).catch((err) => {
      setErrValue(err.info["error"]['message'])
      openNotification('top')
      handleApprovalClose()
      setLoading(false)

    })












  }



  useEffect(() => {

  }, [loading])


  useEffect(() => {
    if (approval) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

  }, [approval, disable])














  return (
    <>
      {contextHolder}
      <StakeLevelUp windowWidth={windowWidth} open={openLevelUp} handleConfirmClose={handleLevelUpClose} HAYANum={HayaNum} onChange={onUpdate} />
      <Confirm windowWidth={windowWidth} open={openConfirm} handleConfirmClose={handleConfirmClose} HayaNum={HayaNum} address={HayaStakingContract} />
      <Succeed hash={hash} windowWidth={windowWidth} open={openSucced} handleConfirmClose={handleSucceedClose} HAYANum={HayaNum} address={HayaStakingContract} />

      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleApprovalClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >

              <Stack direction="row" justifyContent="space-between" alignItems="center" padding="0 10px" mb="20px">
                <Typography sx={{ color: "#000", fontSize: '20px', fontWeight: 700 }}>
                  Approve Your Tokens
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleApprovalClose}
                  sx={{ color: "#9b9b9b" }}

                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 20px', mb: '20px' }}>

                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: '12px' }}>
                    <Stack direction="row" spacing="12px" alignItems="center" >
                      <TokenColorIcon name="HAYA" size={36} />
                      <Typography sx={{ color: "#000", fontSize: '20px', fontWeight: 600 }}>
                        {HayaNum} HAYA

                      </Typography>

                    </Stack>
                    {
                      !approval ? (
                        <ApprovalButton loading={loading} onClick={() => OnApproval()}>Approval</ApprovalButton>

                      ) : (
                        <Stack direction="row" spacing="4px" alignItems="center" >
                          <Typography sx={{ color: "#000", fontSize: '16px', fontWeight: 600 }}>
                            Approved
                          </Typography>
                          <FaCheck color='#1AAE70' size={22} />
                        </Stack>


                      )
                    }

                  </Stack>




                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%">
                  <Stack direction="row" spacing="8px" pb="20px" pt='12px' alignItems="center">
                    <InfoIcon sx={{ color: '#6f6f6f', width: '22px', height: '22px' }} />
                    <Typography sx={{ color: '#6f6f6f', fontSize: '15px', fontWeight: 600, lineHeight: '20px' }}>
                      Tip: Approve your tokens before use. Each Token requires a separate one-time approval.
                    </Typography>

                  </Stack>
                  <SwapButton disabled={disable} onClick={handleDone} >
                    Done
                  </SwapButton>
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleApprovalClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0' } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack direction="row" justifyContent="space-between" alignItems="center" p="0 10px" mb="10px">
                  <Typography sx={{ color: "#000", fontSize: '18px', fontWeight: 600 }}>
                    Approve Your Tokens
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleApprovalClose}
                    sx={{ color: "#6f6f6f" }}

                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 10px', mb: '10px' }}>

                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: '12px' }}>
                    <Stack direction="row" spacing="12px" alignItems="center" >
                      <TokenColorIcon name="HAYA" size={36} />
                      <Typography sx={{ color: "#000", fontSize: '20px', fontWeight: 600 }}>
                        {HayaNum} HAYA

                      </Typography>

                    </Stack>
                    {
                      !approval ? (
                        <ApprovalButton loading={loading} onClick={() => OnApproval()}>Approval</ApprovalButton>

                      ) : (
                        <Stack direction="row" spacing="4px" alignItems="center" >
                          <Typography sx={{ color: "#000", fontSize: '16px', fontWeight: 600 }}>
                            Approved
                          </Typography>
                          <FaCheck color='#1AAE70' size={22} />
                        </Stack>


                      )
                    }

                  </Stack>




                </Box>
                <Stack width="100%">
                  <Stack direction="row" spacing="8px" pb="10px" pt='12px' alignItems="center">
                    <InfoIcon sx={{ color: '#6f6f6f', width: '22px', height: '22px' }} />
                    <Typography sx={{ color: '#6f6f6f', fontSize: '15px', fontWeight: 600, lineHeight: '20px' }}>
                      Tip: Approve your tokens before use. Each Token requires a separate one-time approval.
                    </Typography>

                  </Stack>
                  <SwapButton disabled={disable} onClick={handleDone} >
                    Done
                  </SwapButton>
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }
    </>
  );
}
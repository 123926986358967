import { Box, Button, ButtonProps, Dialog, Drawer, IconButton, InputBase, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CheckIcon from 'assets/icons/Check.svg'
import { useAccount } from "wagmi";
import { useEffect, useState } from "react";
import { MinerContract, MinerFreeContract } from "config";
import minerAbi from 'abi/miner.json'
import { getEthersSigner } from "contract/getEthersSigner";
import { config } from "contexts/wagmiConfig";
import { ethers } from "ethers";
import { stringToBytes } from "viem";
import Confirm from "./sendTrantion/confirm";
import Succeed from "./sendTrantion/succeed";
import minerFeerAbi from 'abi/minFeer.json'
import WarningIcon from '@mui/icons-material/Warning';
import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";


type TypeProps = {
  windowWidth: Number;
  open: boolean;
  handleConfirmClose: () => void;
  id: number;
  onChange: () => void
  NFTName: string
  num: string
  url: string
  token_id: number
  windowHeight: number

}

const OkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  width: '100%',
  color: '#fff',
  boxShadow: 'none',
  borderRadius: '10px',
  backgroundColor: '#1AAE70',
  '&:hover': {
    backgroundColor: '#1AAE70',
    color: '#fff',
  },
  '&:after': {
    boxShadow: 'none'
  },
}));









const SendMiner = ({ windowHeight, windowWidth, handleConfirmClose, open, token_id, onChange, id, num, NFTName, url }: TypeProps) => {



  const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '.MuiDialog-paper': {
      width: '600px',
      borderRadius: '20px',
      padding: '20px 20px'
    },

    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .MuiDialogActions-root': {
      padding: 0,
    },
    '& .customized-dialog-title': {
      borderBottom: 0
    }
  }));

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    flex: 1,

    '& .MuiInputBase-input': {
      backgroundColor: 'transparent',
      fontSize: '16px',
      lineHeight: '36px',
      height: 'auto',
      border: 'none',
      fontWeight: 700,
      width: '99%',
      textAlign: 'start',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: 'none',
        border: 0
      },
    },
  }));

  const { address } = useAccount()

  const [color, setColor] = useState('#000')
  const [disable, setDisable] = useState(true)

  const ViewDetailButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#fff',
    backgroundColor: disable ? '#9B9B9B' : '#1AAE70',
    boxShadow: 'none',
    "&.Mui-disabled": {
      color: '#fff',

    },
    padding: '20px 40px',
    fontSize: '24px',
    fontWeight: 600,
    width: '100%',
    lineHeight: '32px',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#19A56A',
      color: '#fff',
    },
    '&:after': {
      boxShadow: 'none'
    },
  }));

  const [inputValue, setInputValue] = useState('')

  const notificonfig = {
    top: windowHeight * 0.4,

  }



  const [api, contextHolder] = notification.useNotification(notificonfig);

  const openNotification = (placement: NotificationPlacement, err: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Box sx={{ marginTop: '-8px' }}>
        {
          windowWidth >= 600 ? (
            <>
              <Stack width="100%" alignItems="center" textAlign="center" padding="20px 0" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '24px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
              <OkButton onClick={() => api.destroy()}>
                OK
              </OkButton>
            </>
          ) : (
            <>
              <Stack direction="row" width="100%" alignItems="center" textAlign="center" spacing="10px">
                <WarningIcon style={{ color: '#9b9b9b', width: '24px', height: '24px' }} fontSize="large" />
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }} color="#000">
                  {err}
                </Typography>
              </Stack>
            </>
          )
        }
      </Box>
    );

    const mess = (
      <Box sx={{ m: 0, p: 0, '& .ant-notification-notice': { "& .ant-notification-notice-message": { mb: 0 } } }}></Box>
    )



    api.open({
      message: mess,
      description: btn,
      closeIcon: windowWidth >= 600 ? true : false,
      className: 'custom-class',
      style: {
        width: windowWidth >= 600 ? '400px' : '160px',
        padding: '20px 20px',
        borderRadius: '20px'
      },
      key,
      placement,

    });
  };

  const InputChange = (event: any) => {
    setInputValue(event.target.value)
    const value = event.target.value;
    if (/^0x[\da-fA-F]{40}$/.test(value)) {
      setDisable(false)
      setColor('#000')
    } else {
      if (value !== '') {
        setColor('#EE3354')
        setDisable(true)

      }
    }

  }


  // const onBlur = (event: any) => {
  //   const value = event.target.value;
  //   if (/^0x[\da-fA-F]{40}$/.test(value)) {
  //     setDisable(false)
  //     setColor('#000')
  //   } else {
  //     if (value !== '') {
  //       setColor('#EE3354')
  //       setDisable(true)

  //     }
  //   }

  // }

  useEffect(() => {

  }, [color])

  useEffect(() => {

  }, [disable])

  const [hash, setHash] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSucced, setOpenSucced] = useState(false)




  const goViewDetail = async () => {
    if (id == 6) {
      const provider = getEthersSigner(config)

      const contract = new ethers.Contract(MinerFreeContract, minerFeerAbi, await provider)

      setOpenConfirm(true)
      handleConfirmClose()

      await contract.safeTransferFrom(address, inputValue, token_id, num, stringToBytes('')).then(async (res1) => {
        const res2 = await res1.wait()
        if (res2.blockNumber == null) {
        } else {
          setHash(String(res2.hash))
          setOpenConfirm(false)
          setOpenSucced(true)

        }

      }).catch((err) => {
        openNotification('top', `feerSend ---- ${err}`)
        handleConfirmClose()
        setOpenConfirm(false)
      })
    } else {
      const provider = getEthersSigner(config)

      const contract = new ethers.Contract(MinerContract, minerAbi, await provider)

      setOpenConfirm(true)
      handleConfirmClose()

      await contract.safeTransferFrom(address, inputValue, id, num, stringToBytes('')).then(async (res1) => {
        const res2 = await res1.wait()
        if (res2.blockNumber == null) {
        } else {
          setHash(String(res2.hash))
          setOpenConfirm(false)
          setOpenSucced(true)

        }

      }).catch((err) => {
        openNotification('top', `Send ---- ${err}`)
        handleConfirmClose()
        setOpenConfirm(false)
      })
    }
  }

  const handleTranConfirmClose = () => {
    setOpenConfirm(false)
  }

  const handleSuccedClose = () => {
    setOpenSucced(false)
  }



  return (
    <>
      {contextHolder}
      <Confirm windowWidth={windowWidth} NFTName={NFTName} NFTNum={num} address={inputValue} open={openConfirm} handleConfirmClose={handleTranConfirmClose} />
      <Succeed windowWidth={windowWidth} NFTName={NFTName} NFTNum={num} address={inputValue} open={openSucced} handleConfirmClose={handleSuccedClose} hash={hash} />
      {
        windowWidth >= 600 ? (
          <Box sx={{ width: '100%' }}>
            <BootstrapDialog
              onClose={handleConfirmClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <Stack p="0 10px" mb="20px" direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                  Send
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleConfirmClose}
                  sx={{ color: "#9b9b9b" }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>

              <DialogContent >
                <Box sx={{ p: '0px 0px 20px 0px' }}>

                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '10px', margin: '0 auto', mb: '20px', width: 'fit-content' }}>
                    <Stack alignItems="center" spacing="5px">
                      <Box sx={{ width: 'auto', height: 'auto' }}>
                        <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                      </Box>
                      <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: 700, lineHeight: '20px' }}>
                        HAYA {NFTName} Miner
                      </Typography>

                    </Stack>

                  </Box>


                  <Stack alignItems="start" spacing="10px" width="100%">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '32px', pl: '20px' }}>
                      Receiver address
                    </Typography>


                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '12px 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <BootstrapInput sx={{ color: color }} placeholder={address} onChange={InputChange} value={inputValue} />
                        {
                          !disable ? (
                            <img src={CheckIcon} />
                          ) : (
                            <></>
                          )
                        }

                      </Stack>

                    </Box>


                  </Stack>





                </Box>
              </DialogContent>
              <DialogActions>
                <Stack width="100%" >
                  <ViewDetailButton disabled={disable} onClick={goViewDetail}>Send</ViewDetailButton>
                </Stack>
              </DialogActions>
            </BootstrapDialog>

          </Box>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleConfirmClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0' } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Box sx={{ width: '100%' }}>

                <Stack p="0 10px" mb="10px" direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ color: "#000", fontSize: '22px', fontWeight: 700, lineHeight: '24px' }}>
                    Send
                  </Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleConfirmClose}
                    sx={{ color: "#9b9b9b" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Box sx={{ p: '0px 0px 10px 0px' }}>

                  <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '10px', margin: '0 auto', mb: '10px', width: 'fit-content' }}>
                    <Stack alignItems="center" spacing="5px">
                      <Box sx={{ width: 'auto', height: 'auto' }}>
                        <img src={url} style={{ borderRadius: '10px', width: '100px', lineHeight: '120px', verticalAlign: 'middle' }} />
                      </Box>
                      <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: 700, lineHeight: '20px' }}>
                        HAYA {NFTName} Miner
                      </Typography>

                    </Stack>

                  </Box>


                  <Stack alignItems="start" spacing="10px" width="100%">
                    <Typography sx={{ color: '#000', fontSize: '22px', fontWeight: 600, lineHeight: '32px', pl: '20px' }}>
                      Receiver address
                    </Typography>


                    <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '20px', padding: '12px 20px', width: '100%' }}>
                      <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <BootstrapInput sx={{ color: color, fontSize: '14px' }} placeholder={address} onChange={InputChange} value={inputValue} />
                        {
                          !disable ? (
                            <img src={CheckIcon} />
                          ) : (
                            <></>
                          )
                        }

                      </Stack>

                    </Box>


                  </Stack>





                </Box>
                <Stack width="100%" >
                  <ViewDetailButton disabled={disable} onClick={goViewDetail}>Send</ViewDetailButton>
                </Stack>

              </Box>
            </Box>
          </Drawer>
        )
      }

    </>
  )

}
export default SendMiner
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "utils/axios"
import { getToken } from "utils/token";

type UserInfoType = {
  commission_usdt_amount: string;
  commission_base_amount: string;
  tiger_level: number;
  partner_level: number;
  price: number;
  referrer_wallet_address: string;
  referee_wallet_address: string;
  miner_type: number;
  quantity: number;
  transaction_hash: string;
  block_height: number;
  block_timestamp: number
}

type DataType = {
  data: UserInfoType[]
  success?: boolean
  error?: null | string
  _total_records: number
}

type Data = {
  SaleTigerRewardData: DataType,
  status: number
}

type Params = {
  tiger_level: number;
  size: number
  page: number

}



// 获取用户信息
export const getSaleTigerRewardData = createAsyncThunk('account/SaleTigerReward', async (data: Params, thunkkAPI?: any) => {
  try {
    const res = await http.get('/sale_tiger_reward', {
      params: data,
      "headers": {
        "Authorization": `Bearer ${getToken().miner_token}`,
        "Content-Type": "application/json"
      }
    })
    // console.log('获取 SaleTigerReward', res)
    return res
  } catch (error: any) {
    return thunkkAPI.rejectWithValue(error.response)
  }
})

const initialState = {
  SaleTigerRewardData: {},
  status: 0
} as Data


const slice = createSlice({
  name: 'getSaleTigerReward',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSaleTigerRewardData.fulfilled, (state, action) => {
        state.SaleTigerRewardData = action.payload.data
        state.status = action.payload.status
      })
      .addCase(getSaleTigerRewardData.rejected, (state, action: any) => {
        state.status = 401
        state.SaleTigerRewardData.data = []
        state.SaleTigerRewardData._total_records = 0

      })
  },
  reducers: {}
})


export default slice.reducer

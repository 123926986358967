import { Dialog, IconButton, Box, Drawer, Stack, Typography, Button, ButtonProps } from "@mui/material";
import styled from "@mui/system/styled";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { useState } from "react";
import { H30_Address } from "config";
import pictureIcon from 'assets/icons/picture.svg'
import twitterIcon from 'assets/icons/twitter.svg'
import copyIcon from 'assets/images/icon/Copy.svg';
import { FaRegSquareCheck } from "react-icons/fa6";
import crownIcon from 'assets/icons/crown.svg'
import TokenColorIcon from "assets/tokens";
import ListTable from "./tableList";


type PropsType = {
  windowWidth: number
  windowHeight: number
  open: boolean;
  handleClose: () => void;
}


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    maxWidth: '1040px',
    width: '1040px',
    padding: '20px 20px',
    borderRadius: '20px',


  },
  '& .MuiDialogContent-root': {
    padding: 0
  },
  '& .MuiDialogActions-root': {
    padding: 0
  }

}));

const ClaimButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1aae70',
  padding: '8px 40px',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#19A56A',
    color: '#fff',
  },
}));

const ReferralsDetails = ({ windowHeight, windowWidth, open, handleClose }: PropsType) => {

  const [copy, setCopy] = useState(false)

  const onView = () => {

  }

  const onCopy = () => {
    navigator.clipboard.writeText(H30_Address as string)

    setCopy(true)

    setTimeout(() => {
      setCopy(false)

    }, 2000)
  }

  const [value, setValue] = useState('All')

  const OnCheckTitel = (text: string) => {
    setValue(text)
  }

  const [text, setText] = useState('Referrals commission record')

  const OnCheckText = (text: string) => {
    setText(text)
  }
  return (
    <>
      {
        windowWidth >= 600 ? (
          <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p="0 10px" mb="20px">
              <Typography sx={{ color: "#000000", fontSize: '22px', lineHeight: '24px', fontWeight: 600 }}>
                Referrals Details
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{ color: "#6f6f6f", width: '24px', height: '24px' }}

              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack alignItems="center" spacing="10px" marginBottom="20px">
                <Box sx={{ backgroundImage: 'linear-gradient(to bottom, #017E68, #1BAD70)', borderRadius: '24px', padding: '30px 30px', width: '100%' }}>
                  <Stack alignItems="start" spacing="24px">
                    <Stack alignItems="start">
                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '32px', lineHeight: '32px', marginBottom: '10px' }}  >
                        Refer and Earn
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#F6F6F6', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px', marginBottom: '6px' }}  >
                        Earn commission bonus from all your invited friends' daily $HAYA mine;
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#F6F6F6', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '14px', lineHeight: '20px' }}  >
                        Stake $HAYA to upgrade referrals level for a high commission rate.
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing="10px">
                      <Stack direction="row" alignItems="center" spacing="10px" sx={{ backgroundColor: '#fff', borderRadius: '20px', padding: '20px 25px' }}>
                        <Typography variant="body1" sx={{ color: '#000', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '18px', lineHeight: '18px' }}  >
                          fDrgYk
                        </Typography>
                        {
                          !copy ? (
                            <IconButton sx={{ width: '22px', height: '22px' }} onClick={onCopy}>
                              <img style={{ width: '22px', height: '22px' }} src={copyIcon} />
                            </IconButton>
                          ) : (
                            <FaRegSquareCheck size={20} style={{ color: '#9B9B9B' }} />

                          )
                        }



                      </Stack>
                      <IconButton sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                        <img src={twitterIcon} />

                      </IconButton>
                      <IconButton sx={{ padding: 0, width: 'auto', height: 'auto' }}>
                        <img src={pictureIcon} />

                      </IconButton>


                    </Stack>

                  </Stack>
                </Box>

                <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                  <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 30px', width: '60%' }}>
                    <Stack alignItems="start" spacing="10px" justifyContent="center">
                      <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                        Staking Node Level
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing="16px">
                        <img src={crownIcon} />

                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '24px', lineHeight: '28px' }}  >
                          Gold
                        </Typography>

                        <Typography component="button" onClick={onView} variant="body1" sx={{ padding: 0, cursor: 'pointer', color: '#1AAE70', backgroundColor: 'transparent', border: 'none', fontWeight: 500, fontSize: '16px', lineHeight: '18px', fontFamily: `'Public Sans',sans-serif` }}  >
                          {`Upgrade >`}
                        </Typography>


                      </Stack>


                    </Stack>
                  </Box>
                  <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '20%' }}>
                    <Stack alignItems="start" spacing="10px" justifyContent="center">
                      <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                        Tier One Friends
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '24px', lineHeight: '28px' }}  >
                        13
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '20%' }}>
                    <Stack alignItems="start" spacing="10px" justifyContent="center">
                      <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '18px' }}  >
                        Tier Two Friends
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '24px', lineHeight: '28px' }}  >
                        48
                      </Typography>
                    </Stack>
                  </Box>


                </Stack>
                <Stack direction="row" alignItems="center" spacing="10px" width="100%">
                  <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '50%' }}>
                    <Stack alignItems="start" spacing="10px">
                      <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                        Reward of Referrals Commission
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing="12px">
                        <TokenColorIcon name="HAYA" size={48} />
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '48px', lineHeight: '46px' }}  >
                          3014.3
                        </Typography>

                      </Stack>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                          1325.1 $HAYA Claimable
                        </Typography>
                        <ClaimButton>Claim</ClaimButton>
                      </Stack>

                    </Stack>

                  </Box>
                  <Box sx={{ backgroundColor: '#1B1B1B', borderRadius: '20px', padding: '20px 20px', width: '50%' }}>
                    <Stack alignItems="start" spacing="10px">
                      <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                        Reward of Node Sales
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing="12px">
                        <TokenColorIcon name="USDT" size={48} />
                        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 600, fontFamily: `'Public Sans',sans-serif`, fontSize: '48px', lineHeight: '46px' }}  >
                          1252.31
                        </Typography>

                      </Stack>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Typography variant="body1" sx={{ color: '#c0c0c0', fontWeight: 500, fontFamily: `'Public Sans',sans-serif`, fontSize: '16px', lineHeight: '20px' }}  >
                          100 $USDT Claimable
                        </Typography>
                        <ClaimButton>Claim</ClaimButton>

                      </Stack>

                    </Stack>

                  </Box>

                </Stack>
              </Stack>
              <Box sx={{ padding: '0 10px', width: '100%', marginBottom: '10px' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" padding="0px 10px">
                  <Box sx={{ backgroundColor: 'transparent', padding: 0 }}>
                    <Stack direction="row" alignItems="center">
                      <Box sx={text == 'Referrals commission record' ? ({ p: '4px 12px', backgroundColor: '#F6F6F6', color: '#1aae70', fontSize: '16px', fontWeight: 600, borderRadius: "20px", cursor: 'pointer', border: 0, lineHeight: '24px' }) : ({ p: '4px 12px', backgroundColor: 'transparent', fontWeight: 600, color: '#6F6F6F', fontSize: '16px', lineHeight: '24px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckText('Referrals commission record')}>
                        Referrals commission record
                      </Box>
                      <Box sx={text == 'Node sales record' ? ({ p: '4px 12px', backgroundColor: '#F6F6F6', color: '#1aae70', fontSize: '16px', fontWeight: 600, borderRadius: "20px", cursor: 'pointer', border: 0, lineHeight: '24px' }) : ({ p: '4px 12px', backgroundColor: 'transparent', fontWeight: 600, color: '#6F6F6F', fontSize: '16px', lineHeight: '24px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckText('Node sales record')}>
                        Node sales record
                      </Box>
                    </Stack>
                  </Box>
                  <Box sx={{ backgroundColor: 'rgba(76, 76, 80, 0.12)', borderRadius: '16px', padding: '2px' }}>
                    <Stack direction="row" alignItems="center">
                      <Box sx={value == 'All' ? ({ p: '3px 10px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '3px 10px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('All')}>
                        All
                      </Box>
                      <Box sx={value == 'Tier_One' ? ({ p: '3px 10px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '3px 10px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Tier_One')}>
                        Tier One
                      </Box>
                      <Box sx={value == 'Tier_Two' ? ({ p: '3px 10px', backgroundColor: '#ffff', color: '#000', fontSize: '14px', fontWeight: 600, borderRadius: "16px", cursor: 'pointer', border: 0 }) : ({ p: '3px 10px', backgroundColor: 'transparent', fontWeight: 600, color: '#6f6f6f', fontSize: '14px', cursor: 'pointer', border: 0 })} component="button" onClick={() => OnCheckTitel('Tier_Two')}>
                        Tier Two
                      </Box>
                    </Stack>
                  </Box>
                </Stack>

              </Box>
              <ListTable windowWidth={windowWidth} />

            </DialogContent>
          </BootstrapDialog>
        ) : (
          <Drawer anchor='bottom' open={open} onClose={handleClose} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#fff', left: '5px', right: '5px', borderRadius: '20px 20px 0 0' } }}>
            <Box sx={{ width: 'auto', padding: '20px 10px' }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" p="0 10px" mb="10px">
                <Typography sx={{ color: "#000000", fontSize: '18px', fontWeight: 600 }}>
                  Select a Token
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{ color: "#9b9b9b" }}

                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" justifyContent="space-between" sx={{ p: '9px 20px' }}>
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600 }} color="#9B9B9B">
                  Token
                </Typography>
                <Typography variant='body1' sx={{ fontSize: '14px', fontWeight: 600 }} color="#9B9B9B">
                  My balance
                </Typography>

              </Stack>
            </Box>
          </Drawer>


        )
      }

    </>
  )
}

export default ReferralsDetails